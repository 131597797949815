import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: process.env.SENTRY_REACT_DSN,
  integrations: [Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^\//],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});
