import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PitchExpiredPrompt = ({ proposalId }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full mb-5">
      <div
        className={`
        flex
        flex-col
        items-start
      `}
      >
        <div className="flex items-start justify-start gap-3 md:max-w-2xl">
          <div className="md:w-12 md:h-12 md:visible md:block hidden">
            <img
              src="/images/small/logo/ain_new-logo.png"
              className="w-12 h-12 rounded-full"
              alt="AIN"
            />
          </div>
          <div className="w-auto">
            <div className="rounded-xl p-5 bg-slate-100 text-gray-700 text-base font-normal">
              - {t('auth.inbox.pitchExpired')}
              <a
                className="
                  px-3
                  py-1
                  rounded
                  text-white
                  bg-blue-500
                  hover:bg-blue-600
                  ml-1
                  no-underline
                  md:inline
                  inline-block
                "
                target="_blank"
                href={`/entrepreneur/proposal_renew/${proposalId}`}
                rel="noreferrer"
              >
                {t('auth.inbox.renew')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PitchExpiredPrompt;

PitchExpiredPrompt.propTypes = {
  proposalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
};
