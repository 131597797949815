import React from 'react';
import * as PropTypes from 'prop-types';
import AvatarCircle from './AvatarCircle';

const ProfileUpdate = ({ profileUpdates }) => {
  const profileUpdatesLimit = 9;

  return (
    <div className="flex w-full md:gap-2 md:space-x-0 -space-x-2">
      {profileUpdates?.length &&
        profileUpdates.slice(0, profileUpdatesLimit).map((update, idx) => {
          return (
            <AvatarCircle
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              image={update?.logoUrl}
              fullName={update?.fullName}
              investorDetailsUrl={update?.detailsUrl}
              userId={update?.investorId ? update?.investorId : '0'}
            />
          );
        })}
      {profileUpdates?.length > profileUpdatesLimit && (
        <AvatarCircle number={profileUpdates.length - profileUpdatesLimit} />
      )}
    </div>
  );
};

export default ProfileUpdate;

ProfileUpdate.propTypes = {
  profileUpdates: PropTypes.instanceOf(Array).isRequired
};
