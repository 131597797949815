import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NewsfeedButton from './Button';

const ButtonGroup = ({
  onBackClick,
  onConfirmationClick,
  presetValue,
  type
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`flex items-center ${
        type === 'image' ? 'px-6' : ''
      } justify-end gap-2 ml-auto`}
    >
      <NewsfeedButton
        onClick={onBackClick}
        type="secondary"
        title={t('newsfeed.pageContent.back')}
      />
      <NewsfeedButton
        onClick={onConfirmationClick}
        type={presetValue?.length ? 'primary' : 'disabled'}
        title={t('newsfeed.pageContent.done')}
      />
    </div>
  );
};

export default ButtonGroup;

ButtonGroup.propTypes = {
  onBackClick: PropTypes.func,
  onConfirmationClick: PropTypes.func,
  presetValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  type: PropTypes.oneOf(['image', null])
};

ButtonGroup.defaultProps = {
  onBackClick: () => {},
  onConfirmationClick: () => {},
  presetValue: [],
  type: null
};
