import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import NewsfeedButton from '../Newsfeed/Button';
import { renderUserAvatar } from '../../Util/data';
import BannerInput from '../BenefitOfferAdmin/BannerInput';
import Tiptap from '../Tiptap/Tiptap';

const CaseStudyModal = ({
  avatar,
  name,
  type,
  postCase,
  posting,
  preFetchedCaseStudy,
  sites,
  proposalStages,
  industries,
  caseStudyStatuses,
  bgClass
}) => {
  const caseStudy = JSON.parse(preFetchedCaseStudy);
  const [image, setImage] = useState(null);
  const [header, setHeader] = useState(null);
  const [userId, setUserId] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [content, setContent] = useState(null);
  const [quote, setQuote] = useState(null);
  const unsortedSites = JSON.parse(sites);
  const allSites = unsortedSites.sort((a, b) => {
    const nameA = a?.topLocation?.name.toUpperCase();
    const nameB = b?.topLocation?.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedSiteValue, setSelectedSiteValue] = useState('');
  const allProposalStages = JSON.parse(proposalStages);
  const [caseStudyProposalStage, setCaseStudyArticleProposalStage] =
    useState('');
  const allIndustries = JSON.parse(industries);
  const [caseStudyIndustry, setCaseStudyArticleIndustry] = useState('');
  const allCaseStudyStatuses = JSON.parse(caseStudyStatuses);
  const [caseStudyStatus, setCaseStudyArticleStatus] = useState('');
  const [isEditable, setEditable] = useState(!caseStudy?.content?.length);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    postCase(
      userId,
      designation,
      companyName,
      image,
      header,
      content,
      quote,
      selectedSites,
      caseStudyStatus,
      caseStudyProposalStage,
      caseStudyIndustry
    );
  };

  const hideModal = () => {
    // eslint-disable-next-line no-undef
    const modalWrapper = document.querySelector('.modal-wrapper');
    modalWrapper.classList.toggle('hidden');
  };

  useEffect(() => {
    if (caseStudy) {
      setImage(caseStudy?.image?.src);
      setHeader(caseStudy?.header);
      setUserId(caseStudy?.user);
      setDesignation(caseStudy?.designation);
      setCompanyName(caseStudy?.companyName);
      setContent(caseStudy?.content);
      setQuote(caseStudy?.quote);

      setSelectedSites([
        ...(caseStudy?.sitesCodes ?? []).map((site) => {
          return site.code;
        })
      ]);
      setCaseStudyArticleProposalStage(caseStudy?.proposalStage);
      setCaseStudyArticleIndustry(caseStudy?.industry);
      setCaseStudyArticleStatus(caseStudy?.caseStudyArticleStatus);
      if (caseStudy?.sitesCodes.length > 1) {
        setSelectedSiteValue('all');
      } else {
        setSelectedSiteValue(
          ...(caseStudy?.sitesCodes ?? []).map((site) => {
            return site.code;
          })
        );
      }
    }
  }, []);

  const setOfferSite = (event) => {
    setSelectedSiteValue(event);
    const siteList = [];
    if (event === 'all') {
      allSites.forEach((el) => {
        siteList.push(el.siteCode);
      });
    } else {
      siteList.push(event);
    }
    setSelectedSites([...siteList]);
  };

  // eslint-disable-next-line consistent-return
  const updateProposalStage = (proposalStage) => {
    setCaseStudyArticleProposalStage(proposalStage);
  };

  // eslint-disable-next-line consistent-return
  const updateIndustry = (industry) => {
    setCaseStudyArticleIndustry(industry);
  };

  // eslint-disable-next-line consistent-return
  const updateCaseStudyStatus = (status) => {
    setCaseStudyArticleStatus(status);
  };

  return (
    <div
      tabIndex="-1"
      className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full md:h-screen justify-center items-center flex bg-black bg-opacity-90 md:pb-12 pb-14"
      role="dialog"
    >
      <div
        className="
          bg-white
          rounded-2xl
          py-6
          md:w-1/2
          xl:w-2/5
          w-11/12
          max-w-full
          h-auto
          m-auto
          md:mt-12
          mt-14
          flex
          flex-col
          gap-4
        "
      >
        <div className="flex items-center justify-between px-6">
          <h2 className="text-gray-800 font-semibold md:text-xl text-lg leading-8 mb-0">
            {type === 'add' ? 'Add' : 'Edit'} a Case Study
          </h2>
          <button
            type="button"
            onClick={hideModal}
            className="inline-flex items-center justify-center w-6 h-6"
          >
            <span className="material-icons text-gray-600">close</span>
          </button>
        </div>
        <div className="w-full py-2 md:px-6 px-4 flex items-start gap-6">
          {avatar ? (
            <img
              src={avatar}
              alt={name}
              className="w-16 h-16 flex-shrink-0 lg:inline-flex hidden rounded-lg object-cover object-center"
            />
          ) : (
            renderUserAvatar(
              name,
              'w-16 h-16 flex-shrink-0 lg:inline-flex hidden',
              `bg-${bgClass}`
            )
          )}
          <form
            onSubmit={handleFormSubmit}
            className="flex w-full flex-col gap-2"
          >
            <div className="w-full">
              <p className="font-semibold text-gray-800 text-lg mb-2">Image</p>
              <BannerInput
                handleBannerChange={(file) => {
                  return setImage(file);
                }}
                removeImage={() => {
                  return setImage(null);
                }}
                prefetchedBanner={image}
              />
            </div>

            <label
              htmlFor="header"
              className="font-semibold text-gray-800 text-lg"
            >
              Header
              <input
                id="header"
                type="text"
                className="text-left p-3 border w-full border-gray-300 placeholder-gray-200 mt-2 rounded focus:outline-none focus:border-blue-500"
                onChange={(e) => {
                  return setHeader(e.target.value);
                }}
                value={header || ''}
                required
              />
            </label>
            <label
              htmlFor="quote"
              className="font-semibold text-gray-800 text-lg"
            >
              Quote
              <textarea
                id="quote"
                className="text-left p-3 border w-full border-gray-300 placeholder-gray-200 mt-2 rounded focus:outline-none focus:border-blue-500"
                cols="20"
                rows="2"
                onChange={(e) => {
                  return setQuote(e.target.value);
                }}
                value={quote || ''}
                required
              />
            </label>

            <span className="font-semibold text-gray-800 text-lg">Content</span>
            <div id="content" tabIndex={-1}>
              {/*
                On the case study details page, I had to display Tiptap after the admin clicks edit
                as it was not being populated properly because of useEffect.
              */}
              {caseStudy ? (
                isEditable ? (
                  <Tiptap
                    content={content || ''}
                    updateContent={(value) => {
                      return setContent(value);
                    }}
                  />
                ) : (
                  <div className="relative w-full gap-2 py-2 rounded border border-gray-300 p-3 max-h-96 overflow-auto custom-thumb">
                    <button
                      type="button"
                      className="sticky top-3 px-2 py-1 bg-gray-200 text-gray-800 font-semibold text-base rounded-md material-symbols-outlined float-right shadow-md"
                      onClick={() => {
                        return setEditable((value) => {
                          return !value;
                        });
                      }}
                    >
                      edit
                    </button>
                    {content && (
                      <Markdown
                        options={{ forceBlock: true }}
                        className="prose"
                      >
                        {content.length > 800
                          ? `${content.slice(0, 800)}...`
                          : content}
                      </Markdown>
                    )}
                  </div>
                )
              ) : (
                <Tiptap
                  content={content || ''}
                  updateContent={(value) => {
                    return setContent(value);
                  }}
                />
              )}
            </div>

            <label
              htmlFor="userId"
              className="font-semibold text-gray-800 text-lg"
            >
              User ID
              <input
                id="userId"
                type="text"
                className="text-left p-3 border w-full border-gray-300 placeholder-gray-200 mt-2 rounded focus:outline-none focus:border-blue-500"
                onChange={(e) => {
                  return setUserId(e.target.value);
                }}
                value={userId || ''}
                required
              />
            </label>

            <label
              htmlFor="designation"
              className="font-semibold text-gray-800 text-lg"
            >
              Designation
              <input
                id="designation"
                type="text"
                className="text-left p-3 border w-full border-gray-300 placeholder-gray-200 mt-2 rounded focus:outline-none focus:border-blue-500"
                onChange={(e) => {
                  return setDesignation(e.target.value);
                }}
                value={designation || ''}
                required
              />
            </label>

            <label
              htmlFor="companyName"
              className="font-semibold text-gray-800 text-lg"
            >
              Company Name
              <input
                id="companyName"
                type="text"
                className="text-left p-3 border w-full border-gray-300 placeholder-gray-200 mt-2 rounded focus:outline-none focus:border-blue-500"
                onChange={(e) => {
                  return setCompanyName(e.target.value);
                }}
                value={companyName || ''}
                required
              />
            </label>

            <label
              htmlFor="site"
              className="font-semibold text-gray-800 text-lg"
            >
              Sites
              <div className="relative flex-grow md:w-auto w-full">
                <select
                  onChange={(e) => {
                    e.preventDefault();
                    setOfferSite(e.target.value);
                  }}
                  value={selectedSiteValue}
                  id="site"
                  className="
                      mt-2
                      mb-2
                      w-full
                      bg-white
                      border
                      border-gray-300
                      focus:border-blue-600
                      focus:outline-none
                      text-gray-800
                      text-base
                      leading-6
                      rounded
                      p-2.5
                      appearance-none
                      cursor-pointer
                    "
                  required
                >
                  <option value="">-</option>
                  <option value="all">All Sites</option>
                  {allSites?.map((site) => {
                    return (
                      <option key={site?.siteCode} value={site?.siteCode}>
                        {site?.topLocation?.name} - ({site?.siteCode})
                      </option>
                    );
                  })}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800">
                  <span className="material-icons">expand_more</span>
                </div>
              </div>
            </label>
            <label
              htmlFor="site"
              className="font-semibold text-gray-800 text-lg"
            >
              Proposal Stage
              <div className="relative flex-grow md:w-auto w-full">
                <select
                  onChange={(e) => {
                    return updateProposalStage(e.target.value);
                  }}
                  value={caseStudyProposalStage}
                  id="proposalStage"
                  className="
                      w-full
                      bg-white
                      border
                      border-gray-300
                      focus:border-blue-600
                      focus:outline-none
                      text-gray-800
                      text-base
                      leading-6
                      rounded
                      p-2.5
                      appearance-none
                      cursor-pointer
                    "
                  required
                >
                  <option value="">-</option>
                  {allProposalStages?.map((proposalStage) => {
                    return (
                      <option key={proposalStage?.id} value={proposalStage?.id}>
                        {proposalStage?.name}
                      </option>
                    );
                  })}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800">
                  <span className="material-icons">expand_more</span>
                </div>
              </div>
            </label>
            <label
              htmlFor="site"
              className="font-semibold text-gray-800 text-lg"
            >
              Industry
              <div className="relative flex-grow md:w-auto w-full">
                <select
                  onChange={(e) => {
                    return updateIndustry(e.target.value);
                  }}
                  value={caseStudyIndustry}
                  id="industry"
                  className="
                      w-full
                      bg-white
                      border
                      border-gray-300
                      focus:border-blue-600
                      focus:outline-none
                      text-gray-800
                      text-base
                      leading-6
                      rounded
                      p-2.5
                      appearance-none
                      cursor-pointer
                    "
                  required
                >
                  <option value="">-</option>
                  {allIndustries?.map((industry) => {
                    return (
                      <option key={industry?.id} value={industry?.id}>
                        {industry?.name}
                      </option>
                    );
                  })}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800">
                  <span className="material-icons">expand_more</span>
                </div>
              </div>
            </label>
            <label
              htmlFor="site"
              className="font-semibold text-gray-800 text-lg"
            >
              Status
              <div className="relative flex-grow md:w-auto w-full">
                <select
                  onChange={(e) => {
                    return updateCaseStudyStatus(e.target.value);
                  }}
                  value={caseStudyStatus}
                  id="status"
                  className="
                      w-full
                      bg-white
                      border
                      border-gray-300
                      focus:border-blue-600
                      focus:outline-none
                      text-gray-800
                      text-base
                      leading-6
                      rounded
                      p-2.5
                      appearance-none
                      cursor-pointer
                    "
                  required
                >
                  <option value="">-</option>
                  {allCaseStudyStatuses?.map((status) => {
                    return (
                      <option key={status?.code} value={status?.code}>
                        {status?.name}
                      </option>
                    );
                  })}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800">
                  <span className="material-icons">expand_more</span>
                </div>
              </div>
            </label>
            <div className="w-full flex items-center justify-end">
              <NewsfeedButton
                classNames="md:w-auto w-full"
                loading={posting}
                title="Submit"
                type="primary"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyModal;

CaseStudyModal.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  posting: PropTypes.bool.isRequired,
  postCase: PropTypes.func,
  preFetchedCaseStudy: PropTypes.string,
  type: PropTypes.oneOf(['add', 'edit']),
  sites: PropTypes.string,
  proposalStages: PropTypes.string,
  industries: PropTypes.string,
  caseStudyStatuses: PropTypes.string,
  bgClass: PropTypes.string
};

CaseStudyModal.defaultProps = {
  avatar: null,
  postCase: () => {},
  preFetchedCaseStudy: null,
  type: 'add',
  sites: null,
  proposalStages: null,
  industries: null,
  caseStudyStatuses: null,
  bgClass: '0'
};
