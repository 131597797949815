/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import '../../Util/i18n';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchProposals } from '../../Util/ApiService';
import Loader from '../../Components/Loader/Loader';
import ProposalCarouselBlock from '../../Components/ProposalCarouselBlock/ProposalCarouselBlock';

const PAGE_SIZE = 6;

const ProposalCarousel = ({
  apiUrl,
  businessProposalUri,
  currencyCode,
  industryList,
  locale,
  stageList,
  title,
  subscriptionTypes
}) => {
  const { t } = useTranslation();

  const [currentSliceIndex, setCurrentSliceIndex] = useState(0);
  const [featuredProposals, setFeaturedProposals] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [modifiedIndustries, setModifiedIndustries] = useState([]);
  const [sections, setSections] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);

  const getFeaturedProposals = async () => {
    const proposals = await fetchProposals(apiUrl, {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      featured: 1
    });
    if (proposals?.results?.length) {
      setFeaturedProposals(proposals.results);
    }
  };

  const getProposals = async (data) => {
    setLoading(true);
    const updatedObject = {
      id: data.id,
      title: data.name
    };
    const proposals = await fetchProposals(apiUrl, {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      industries: data.type === 'industry' ? [data.id] : null,
      stages: data.type === 'stage' ? [data.id] : null
    });

    if (proposals?.results?.length) {
      updatedObject.proposals = proposals.results;
      const existingSectionIndex = sections.findIndex((existingSection) => {
        return existingSection.name === data.name;
      });

      if (existingSectionIndex === -1) {
        setSections((prevSections) => {
          return [...prevSections, updatedObject];
        });
      }
      setLoading(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-undef
    window.open(
      // eslint-disable-next-line no-undef
      `${window.location.origin}${businessProposalUri}?searchQuery[]=${searchQuery}`,
      '_blank'
    );
  };

  const loadMoreSections = () => {
    if (modifiedIndustries?.length === sections?.length) {
      return setHasMore(false);
    }
    modifiedIndustries?.slice(currentSliceIndex).map((item) => {
      return getProposals(item);
    });
    return setCurrentSliceIndex(currentSliceIndex + 2);
  };

  useEffect(() => {
    const industries = JSON.parse(industryList).sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    const updatedIndustries = industries.map((industry) => {
      return { ...industry, type: 'industry' };
    });

    const stages = JSON.parse(stageList).sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });

    const modifiedStages = stages.map((stage) => {
      return { ...stage, type: 'stage' };
    });

    updatedIndustries.splice(3, 0, modifiedStages[0]);
    updatedIndustries.splice(4, 0, modifiedStages[1]);
    setModifiedIndustries(updatedIndustries);

    getFeaturedProposals();
    updatedIndustries?.slice(0, 3).map((item) => {
      return getProposals(item);
    });
    setCurrentSliceIndex(3);
  }, []);

  return (
    <>
      {featuredProposals?.length ? (
        <div className="max-w-container w-full mx-auto px-3">
          <ProposalCarouselBlock
            businessProposalUri={businessProposalUri}
            currencyCode={currencyCode}
            locale={locale}
            proposals={featuredProposals}
            title={title}
            subscriptionTypes={subscriptionTypes}
          />
        </div>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></> // this empty fragment is required to not display "0" when there's no proposal
      )}
      {sections.length ? (
        sections.slice(0, 3).map((section, index) => {
          return (
            <div key={index} className="max-w-container w-full mx-auto px-3">
              <ProposalCarouselBlock
                businessProposalUri={businessProposalUri}
                currencyCode={currencyCode}
                locale={locale}
                title={section.title}
                proposals={section.proposals}
                subscriptionTypes={subscriptionTypes}
              />
            </div>
          );
        })
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      )}
      <div className="w-full py-16 bg-exploreSearch bg-cover bg-no-repeat bg-center bg-blue-600 text-center">
        <div className="max-w-container mx-auto w-full px-3 flex flex-col items-center gap-10">
          <h2 className="text-white text-3xl font-medium">
            {t('explorePage.somethingSpecific')}
          </h2>
          <form
            onSubmit={handleFormSubmit}
            className="w-full max-w-2xl mx-auto"
          >
            <div className="grid gap-5 md:grid-cols-3">
              <div className="md:col-span-2 relative">
                <input
                  onInput={(e) => {
                    return setSearchQuery(e?.target?.value);
                  }}
                  required
                  type="text"
                  className="w-full relative bg-transparent text-white border rounded-md border-white focus:outline-none focus:ring-2 ring-blue-100 transition-all p-3 pl-12 text-lg"
                />
                <div className="absolute inset-y-0 left-0 inline-flex items-center justify-center p-4">
                  <span className="material-icons text-white">search</span>
                </div>
              </div>
              <button
                type="submit"
                className="flex font-semibold text-base text-blue-600 bg-white p-3 items-center justify-center rounded-md"
              >
                {t('explorePage.searchPitches')}
              </button>
            </div>
          </form>
        </div>
      </div>
      <InfiniteScroll
        dataLength={modifiedIndustries?.length}
        next={loadMoreSections}
        hasMore={hasMore}
      >
        {sections?.slice(3)?.map((section) => {
          return (
            <div
              key={section.id}
              className="max-w-container w-full px-4 mx-auto px-3"
            >
              <ProposalCarouselBlock
                businessProposalUri={businessProposalUri}
                currencyCode={currencyCode}
                locale={locale}
                title={section.title}
                proposals={section.proposals}
                subscriptionTypes={subscriptionTypes}
              />
            </div>
          );
        })}
      </InfiniteScroll>
      {loading && (
        <div className="w-full flex items-center justify-center py-8">
          <Loader
            customColor="text-blue-600"
            width="w-5"
            height="h-5"
            title="Loading..."
          />
        </div>
      )}
    </>
  );
};

export default ProposalCarousel;

ProposalCarousel.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  businessProposalUri: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  industryList: PropTypes.string.isRequired,
  stageList: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subscriptionTypes: PropTypes.instanceOf(Object)
};

ProposalCarousel.defaultProps = {
  subscriptionTypes: null
};
