/* eslint-disable indent */
import React from 'react';
import * as PropTypes from 'prop-types';
import '../../Util/i18n';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';

const BarChart = ({ chartData, currencyCode, locale }) => {
  const { t } = useTranslation();
  return (
    <Bar
      data={chartData}
      options={{
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
              pointStyle: 'circle'
            }
          },
          tooltip:
            currencyCode && locale
              ? {
                  callbacks: {
                    label: (tooltipItems) => {
                      return t('currency', {
                        val: tooltipItems.raw,
                        formatParams: {
                          val: {
                            currency: currencyCode,
                            locale: locale.replace(/_/g, '-'),
                            maximumFractionDigits: 0
                          }
                        }
                      });
                    }
                  }
                }
              : null
        },
        responsive: true,
        scales: {
          x: {
            gridLines: {
              display: true
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false
            },
            ticks: {
              callback: (value) => {
                return currencyCode && locale
                  ? t('currency', {
                      val: value,
                      formatParams: {
                        val: {
                          currency: currencyCode,
                          locale: locale.replace(/_/g, '-'),
                          maximumFractionDigits: 0
                        }
                      }
                    })
                  : value;
              }
            }
          }
        }
      }}
    />
  );
};

export default BarChart;

BarChart.propTypes = {
  chartData: PropTypes.instanceOf(Object).isRequired,
  currencyCode: PropTypes.string,
  locale: PropTypes.string
};

BarChart.defaultProps = {
  currencyCode: null,
  locale: null
};
