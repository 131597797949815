/* eslint-disable indent */
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { renderBusinessAvatar, renderUserAvatar } from '../../Util/data';
import { connectionStatusCodes } from '../../Util/connectionStatuses';
import Attachment from '../Attachment/Attachment';
import Loader from '../Loader/Loader';

const UserInfo = ({
  showProfileDetails,
  conversation,
  attachments,
  acceptConnection,
  rejectConnection,
  isProposalUser,
  locale,
  acceptingNudge,
  rejectingNudge
}) => {
  const { t } = useTranslation();

  return !conversation ? (
    <div
      className={`${
        !showProfileDetails ? 'hidden' : 'lg:block lg:visible hidden'
      } lg:w-1/4 w-full lg:px-10 h-full transition-all`}
    >
      <div className="w-full py-10 flex flex-col justify-center gap-3 items-center">
        <div className="w-20 h-20 rounded-full bg-gray-200 animate-pulse mb-2" />
        <div className="w-full flex flex-col justify-center items-center">
          <div className="w-1/2 h-6 mx-auto rounded-md bg-gray-200 animate-pulse mb-2" />
          <div className="w-1/2 h-3 mx-auto rounded-md bg-gray-200 animate-pulse mb-2" />
        </div>
        <div className="w-3/4 h-4 mx-auto rounded-md bg-gray-200 animate-pulse mb-2" />
        <div className="w-3/4 h-2 mx-auto rounded-md bg-gray-200 animate-pulse mb-2" />

        <div className="w-3/4 h-4 mx-auto rounded-md bg-gray-200 animate-pulse mb-2" />
        <div className="w-3/4 h-8 mx-auto rounded-md bg-gray-200 animate-pulse mb-2" />
        <div className="w-3/4 h-6 mx-auto rounded-md bg-gray-200 animate-pulse mb-2" />
      </div>
    </div>
  ) : (
    <div
      className={`${
        !showProfileDetails ? 'hidden' : 'lg:block lg:visible hidden'
      } lg:w-1/4 w-full lg:px-10 h-full transition-all`}
    >
      {!isProposalUser ? (
        <div className="w-full py-10 flex flex-col justify-center gap-3 items-center border-b border-slate-100">
          {conversation.proposal.logoUri ? (
            <img
              src={conversation.proposal.logoUri}
              alt={conversation.proposal.title}
              className="rounded-md w-24 h-24 object-cover object-center"
            />
          ) : (
            renderBusinessAvatar(conversation.proposal.title, null, null)
          )}
          <div className="w-full flex flex-col justify-center items-center">
            <p className="text-gray-800 text-xl">
              {conversation.proposal.title}
            </p>
          </div>
          <a
            href={conversation.proposal.proposalPageUri}
            target="_blank"
            className="font-normal text-sm underline text-blue-400"
            rel="noreferrer"
          >
            {t('auth.inbox.viewPitch')}
          </a>
          {conversation.status.code ===
          connectionStatusCodes.pendingAcceptance ? (
            <>
              <p className="text-sm text-gray-500">
                {t('auth.inbox.wantToConnect')}
              </p>
              <button
                type="button"
                onClick={() => {
                  return acceptConnection(
                    conversation.connectionUserActions.acceptConnectionUri
                  );
                }}
                className="block w-full mt-3 px-5 py-3 bg-blue-500 hover:bg-blue-600 text-white border border-blue-600 rounded-md"
              >
                {acceptingNudge ? (
                  <Loader title="Loading" />
                ) : (
                  t('auth.inbox.interested')
                )}
              </button>
              <button
                type="button"
                onClick={() => {
                  return rejectConnection(
                    conversation.connectionUserActions.rejectConnectionUri
                  );
                }}
                className="block w-full px-5 py-3 bg-gray-200 hover:bg-gray-300 text-gray-600 border border-gray-400 rounded-md"
              >
                {rejectingNudge ? (
                  <Loader title="Loading" />
                ) : (
                  t('auth.inbox.ignore')
                )}
              </button>
            </>
          ) : null}
        </div>
      ) : (
        <div className="w-full py-10 flex flex-col justify-center gap-3 items-center border-b border-slate-100">
          {conversation.interlocutor.logoUri ? (
            <img
              src={conversation.interlocutor.logoUri}
              alt={`${conversation.interlocutor.name.firstName} ${conversation.interlocutor.name.lastName}`}
              className="w-20 h-20 rounded-full object-cover object-center"
            />
          ) : (
            renderUserAvatar(
              `${conversation.interlocutor.name.firstName} ${conversation.interlocutor.name.lastName}`,
              null,
              `bg-${color}`
            )
          )}
          <div className="w-full flex flex-col justify-center items-center">
            <p className="text-gray-800 text-xl">
              {`${conversation.interlocutor.name.firstName} ${conversation.interlocutor.name.lastName}`}
            </p>
            <span className="text-gray-600 text-base">
              <span className="material-icons text-base text-blue-500 mr-1">
                location_on
              </span>
              {conversation.interlocutor.townName
                ? `${conversation.interlocutor.townName}, `
                : null}
              {t(`common.countryName.${conversation.interlocutor.countryCode}`)}
            </span>
          </div>
          {conversation.interlocutor.profileUri ? (
            <a
              href={conversation.interlocutor.profileUri}
              className="font-normal text-sm underline text-blue-400"
            >
              {t('auth.inbox.viewProfile')}
            </a>
          ) : null}
          {conversation.interlocutor.minInvestment &&
          conversation.interlocutor.minInvestment ? (
            <>
              <span className="text-xs text-gray-800 inline-block mt-4">
                {t('auth.inbox.investmentRange')}
              </span>
              <span className="text-xl text-gray-800 text-base">
                {t('currency', {
                  val: conversation.interlocutor.minInvestment,
                  formatParams: {
                    val: {
                      currency: conversation.interlocutor.currencyCode,
                      locale: locale.replace(/_/g, '-'),
                      maximumFractionDigits: 0
                    }
                  }
                })}
                &nbsp;-&nbsp;
                {t('currency', {
                  val: conversation.interlocutor.maxInvestment,
                  formatParams: {
                    val: {
                      currency: conversation.interlocutor.currencyCode,
                      locale: locale.replace(/_/g, '-'),
                      maximumFractionDigits: 0
                    }
                  }
                })}
              </span>
            </>
          ) : null}
        </div>
      )}
      {attachments?.length > 0 && (
        <div className="w-full pb-10 h-full max-h-96 overflow-x-hidden overflow-y-auto">
          <h4 className="font-semibold py-6 text-lg text-gray-800 mb-3 sticky top-0 bg-white z-10">
            {t('auth.inbox.mediaAndAttachments')}
          </h4>
          <div className="w-full flex flex-wrap">
            {attachments.map((attachment, index) => {
              // eslint-disable-next-line react/no-array-index-key
              return <Attachment key={index} attachment={attachment} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  showProfileDetails: PropTypes.bool,
  conversation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array)
  ]),
  attachments: PropTypes.instanceOf(Object),
  acceptConnection: PropTypes.func,
  rejectConnection: PropTypes.func,
  isProposalUser: PropTypes.bool,
  locale: PropTypes.string,
  acceptingNudge: PropTypes.bool,
  rejectingNudge: PropTypes.bool
};

UserInfo.defaultProps = {
  showProfileDetails: true,
  conversation: null,
  attachments: null,
  acceptConnection: () => {},
  rejectConnection: () => {},
  isProposalUser: false,
  locale: '',
  acceptingNudge: false,
  rejectingNudge: false
};
