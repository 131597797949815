/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import fetchThumbnail from '../../Util/fetchThumbnail';
import LightBox from '../../Components/Lightbox/LightBox';

const youtubeIcon = '/images/small/icons/youtube-icon.png';
const vimeoIcon = '/images/small/icons/vimeo-icon.png';

const Gallery = ({ images, videoSource, videoUrl }) => {
  const [slides, setSlides] = useState([]);
  const [isLightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxIndex, setLightBoxIndex] = useState(0);

  const openLightBox = (index) => {
    setLightBoxIndex(index);
    setLightBoxOpen(true);
  };

  const handleKeyUp = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (videoSource && videoUrl) {
      setSlides([
        {
          src: videoUrl,
          type: 'video',
          provider: videoSource,
          width: 1280,
          height: 720
        },
        ...images
      ]);
    } else {
      setSlides(images);
    }
  }, []);

  return (
    <div className="py-16 w-full bg-slate-100 lg:px-5 px-3">
      <div className="gallery md:max-w-container mx-auto w-full flex items-center overflow-x-auto h-96">
        {videoUrl && videoSource && videoSource !== 'html5' && (
          <div className="md:w-auto w-full h-full relative md:min-w-max min-w-full">
            <div className="w-full h-full">
              <img
                className="md:w-auto w-full h-full object-cover object-center"
                src={fetchThumbnail(videoUrl, videoSource)}
                alt="video thumbnail"
              />
            </div>
            <div
              className="absolute w-full h-full inset-0 inline-flex items-center justify-center"
              role="button"
              onClick={() => {
                return openLightBox(0);
              }}
              onKeyUp={handleKeyUp}
            >
              <img
                src={videoSource === 'youtube' ? youtubeIcon : vimeoIcon}
                alt="play icon"
                className="w-20"
              />
            </div>
          </div>
        )}
        {images?.length ? (
          images?.map((image, idx) => {
            return (
              <img
                key={idx}
                className="md:w-auto w-full h-full object-cover object-center bg-gray-200"
                src={image?.src}
                alt={image?.alt}
                role="button"
                onClick={() => {
                  return videoSource && videoUrl
                    ? openLightBox(idx + 1)
                    : openLightBox(idx);
                }}
                onKeyUp={handleKeyUp}
              />
            );
          })
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        )}
      </div>
      {slides?.length > 0 && (
        <LightBox
          type="lightbox"
          images={slides}
          isOpen={isLightBoxOpen}
          initialIdx={lightBoxIndex}
          closeLightBox={() => {
            return setLightBoxOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default Gallery;

Gallery.propTypes = {
  images: PropTypes.instanceOf(Array),
  videoSource: PropTypes.oneOf(['vimeo', 'youtube']),
  videoUrl: PropTypes.string
};

Gallery.defaultProps = {
  images: [],
  videoSource: 'youtube',
  videoUrl: null
};
