/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import NewsfeedCard from '../../Components/Newsfeed/Card';
import { displayToast } from '../../Util/Toast';
import {
  fetchNewsfeed,
  fetchProposals,
  postNewsfeed
} from '../../Util/ApiService';
import {
  renderUserAvatar,
  formatInvestorName,
  checkProtocol,
  getLastCharacter
} from '../../Util/data';
import ModalButtons from '../../Components/Newsfeed/ModalButtons';
import NewsfeedModal from '../../Components/Newsfeed/Modal';
import NewsfeedSkeleton from '../../Components/Newsfeed/Skeleton';
import NewsfeedPopup from '../../Components/Newsfeed/Popup';

const NewsfeedPage = ({
  locale,
  loggedInUserLogo,
  preFetchedFeedResponse,
  userName,
  userId,
  pitchUpdateCount,
  postFeedUrl,
  pitchUpdatePurchaseUri,
  getProposal,
  findInvestorsUrl,
  newsfeedTypes,
  renewProposalUrl,
  publishProposalUrl,
  addProposalUrl
}) => {
  const { t } = useTranslation();
  const preFetchedFeed = JSON.parse(preFetchedFeedResponse);
  const types = newsfeedTypes;

  const [feeds, setFeeds] = useState(preFetchedFeed?.results);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [loadMoreUri, setLoadMoreUri] = useState(preFetchedFeed?.loadMoreUrl);
  const [modalType, setModalType] = useState('default');
  const [popupType, setPopupType] = useState('no-active');
  const [popupUrl, setPopupUrl] = useState('');
  const [modalTitle, setModalTitle] = useState(
    t('newsfeed.pageContent.shareUpdate')
  );
  const [posting, setPosting] = useState(false);
  const [proposals, setProposals] = useState([]);
  const [allProposals, setAllProposals] = useState([]);

  const addAdditionalDataToFeed = (
    title,
    type,
    objectName,
    array,
    timestamp
  ) => {
    const data = {
      content: title,
      newsfeedType: {
        code: type
      },
      createdDate: timestamp || null
    };
    data[objectName] = array;
    return data;
  };

  const fetchFeeds = async (uri) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const newFeeds = await fetchNewsfeed(uri);
      if (!newFeeds?.loadMoreUrl) setHasMore(false);
      setLoadMoreUri(newFeeds?.loadMoreUrl);

      let investorApprovedResponse = {};
      let investorUpdatedResponse = {};
      const proposalResponse = {};

      if (
        newFeeds?.paginatedItemsByEntityName?.investorApproved?.results?.length
      ) {
        investorApprovedResponse = addAdditionalDataToFeed(
          t('newsfeed.pageContent.latestInvestors'),
          types?.latestInvestors,
          'investorsApproved',
          newFeeds?.paginatedItemsByEntityName?.investorApproved?.results,
          newFeeds?.paginatedItemsByEntityName?.investorApproved?.results
            ?.length
            ? newFeeds?.paginatedItemsByEntityName?.investorApproved?.results[0]
                ?.firstActivatedAt
            : null
        );
      }

      if (
        newFeeds?.paginatedItemsByEntityName?.investorUpdated?.results?.length
      ) {
        const formattedName = formatInvestorName(
          newFeeds?.paginatedItemsByEntityName?.investorUpdated?.results[0]
            .fullName
        );
        investorUpdatedResponse = addAdditionalDataToFeed(
          t('newsfeed.pageContent.updatedProfile')
            .replace('%fullName%', formattedName)
            .replace(
              '%length%',
              `${
                newFeeds.paginatedItemsByEntityName.investorUpdated.results
                  .length - 1
              }`
            ),
          types?.updatedInvestors,
          'investorsUpdated',
          newFeeds?.paginatedItemsByEntityName?.investorUpdated?.results,
          newFeeds?.paginatedItemsByEntityName?.investorUpdated?.results?.length
            ? newFeeds?.paginatedItemsByEntityName?.investorUpdated?.results[0]
                ?.updatedAt
            : null
        );
      }
      setFeeds([
        ...feeds,
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...newFeeds?.results,
        investorApprovedResponse && Object.keys(investorApprovedResponse).length
          ? investorApprovedResponse
          : null,
        investorUpdatedResponse && Object.keys(investorUpdatedResponse).length
          ? investorUpdatedResponse
          : null,
        proposalResponse && Object.keys(proposalResponse).length
          ? proposalResponse
          : null
      ]);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line no-shadow, consistent-return
  const handleModalOpen = (modalType, modalTitle) => {
    if (userId === null) {
      // eslint-disable-next-line no-undef
      return toggleLoginModal(true);
    }

    if (proposals?.length > 0) {
      setModalType(modalType);
      setModalTitle(modalTitle);
      return setModalOpen(true);
    }

    if (
      allProposals?.find((proposal) => {
        return proposal.expired === true;
      })
    ) {
      setPopupType('expired');
      setPopupUrl(renewProposalUrl);
      return setPopupOpen(true);
    }

    const draftProposal = allProposals?.find((proposal) => {
      return proposal.draft === true;
    });
    if (draftProposal) {
      setPopupType('draft');
      setPopupUrl(
        publishProposalUrl.replace('PROPOSAL_ID', draftProposal.proposalId)
      );
      return setPopupOpen(true);
    }

    setPopupType('no-active');
    setPopupUrl(addProposalUrl);
    setPopupOpen(true);
  };

  const openDefaultModal = () => {
    handleModalOpen('default', t('newsfeed.pageContent.shareUpdate'));
  };

  const openImageModal = () => {
    handleModalOpen('image', t('newsfeed.pageContent.addImages'));
  };

  const openUrlModal = () => {
    handleModalOpen('url', t('newsfeed.pageContent.shareLink'));
  };

  const handlePopupHide = () => {
    return setPopupOpen(false);
  };

  const handleModalHide = (
    selectedImages,
    imagesForLightBox,
    url,
    proposalId,
    updateMessage
  ) => {
    if (
      selectedImages?.length ||
      imagesForLightBox?.length ||
      url ||
      proposalId ||
      updateMessage
    ) {
      return openDefaultModal();
    }
    return setModalOpen(false);
  };

  const handlePostSubmit = async (
    selectedImages,
    imagesForLightBox,
    url,
    proposalId,
    updateMessage
    // eslint-disable-next-line consistent-return
  ) => {
    setPosting(true);
    const formData = new FormData();
    // eslint-disable-next-line no-unused-expressions
    updateMessage && formData.append('content', updateMessage);
    // eslint-disable-next-line no-unused-expressions
    proposalId && formData.append('proposal', proposalId);
    // eslint-disable-next-line no-unused-expressions
    url && formData.append('link', checkProtocol(url));
    selectedImages.forEach((selectedImage) => {
      formData.append('images[]', selectedImage, selectedImage.name);
    });
    const response = await postNewsfeed(postFeedUrl, formData);
    if (response) {
      setFeeds([response, ...feeds]);
      setPosting(false);
      setModalOpen(false);
      displayToast(t('newsfeed.pageContent.updatePosted'), 'success');
    } else {
      return setPosting(false);
    }
  };

  const loadMoreFeeds = () => {
    if (!loadMoreUri) {
      return setHasMore(false);
    }
    return fetchFeeds(loadMoreUri);
  };

  const getProposals = async () => {
    if (proposals?.length) {
      return;
    }
    const userProposals = await fetchProposals(getProposal, {
      pageNumber: 1,
      pageSize: 25,
      user: userId
    });
    setProposals(userProposals?.results);
  };

  const getAllProposals = async () => {
    const userAllProposals = await fetchProposals(getProposal, {
      pageNumber: 1,
      pageSize: 25,
      user: userId,
      includeInActiveProposal: true
    });
    setAllProposals(userAllProposals?.results);
  };

  const checkForPreFetchedInvestorsApproved = () => {
    if (
      preFetchedFeed?.paginatedItemsByEntityName?.investorApproved?.results
        ?.length
    ) {
      return addAdditionalDataToFeed(
        t('newsfeed.pageContent.latestInvestors'),
        types?.latestInvestors,
        'investorsApproved',
        preFetchedFeed?.paginatedItemsByEntityName?.investorApproved?.results,
        preFetchedFeed?.paginatedItemsByEntityName?.investorApproved?.results
          ?.length
          ? preFetchedFeed?.paginatedItemsByEntityName?.investorApproved
              ?.results[0]?.firstActivatedAt
          : null
      );
    }
    return null;
  };

  const checkForPreFetchedInvestorsUpdated = () => {
    if (
      preFetchedFeed?.paginatedItemsByEntityName?.investorUpdated?.results
        ?.length
    ) {
      const formattedName = formatInvestorName(
        preFetchedFeed?.paginatedItemsByEntityName?.investorUpdated?.results[0]
          .fullName
      );
      return addAdditionalDataToFeed(
        t('newsfeed.pageContent.updatedProfile')
          .replace('%fullName%', formattedName)
          .replace(
            '%length%',
            `${
              preFetchedFeed.paginatedItemsByEntityName.investorUpdated.results
                .length - 1
            }`
          ),
        types?.updatedInvestors,
        'investorsUpdated',
        preFetchedFeed?.paginatedItemsByEntityName?.investorUpdated?.results,
        preFetchedFeed?.paginatedItemsByEntityName?.investorUpdated?.results
          ?.length
          ? preFetchedFeed?.paginatedItemsByEntityName?.investorUpdated
              ?.results[0]?.updatedAt
          : null
      );
    }
    return null;
  };

  const checkForPreFetchedProposals = () => {
    if (preFetchedFeed?.paginatedItemsByEntityName?.proposal?.results?.length) {
      return addAdditionalDataToFeed(
        t('newsfeed.pageContent.newProposalsAdded'),
        types?.newProposalsAdded,
        'proposals',
        preFetchedFeed?.paginatedItemsByEntityName?.proposal?.results,
        preFetchedFeed?.paginatedItemsByEntityName?.proposal?.results?.length
          ? preFetchedFeed?.paginatedItemsByEntityName?.proposal?.results[0]
              ?.publishedAt
          : null
      );
    }
    return null;
  };

  const handleKeyUp = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (userId) {
      getProposals();
      getAllProposals();
    }
    const preFetchedInvestorsApproved = checkForPreFetchedInvestorsApproved();
    const preFetchedInvestorsUpdated = checkForPreFetchedInvestorsUpdated();
    const preFetchedProposals = checkForPreFetchedProposals();
    setFeeds([
      ...feeds,
      preFetchedInvestorsApproved || null,
      preFetchedInvestorsUpdated || null,
      preFetchedProposals || null
    ]);

    // eslint-disable-next-line no-undef
    const infiniteScrollComponent = document.querySelector(
      '.infinite-scroll-component'
    );
    infiniteScrollComponent?.style?.removeProperty('overflow');
  }, []);

  return (
    <div className="bg-gray-50 pt-12 pb-32">
      <div className="md:max-w-2xl mx-auto flex flex-col gap-6 w-full lg:px-0 px-4">
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-start gap-4">
            {loggedInUserLogo ? (
              <img
                src={loggedInUserLogo}
                alt={userName}
                className="w-16 h-16 flex-shrink-0 rounded-full"
              />
            ) : (
              renderUserAvatar(userName, 'w-16 h-16 flex-shrink-0')
            )}
            <div className="flex flex-col w-full gap-3">
              <div
                onClick={openDefaultModal}
                role="button"
                className="w-full h-12 flex items-center px-3 rounded-full border border-gray-200 bg-gray-50"
                onKeyUp={handleKeyUp}
              >
                <span className="inline-block truncate max-w-full text-gray-700 text-base font-normal leading-8">
                  {t('newsfeed.pageContent.whatsLatest')}
                </span>
              </div>
              <ModalButtons
                openImageModal={openImageModal}
                openUrlModal={openUrlModal}
              />
            </div>
          </div>
        </div>

        <InfiniteScroll
          dataLength={feeds?.length}
          next={loadMoreFeeds}
          hasMore={hasMore}
          loader={<NewsfeedSkeleton />}
          endMessage={
            <p className="text-center text-base text-gray-500">
              {t('newsfeed.pageContent.reachedTheEnd')}
            </p>
          }
          className="flex flex-col gap-6"
        >
          {feeds?.map((feed, idx) => {
            return (
              feed !== null && (
                <NewsfeedCard
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  type={feed?.newsfeedType?.code}
                  locale={locale}
                  title={
                    feed?.newsfeedType?.code === types?.proposalUpdate ||
                    feed?.newsfeedType?.code === types?.proposalFeatured
                      ? feed?.proposal?.entrepreneurFullName
                      : feed?.newsfeedType?.code ===
                          types?.proposalDocumentDownloaded ||
                        feed?.newsfeedType?.code ===
                          types?.investorInterestedInProposal ||
                        feed?.newsfeedType?.code ===
                          types?.investorViewedProposal
                      ? `${feed?.user?.name?.firstName} ${
                          feed?.user?.name?.lastName?.length > 1
                            ? `${feed?.user?.name?.lastName?.slice(0, 1)}.`
                            : feed?.user?.name?.lastName
                        }`
                      : 'Angel Investment Network Team'
                  }
                  description={feed?.content}
                  time={feed?.createdDate}
                  logoUrl={feed?.user?.logoUri}
                  logoType={
                    feed?.newsfeedType?.code === types?.proposalUpdate ||
                    feed?.newsfeedType?.code === types?.proposalFeatured ||
                    feed?.newsfeedType?.code ===
                      types?.investorInterestedInProposal ||
                    feed?.newsfeedType?.code ===
                      types?.proposalDocumentDownloaded ||
                    feed?.newsfeedType?.code === types?.investorViewedProposal
                      ? 'person'
                      : 'brand'
                  }
                  proposal={feed?.proposal}
                  images={feed?.images}
                  externalLink={feed?.externalLink?.url}
                  investorsApproved={
                    feed?.investorsApproved?.length
                      ? feed?.investorsApproved
                      : null
                  }
                  investorsUpdated={
                    feed?.investorsUpdated?.length
                      ? feed?.investorsUpdated
                      : null
                  }
                  findInvestorsUrl={findInvestorsUrl}
                  proposals={feed?.proposals?.length ? feed?.proposals : null}
                  newsfeedTypes={types}
                  userProfileUri={feed?.user?.profileUri}
                  feedUserIdLastChar={
                    feed?.user?.userId
                      ? getLastCharacter(feed?.user?.userId)
                      : '0'
                  }
                />
              )
            );
          })}
        </InfiniteScroll>
      </div>
      {isPopupOpen && (
        <NewsfeedPopup
          popupType={popupType}
          hidePopup={handlePopupHide}
          url={popupUrl}
        />
      )}
      {isModalOpen && (
        <NewsfeedModal
          title={modalTitle}
          modalType={modalType}
          hideModal={handleModalHide}
          submitPost={handlePostSubmit}
          loggedInUserLogo={loggedInUserLogo}
          loggedInUserName={userName}
          openImageModal={openImageModal}
          openUrlModal={openUrlModal}
          isPosting={posting}
          proposals={proposals}
          pitchUpdateCount={pitchUpdateCount}
          pitchUpdatePurchaseUri={pitchUpdatePurchaseUri}
        />
      )}
    </div>
  );
};

export default NewsfeedPage;

NewsfeedPage.propTypes = {
  locale: PropTypes.string.isRequired,
  loggedInUserLogo: PropTypes.string,
  preFetchedFeedResponse: PropTypes.string.isRequired,
  userName: PropTypes.string,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pitchUpdateCount: PropTypes.number,
  postFeedUrl: PropTypes.string,
  pitchUpdatePurchaseUri: PropTypes.string,
  getProposal: PropTypes.string,
  findInvestorsUrl: PropTypes.string,
  newsfeedTypes: PropTypes.instanceOf(Object),
  renewProposalUrl: PropTypes.string,
  publishProposalUrl: PropTypes.string,
  addProposalUrl: PropTypes.string
};

NewsfeedPage.defaultProps = {
  loggedInUserLogo: '',
  userName: '',
  userId: null,
  pitchUpdateCount: 0,
  postFeedUrl: '',
  pitchUpdatePurchaseUri: '',
  getProposal: '',
  findInvestorsUrl: '',
  newsfeedTypes: null,
  renewProposalUrl: '',
  publishProposalUrl: '',
  addProposalUrl: ''
};
