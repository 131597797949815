/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React, { useState, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import MessageList from './MessageList';
import { getLastCharacter } from '../../Util/data';

const Sidebar = ({
  conversations,
  updateSelectedConversation,
  loading,
  activeId,
  updatedReadStatus,
  totalMessages,
  fetchMoreMessages,
  hasMoreData,
  filterMessage,
  filterMessageByQuery,
  noResults,
  updateReadStatus,
  markAsArchived,
  markAsUnarchived,
  locale,
  showSidebar
}) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState('');
  const searchSection = useRef();
  const [searchSectionHeight, setSearchSectionHeight] = useState(null);
  const [selectedOption, setSelectedOption] = useState('all');

  const options = ['all', 'deleted', 'read', 'unread'];

  const handleFormSubmit = (event) => {
    event.preventDefault();
    filterMessageByQuery(query);
  };

  const handleKeyUp = (e) => {
    e.preventDefault();
  };

  const getTargetEvent = (event, conversation) => {
    const itemIsDropdownToggler =
      event.target.classList.contains('sidebar-drop-btn');
    const itemParentIsDropdownToggler =
      event.target.parentElement.classList.contains('sidebar-drop-btn');
    const itemIsDropdownContent =
      event.target.classList.contains('message-options');
    const itemIsDropdownContentChild =
      event.target.parentElement.classList.contains('message-options');
    if (
      itemIsDropdownToggler ||
      itemParentIsDropdownToggler ||
      itemIsDropdownContent ||
      itemIsDropdownContentChild
    ) {
      return;
    }

    updateSelectedConversation(
      conversation?.messagesApiUri,
      `${conversation?.interlocutor?.name?.firstName} ${conversation?.interlocutor?.name?.lastName}`,
      conversation?.proposal?.title,
      conversation?.interlocutor?.logoUri,
      conversation?.interlocutor?.userId,
      conversation?.connectionId
    );
  };

  const changeFilter = (option) => {
    setSelectedOption(option);
    filterMessage(option);
  };

  useEffect(() => {
    const sectionHeight = searchSection.current.offsetHeight;
    setSearchSectionHeight(sectionHeight);
  }, []);

  return (
    <div
      className={`${
        showSidebar ? 'block' : 'md:block hidden'
      } xl:w-1/4 md:w-1/3 w-full h-full border-r border-gray-200 transition-all`}
      id="sidebar"
    >
      <div className="w-full" ref={searchSection}>
        <div className="w-full px-3 md:py-3 flex items-center justify-between">
          <p className="font-semibold text-gray-800">
            {selectedOption === 'all'
              ? t('auth.inbox.messages')
              : t(`auth.inbox.${selectedOption}`).concat(
                  ` ${t('auth.inbox.messages').toLowerCase()}`
                )}
          </p>
          <div className="relative group md:p-0 p-4 pr-0" role="button">
            <span className="material-icons">tune</span>
            <div className="absolute right-0 rounded-lg z-10 bg-white border border-gray-200 hidden visible group-hover:block shadow p-2">
              <ul className="whitespace-nowrap w-52 max-w-sm">
                {options.map((option, index) => {
                  return (
                    <li
                      role="button"
                      key={index}
                      className="text-base font-normal px-4 py-2 hover:bg-gray-50 rounded"
                      onClick={() => {
                        return changeFilter(option);
                      }}
                      onKeyUp={handleKeyUp}
                    >
                      {t(`auth.inbox.${option}`)}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full px-3 pb-3 flex items-center gap-3">
          <div className="w-full flex items-center rounded-md overflow-hidden bg-gray-200">
            <span className="text-gray-600 material-icons md:inline-flex pl-3 justify-center">
              search
            </span>
            <form onSubmit={handleFormSubmit} className="w-full">
              <input
                type="text"
                className="
                  text-left
                  px-3
                  py-2
                  border
                  w-full
                  grow
                  border-0
                  bg-transparent
                  placeholder-gray-300
                  focus:outline-none
                "
                placeholder={t('auth.inbox.searchInbox')}
                onChange={(e) => {
                  return setQuery(e.target.value);
                }}
              />
            </form>
          </div>
        </div>
      </div>
      <div
        id="scrollTarget"
        className="w-full flex flex-col items-center overflow-y-auto max-h-full md:px-0 px-3"
        style={{ height: `calc(100% - ${searchSectionHeight}px)` }}
      >
        <ul className="w-full divide-y divide-slate-100 pb-28 md:h-auto h-screen">
          {loading ? (
            Array.from(Array(2), (_, i) => {
              return (
                <li key={i}>
                  <div className="flex gap-3 items-start p-4">
                    <div className="rounded-full bg-gray-200 w-12 h-10 animate-pulse flex-grow" />
                    <div className="rounded-md bg-gray-200 w-full h-24 animate-pulse" />
                  </div>
                </li>
              );
            })
          ) : noResults ? (
            <div className="my-10 text-center px-3 break-words">
              {t('auth.inbox.noMessages')}
            </div>
          ) : (
            <InfiniteScroll
              dataLength={totalMessages}
              next={() => {
                return fetchMoreMessages();
              }}
              hasMore={hasMoreData}
              scrollableTarget="scrollTarget"
              loader={
                <div className="text-center">
                  <svg
                    className="animate-spin inline h-3.5 w-3.5 fill-current text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <title>Loading...</title>
                    <path
                      className="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                </div>
              }
            >
              {conversations.map((conversation) => {
                return conversation.lastMessage ? (
                  <li
                    key={conversation.connectionId}
                    role="button"
                    onClick={(event) => {
                      return getTargetEvent(event, conversation);
                    }}
                    id={conversation.connectionId}
                    onKeyUp={handleKeyUp}
                  >
                    <MessageList
                      image={conversation.interlocutor.logoUri}
                      lastUpdated={conversation.lastMessage.sentDate}
                      lastMessage={conversation.lastMessage.content}
                      userIdLastChar={getLastCharacter(
                        conversation.interlocutor.userId
                      )}
                      userName={
                        conversation.interlocutor.logoUri
                          ? conversation.interlocutor.name.firstName
                          : `${conversation.interlocutor.name.firstName} ${conversation.interlocutor.name.lastName}`
                      }
                      proposalTitle={conversation.proposal.title}
                      isSelected={activeId === conversation.connectionId}
                      isRead={conversation?.read}
                      updatedReadStatus={updatedReadStatus}
                      connectionId={conversation.connectionId}
                      changeStatus={updateReadStatus}
                      setAsArchived={markAsArchived}
                      isArchived={conversation.archived}
                      restoreConnection={markAsUnarchived}
                      locale={locale}
                      isProposalUser={conversation.viewingByProposalUser}
                      status={conversation.status.code}
                    />
                  </li>
                ) : (
                  <li
                    key={conversation.connectionId}
                    role="button"
                    onClick={(event) => {
                      return getTargetEvent(event, conversation);
                    }}
                    id={conversation.connectionId}
                    onKeyUp={handleKeyUp}
                  >
                    <MessageList
                      image={conversation.interlocutor.logoUri}
                      userIdLastChar={getLastCharacter(
                        conversation.interlocutor.userId
                      )}
                      userName={`${conversation.interlocutor.name.firstName}`}
                      proposalTitle={conversation.proposal.title}
                      isSelected={activeId === conversation.connectionId}
                      isRead={conversation?.read}
                      updatedReadStatus={updatedReadStatus}
                      connectionId={conversation.connectionId}
                      locale={locale}
                      isProposalUser={conversation.viewingByProposalUser}
                      status={conversation.status.code}
                      changeStatus={updateReadStatus}
                    />
                  </li>
                );
              })}
            </InfiniteScroll>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  conversations: PropTypes.instanceOf(Array),
  updateSelectedConversation: PropTypes.func,
  loading: PropTypes.bool,
  activeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalMessages: PropTypes.number,
  fetchMoreMessages: PropTypes.func,
  hasMoreData: PropTypes.bool,
  filterMessage: PropTypes.func,
  filterMessageByQuery: PropTypes.func,
  noResults: PropTypes.bool,
  updateReadStatus: PropTypes.func,
  markAsArchived: PropTypes.func,
  markAsUnarchived: PropTypes.func,
  updatedReadStatus: PropTypes.bool,
  locale: PropTypes.string,
  showSidebar: PropTypes.bool
};

Sidebar.defaultProps = {
  conversations: [],
  updateSelectedConversation: () => {},
  loading: false,
  activeId: '',
  totalMessages: 0,
  fetchMoreMessages: () => {},
  hasMoreData: false,
  filterMessage: () => {},
  filterMessageByQuery: () => {},
  noResults: false,
  updateReadStatus: () => {},
  markAsArchived: () => {},
  markAsUnarchived: () => {},
  updatedReadStatus: false,
  locale: '',
  showSidebar: false
};
