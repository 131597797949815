import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';

const LogoInput = ({ handleLogoChange, removeImage, prefetchedLogo }) => {
  const [logoImage, setLogoImage] = useState(null);
  const fileInput = useRef();

  const handleFileInput = (event) => {
    setLogoImage(URL.createObjectURL(event.target.files[0]));
    handleLogoChange(event.target.files[0]);
  };

  const deleteImage = () => {
    setLogoImage(null);
    removeImage();
  };

  const triggerLogoInput = () => {
    fileInput?.current?.click();
  };

  useEffect(() => {
    if (prefetchedLogo) {
      if (typeof prefetchedLogo === 'string') {
        setLogoImage(prefetchedLogo);
      } else {
        setLogoImage(URL.createObjectURL(prefetchedLogo));
      }
    }
  }, [prefetchedLogo]);

  return (
    <div className="flex items-center w-full gap-6">
      <div
        className={`
          rounded-full
          w-24
          h-24
          overflow-hidden
          inline-flex
          items-center
          justify-center
          bg-gray-50
          ${prefetchedLogo || logoImage ? 'border-0' : 'border-2'}
          border-gray-400
          border-dashed
          text-gray-500
        `}
      >
        {logoImage ? (
          <img
            src={logoImage}
            alt="logo"
            className="w-full h-full object-cover object-center"
          />
        ) : (
          <>
            <span className="material-icons text-3xl">person</span>
            <input
              ref={fileInput}
              onChange={handleFileInput}
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              id="logo-input"
              name="file"
              className="hidden"
            />
          </>
        )}
      </div>
      <div className="inline-flex items-center gap-2 flex-grow flex-wrap sm:flex-nowrap">
        {logoImage ? (
          <button
            onClick={deleteImage}
            type="button"
            className="bg-gray-200 text-gray-800 rounded-md p-2 logo-delete inline-flex items-center justify-center"
          >
            <span className="material-icons">delete</span>
          </button>
        ) : (
          <button
            type="button"
            className="bg-gray-200 text-gray-800 rounded-md py-2 px-4 text-base font-semibold"
            onClick={triggerLogoInput}
          >
            Upload an image
          </button>
        )}
      </div>
    </div>
  );
};

export default LogoInput;

LogoInput.propTypes = {
  handleLogoChange: PropTypes.func.isRequired,
  prefetchedLogo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object)
  ]),
  removeImage: PropTypes.func
};

LogoInput.defaultProps = {
  prefetchedLogo: null,
  removeImage: () => {}
};
