var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import reactStringReplace from 'react-string-replace';
import { useTranslation } from 'react-i18next';
import { fetchNotifications } from '../../Util/ApiService';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import NotificationSkeleton from '../../Components/Notification/NotificationSkeleton';
const Notification = ({ loadMoreUri, locale, notificationsString, pageSize, totalResults }) => {
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState(JSON.parse(notificationsString));
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(totalResults > pageNumber * pageSize);
    const [isLoading, setIsLoading] = useState(false);
    const skeletonCards = Array(3).fill(React.createElement(NotificationSkeleton, null));
    const fetchNewNotifications = (uri) => __awaiter(void 0, void 0, void 0, function* () {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        const newPageNumber = pageNumber + 1;
        try {
            const newNotifications = yield fetchNotifications(uri, {
                pageNumber: newPageNumber,
                pageSize
            });
            if (!newNotifications.results.length)
                setHasMore(false);
            setNotifications([...notifications, ...newNotifications.results]);
            setPageNumber(newPageNumber);
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }
    });
    const loadMoreNotifications = () => {
        if (totalResults < pageNumber * pageSize) {
            return setHasMore(false);
        }
        return fetchNewNotifications(loadMoreUri);
    };
    useEffect(() => {
        var _a;
        // eslint-disable-next-line no-undef
        const infiniteScrollComponent = document.querySelector('.infinite-scroll-component');
        (_a = infiniteScrollComponent === null || infiniteScrollComponent === void 0 ? void 0 : infiniteScrollComponent.style) === null || _a === void 0 ? void 0 : _a.removeProperty('overflow');
    }, []);
    const getNotificationContent = (notification) => {
        const underlineClass = 'hover:underline';
        const entrepreneurName = notification.createdByUser && (React.createElement("span", null, `${notification.createdByUser.name.firstName} ${notification.createdByUser.name.lastName[0]}.`));
        const investorLink = notification.createdByUser && (React.createElement("a", { href: notification.createdByUser.profileUri, className: underlineClass }, `${notification.createdByUser.name.firstName} ${notification.createdByUser.name.lastName[0]}.`));
        const inboxConnectionLink = notification.connection && (React.createElement("a", { href: notification.connection.inboxConnectionUri, className: underlineClass }, t('notifications.pageContent.notification.common.inboxLinkCaption')));
        const proposalLink = notification.proposal && (React.createElement("a", { href: notification.proposal.proposalPageUri, className: underlineClass }, notification.proposal.title));
        const notificationBodyBase = t(`notifications.pageContent.notification.bodyTemplate.${notification.notificationType.code}`)
            .replace('%adjective%', t(`homepage.adjective.${notification.recipientSite.code}`))
            .replace('%investorCount%', notification.count.toString())
            .replace('%count%', notification.count.toString());
        let notificationBody = reactStringReplace(notificationBodyBase, '%entrepreneur%', () => {
            return React.createElement("span", { key: uuidv4() }, entrepreneurName);
        });
        notificationBody = reactStringReplace(notificationBody, '%investorLink%', () => {
            return React.createElement("span", { key: uuidv4() }, investorLink);
        });
        notificationBody = reactStringReplace(notificationBody, '%inboxConnectionLink%', () => {
            return React.createElement("span", { key: uuidv4() }, inboxConnectionLink);
        });
        notificationBody = reactStringReplace(notificationBody, '%proposalLink%', () => {
            return React.createElement("span", { key: uuidv4() }, proposalLink);
        });
        return notificationBody;
    };
    return (React.createElement(InfiniteScroll, { dataLength: notifications.length, next: loadMoreNotifications, hasMore: hasMore, loader: skeletonCards.map((card, i) => {
            // eslint-disable-next-line react/no-array-index-key
            return React.createElement(NotificationSkeleton, { key: `${card}-${i}` });
        }), className: "w-full flex flex-col gap-4 divide-y divide-gray-200" }, notifications.map((notification) => {
        const notificationContent = getNotificationContent(notification);
        return (React.createElement("div", { key: `${notification.updated}-${notification.notificationType.code}-${uuidv4()}`, className: "w-full py-4 grid grid-cols-5 lg:gap-8 gap-3" },
            React.createElement("div", { className: "col-span-4 flex items-center lg:gap-12 gap-3" },
                React.createElement("div", { className: "w-16 h-16 rounded-full overflow-hidden flex-shrink-0" },
                    React.createElement("img", { src: "/images/small/logo/ain_new-logo.png", alt: "AIN", className: "w-full h-full object-cover object-center" })),
                React.createElement("p", { className: "text-gray-800 text-lg font-medium" }, notificationContent)),
            React.createElement("div", { className: "col-span-1 inline-flex items-center justify-end" },
                React.createElement("p", { className: "text-gray-800 text-lg md:text-left text-right" },
                    React.createElement("em", null, moment(notification.countUpdatedAt).locale(locale).fromNow())))));
    })));
};
export default Notification;
