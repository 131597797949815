import React from 'react';

const NotificationSkeleton = () => {
  return (
    <div className="w-full py-4 grid grid-cols-3 lg:gap-8 gap-3">
      <div className="col-span-2 flex items-center lg:gap-12 gap-3">
        <div className="w-16 h-16 rounded-full overflow-hidden flex-shrink-0 animate-pulse bg-gray-300" />
        <div className="h-7 animate-pulse bg-gray-200 w-3/4" />
      </div>
      <div className="col-span-1 inline-flex items-center justify-end">
        <div className="md:w-1/4 w-1/2 bg-gray-200 h-7 animate-pulse bg-gray-200" />
      </div>
    </div>
  );
};

export default NotificationSkeleton;
