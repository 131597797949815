import React from 'react';
import * as PropTypes from 'prop-types';
import {
  renderUserAvatar,
  formatInvestorName,
  getLastCharacter
} from '../../Util/data';

const NewsfeedInvestorCard = ({ investor }) => {
  return (
    <a
      href={investor?.detailsUrl}
      target="_blank"
      className="block w-full"
      rel="noreferrer"
    >
      <div className="flex w-full items-center rounded-lg p-3 gap-x-4 bg-white shadow">
        {investor?.logoUrl ? (
          <img
            src={investor?.logoUrl}
            alt={investor?.fullName}
            className="md:w-20 md:h-20 w-16 h-16 object-cover object-center flex-shrink-0 rounded-full"
          />
        ) : (
          renderUserAvatar(
            investor?.fullName,
            'md:w-20 md:h-20 w-16 h-16 flex-shrink-0',
            `bg-${getLastCharacter(investor?.investorId)}`
          )
        )}
        <div className="flex gap-x-3 items-center flex-grow">
          <div className="flex flex-col w-full h-full">
            <h5 className="font-semibold text-gray-800 sm:text-lg text-base leading-5">
              {formatInvestorName(investor?.fullName)}
            </h5>
            <span className="hover:underline text-gray-700 sm:text-base text-sm leading-5 font-normal break-all">
              {
                // eslint-disable-next-line no-undef
                `${window?.location?.host}${investor?.detailsUrl}`
              }
            </span>
          </div>
          <div className="w-6 h-6 m-3 md:inline-flex hidden justify-end">
            <span className="text-gray-600 material-icons">open_in_new</span>
          </div>
        </div>
      </div>
    </a>
  );
};

export default NewsfeedInvestorCard;

NewsfeedInvestorCard.propTypes = {
  investor: PropTypes.instanceOf(Object).isRequired
};
