import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProposalSummary from '../../Components/ProposalSummary/ProposalSummary';
import AddProposalCard from '../../Components/AddProposalCard/AddProposalCard';
import DashboardBottomSheet from '../../Components/Dashboard/DashboardBottomSheet';
import { removeEntryFromArray } from '../../Util/data';
import { deleteProposal } from '../../Util/ApiService';

const EntrepreneurDashboard = ({
  createProposalUri,
  currencyCode,
  deleteProposalUri,
  locale,
  preFetchedProposals,
  subscriptionTypes
}) => {
  const { t } = useTranslation();

  const preSetProposals = preFetchedProposals
    ? JSON.parse(preFetchedProposals)
    : null;
  const [proposals, setProposals] = useState([]);
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalType, setModalType] = useState(null);
  const [confirmButtonText, setConfirmButtonText] = useState('');
  const [discardButtonText, setDiscardButtonText] = useState('');
  const [selectedProposalIdForDeletion, setProposalIdForDeletion] =
    useState(null);

  const handleConfirmClick = async (type) => {
    if (type === 'add') {
      // eslint-disable-next-line no-undef
      window.location.href = createProposalUri;
    } else if (type === 'delete') {
      await deleteProposal(
        deleteProposalUri.replace(
          'PROPOSAL_ID',
          selectedProposalIdForDeletion.toString()
        )
      ).then(() => {
        const selectedProposal = proposals.find((proposal) => {
          return proposal.proposalId === selectedProposalIdForDeletion;
        });
        const selectedProposalIndex = proposals.indexOf(selectedProposal);
        const proposalsArray = [...proposals];
        const newArray = removeEntryFromArray(
          proposalsArray,
          selectedProposalIndex
        );
        setProposals([...newArray]);
        setDisplayOverlay(false);
      });
    }
  };

  const deletePitch = (proposalId) => {
    setProposalIdForDeletion(proposalId);
    setDisplayOverlay(true);
    setModalType('delete');
    setModalTitle(t('entrepreneurDashboard.modals.deletePitch.title'));
    setModalDescription(
      t('entrepreneurDashboard.modals.deletePitch.description')
    );
    setConfirmButtonText(t('entrepreneurDashboard.modals.deletePitch.button1'));
    setDiscardButtonText(t('entrepreneurDashboard.modals.deletePitch.button2'));
  };

  const handleCreatePitchClick = () => {
    if (proposals.length) {
      setDisplayOverlay(true);
      setModalType('add');
      setModalTitle(t('entrepreneurDashboard.modals.alreadyHavePitch.title'));
      setModalDescription(
        t('entrepreneurDashboard.modals.alreadyHavePitch.description')
      );
      setConfirmButtonText(
        t('entrepreneurDashboard.modals.alreadyHavePitch.button1')
      );
      setDiscardButtonText(
        t('entrepreneurDashboard.modals.alreadyHavePitch.button2')
      );
    } else {
      // eslint-disable-next-line no-undef
      window.location.href = createProposalUri;
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (preSetProposals) {
      return setProposals(preSetProposals);
    }
  }, []);

  return (
    <div className="w-full flex flex-col items-center pt-8 min-h-screen">
      <div className="w-full lg:max-w-screen-xl">
        <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 p-4">
          <AddProposalCard
            buttonTitle={t('entrepreneurDashboard.createPitch')}
            onClick={handleCreatePitchClick}
          />
          {proposals?.map((proposal) => {
            return (
              <ProposalSummary
                key={proposal.proposalUri}
                proposal={proposal}
                locale={locale}
                currencyCode={currencyCode}
                isEntrepreneurDashboard
                deletePitchFromDashboard={(proposalId) => {
                  return deletePitch(proposalId);
                }}
                subscriptionTypeCodes={subscriptionTypes}
              />
            );
          })}
        </div>
      </div>
      {displayOverlay && (
        <DashboardBottomSheet
          title={modalTitle}
          description={modalDescription}
          confirmButtonText={confirmButtonText}
          discardButtonText={discardButtonText}
          handleConfirmButtonClick={(type) => {
            return handleConfirmClick(type);
          }}
          hideModal={() => {
            return setDisplayOverlay(false);
          }}
          modalType={modalType}
        />
      )}
    </div>
  );
};

export default EntrepreneurDashboard;

EntrepreneurDashboard.propTypes = {
  createProposalUri: PropTypes.string.isRequired,
  currencyCode: PropTypes.string,
  deleteProposalUri: PropTypes.string.isRequired,
  locale: PropTypes.string,
  preFetchedProposals: PropTypes.string,
  subscriptionTypes: PropTypes.instanceOf(Object)
};

EntrepreneurDashboard.defaultProps = {
  currencyCode: '',
  locale: '',
  preFetchedProposals: null,
  subscriptionTypes: null
};
