/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
import React, { useState, useRef, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader/Loader';
import MessageBox from './MessageBox';
import PitchExpiredPrompt from './PitchExpiredPrompt';
import {
  removeEntryFromArray,
  renderDocumentIconOld,
  renderUserAvatar
} from '../../Util/data';
import { connectionStatusCodes } from '../../Util/connectionStatuses';
import InvestorDeactivatedPrompt from './InvestorDeactivatedPrompt';
import Tooltip from '../Tooltip/Tooltip';

const MessageSection = ({
  toggleProfileDetails,
  showProfileDetails,
  conversation,
  avatar,
  loading,
  handleMessage,
  sending,
  recipient,
  proposalTitle,
  updateReadStatus,
  markAsArchived,
  restoreConnection,
  isRead,
  isProposalUser,
  canViewMessages,
  canPostMessages,
  connection,
  locale,
  acceptConnection,
  rejectConnection,
  loadMoreMessages,
  interestedUserDeactivated,
  acceptingNudge,
  rejectingNudge
}) => {
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef();
  const messageContainer = useRef();
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [threadAlreadyRead, setThreadReadStatus] = useState(isRead);

  const { t } = useTranslation();

  const scrollToBottom = () => {
    if (!messagesEndRef.current || messagesEndRef.current === undefined) {
      return;
    }
    const topPos = messagesEndRef.current.offsetTop;
    messageContainer.current.scrollTop = topPos;
    // messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleMessage(message, attachments).then(() => {
      setMessage('');
      setAttachments([]);
    });
  };

  const handleFileInput = (e) => {
    const files = [];
    Array.from(e.target.files).forEach((file) => {
      files.push(file);
    });
    setAttachments([...attachments.concat(files)]);
  };

  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === 'Enter') {
      handleFormSubmit(e);
    }
  };

  const deleteAttachment = (event, index) => {
    event.preventDefault();
    const selectedAttachments = [...attachments];
    const newArray = removeEntryFromArray(selectedAttachments, index);
    setAttachments([...newArray]);
  };

  const getDocumentIcon = (fileName) => {
    const icon = renderDocumentIconOld(fileName);

    return (
      <img
        src={icon}
        alt={fileName}
        title={fileName}
        className="w-full h-full object-center object-contain"
      />
    );
  };

  const renderMessages = () => {
    return connection &&
      connection.status.code === connectionStatusCodes.pendingAcceptance &&
      !isProposalUser ? (
      <div>
        <MessageBox
          sentByCurrentUser={false}
          conversationUserName={recipient}
          logoUrl={avatar}
          isPending
          connection={connection}
          locale={locale}
          acceptConnection={acceptConnection}
          rejectConnection={rejectConnection}
          isProposalUser={isProposalUser}
          lastMessage={connection.lastMessage}
          acceptingNudge={acceptingNudge}
          rejectingNudge={rejectingNudge}
        />
        <div ref={messagesEndRef} />
      </div>
    ) : (
      messages && (
        <>
          {
            // eslint-disable-next-line no-shadow
            [...messages].reverse().map((message) => {
              return (
                <MessageBox
                  key={message.sentDate}
                  loading={loading}
                  sentByCurrentUser={message.sentByCurrentUser}
                  message={message.content}
                  attachments={message.attachments}
                  date={message.sentDate}
                  conversationUserName={`${message.sender.name.firstName} ${message.sender.name.lastName}`}
                  logoUrl={message.sender.logoUri}
                  isPending={false}
                  isProposalUser={isProposalUser}
                  acceptingNudge={acceptingNudge}
                  rejectingNudge={rejectingNudge}
                />
              );
            })
          }
          <div ref={messagesEndRef} />
        </>
      )
    );
  };

  const archiveMessage = () => {
    if (connection) {
      markAsArchived(connection.connectionUserActions.markAsArchivedUri);
    }
  };

  const restoreMessage = () => {
    if (connection) {
      restoreConnection(connection.connectionUserActions.markAsNotArchivedUri);
    }
  };

  useEffect(() => {
    if (conversation !== '' && conversation.results.length) {
      // eslint-disable-next-line no-unused-expressions
      conversation.pagination.totalResults >
      conversation.pagination.pageNumber * conversation.pagination.pageSize
        ? setHasMoreMessages(true)
        : setHasMoreMessages(false);
      if (isProposalUser && !canPostMessages && !canViewMessages) {
        setHasMoreMessages(false);
      }
      // eslint-disable-next-line no-unused-expressions
      conversation.pagination.pageNumber === 1
        ? setMessages(conversation.results)
        : setMessages([...messages.concat(conversation.results)]);
    }
    if (conversation && conversation.pagination.pageNumber === 1) {
      scrollToBottom();
    }
  }, [conversation.results]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isRead ? setThreadReadStatus(true) : setThreadReadStatus(false);
  }, [recipient, isRead, connection]);

  useEffect(() => {
    setMessages([]);
  }, [recipient]);

  return (
    <div
      className={`
        lg:block
        w-5/6
        h-full
        border-r
        border-slate-100
        relative
        overflow-hidden
        transition-all
        lg:block
        md:w-2/3
        ${showProfileDetails ? 'lg:w-2/4' : 'lg:w-3/4'}
      `}
      id="message-section"
    >
      <div className="flex flex-col w-full h-full">
        <div className="w-full flex p-0.5 items-center justify-between border-b border-slate-100 sticky top-0">
          {loading ? (
            <>
              <div className="px-4 inline-flex gap-3 items-center">
                <div className="rounded-full bg-gray-200 h-11 w-11 animate-pulse" />
                <div className="h-6 w-16 bg-gray-200 rounded animate-pulse" />
              </div>
              <div className="w-48 h-6 bg-gray-200 rounded animate-pulse" />
            </>
          ) : (
            <>
              <div className="px-4 inline-flex gap-3 items-center">
                {avatar ? (
                  <img
                    src={avatar}
                    alt={recipient}
                    className="w-11 h-11 rounded-full object-center object-cover"
                  />
                ) : (
                  renderUserAvatar(recipient, null, `bg-1`)
                )}
                <p className="text-gray-800 text-lg lg:block lg:visible hidden">
                  {recipient}
                </p>
              </div>
              <p className="px-4 font-semibold text-gray-800 text-lg truncate">
                {proposalTitle}
              </p>
            </>
          )}
          <div className="flex items-center">
            <div className="flex items-center lg:border-r border-slate-100">
              <Tooltip
                text={
                  threadAlreadyRead
                    ? t('auth.inbox.markAsUnread')
                    : t('auth.inbox.markAsRead')
                }
                classNames="bottom-0 -mb-2.5 whitespace-nowrap"
              >
                <button
                  type="button"
                  className="h-full p-3"
                  onClick={() => {
                    return threadAlreadyRead
                      ? updateReadStatus(false)
                      : updateReadStatus(true);
                  }}
                >
                  <span className="material-icons">
                    {threadAlreadyRead ? 'email' : 'drafts'}
                  </span>
                </button>
              </Tooltip>
              {connection && !connection.archived ? (
                <Tooltip
                  text={t('auth.inbox.delete')}
                  classNames="bottom-0 -mb-2.5"
                >
                  <button
                    type="button"
                    className="h-full p-3"
                    onClick={archiveMessage}
                  >
                    <span className="material-icons">delete</span>
                  </button>
                </Tooltip>
              ) : (
                <Tooltip
                  text={t('auth.inbox.restore')}
                  classNames="bottom-0 -mb-2.5"
                >
                  <button
                    type="button"
                    className="h-full p-3"
                    onClick={restoreMessage}
                  >
                    <span className="material-icons">restore</span>
                  </button>
                </Tooltip>
              )}
            </div>
            <button
              type="button"
              className="lg:block lg:visible hidden h-full px-5 "
              onClick={toggleProfileDetails}
            >
              <span
                className={`material-icons transform transition-all ${
                  showProfileDetails ? '' : '-rotate-180'
                }`}
              >
                chevron_right
              </span>
            </button>
          </div>
        </div>
        {connection &&
        connection.status.code === connectionStatusCodes.rejected ? (
          <div className="py-4 md:px-4 mx-auto">
            {isProposalUser
              ? t('auth.inbox.rejected')
              : t('auth.inbox.connectionRejected')}
          </div>
        ) : connection &&
          connection.status.code === connectionStatusCodes.deleted ? (
          <div className="py-4 md:px-4 mx-auto">
            {t('auth.inbox.pitchDeleted')}
          </div>
        ) : (
          <div
            id="scrollableTarget"
            ref={messageContainer}
            className={`
              flex
              w-full
              overflow-auto
              flex-grow py-2
              ${
                isProposalUser && !canPostMessages && !canViewMessages
                  ? ''
                  : 'flex-col-reverse'
              }
            `}
          >
            <InfiniteScroll
              dataLength={
                conversation && conversation.pagination
                  ? conversation.pagination.pageNumber * 6
                  : 0
              } // 6 is pageSize
              next={() => {
                return loadMoreMessages();
              }}
              inverse
              hasMore={
                hasMoreMessages &&
                connection &&
                connection.status.code !==
                  connectionStatusCodes.pendingAcceptance
              }
              loader={
                conversation !== '' && (
                  <span className="text-sm inline-block mx-auto">
                    {t('auth.inbox.loading')}
                  </span>
                )
              }
              scrollableTarget="scrollableTarget"
              className="flex flex-col-reverse"
            >
              <div className="w-full h-full px-5 py-10">
                {isProposalUser && interestedUserDeactivated ? (
                  <InvestorDeactivatedPrompt />
                ) : isProposalUser && !canPostMessages && !canViewMessages ? (
                  <PitchExpiredPrompt
                    proposalId={
                      connection
                        ? connection.proposal.proposalApiUri.match(/\d+/)[0]
                        : null
                    }
                  />
                ) : (
                  renderMessages()
                )}
              </div>
            </InfiniteScroll>
          </div>
        )}

        {canPostMessages && (
          <div className="w-full bottom-0 mt-auto px-6 py-4 border-t border-slate-100 bg-white">
            <form className="w-full" onSubmit={handleFormSubmit}>
              <textarea
                className="w-full border-2 border-slate-100 rounded-md p-4"
                cols="20"
                rows="2"
                placeholder={t('auth.inbox.startTyping')}
                onChange={(e) => {
                  return setMessage(e.target.value);
                }}
                onKeyDown={(e) => {
                  return handleKeyDown(e);
                }}
                value={message}
                required
              />

              <div className="w-full flex justify-between items-center py-3">
                <div className="relative w-10 h-10 cursor-pointer">
                  <div className="absolute w-full h-full">
                    <button
                      type="button"
                      className="material-icons absolute inset-0 w-full h-full"
                    >
                      attach_file
                    </button>
                  </div>
                  <input
                    type="file"
                    className="block opacity-0 w-full h-full cursor-pointer"
                    multiple
                    accept=".doc,.docx,.ppt,.pptx,.ppsx,.xls,.xlsx,.pdf"
                    onChange={(e) => {
                      return handleFileInput(e);
                    }}
                  />
                </div>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-8 py-2 rounded-md"
                >
                  {sending ? (
                    <Loader title={t('auth.inbox.sending')} />
                  ) : (
                    `${t('auth.inbox.send')}`
                  )}
                </button>
              </div>
            </form>
            <div className="flex gap-2 w-full items-center overflow-x-auto h-auto">
              {attachments?.length > 0 &&
                attachments?.map((attachment, index) => {
                  return (
                    <div
                      key={index}
                      className="w-1/5 p-4 gap-y-2 h-full flex flex-col items-center justify-center"
                    >
                      <div className="relative md:w-1/3 w-full h-full">
                        {getDocumentIcon(attachment.name)}
                        <button
                          type="button"
                          className="
                          absolute
                          -top-1
                          -right-1
                          w-5
                          h-5
                          rounded-full
                          inline-flex
                          items-center
                          justify-center
                          bg-white
                          shadow
                        "
                          title="Delete attachment"
                          onClick={(event) => {
                            return deleteAttachment(event, index);
                          }}
                        >
                          <span className="material-icons text-sm">close</span>
                        </button>
                      </div>
                      <p className="text-gray-800 text-xs truncate max-w-full">
                        {attachment.name}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageSection;

MessageSection.propTypes = {
  toggleProfileDetails: PropTypes.func,
  showProfileDetails: PropTypes.bool,
  conversation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array)
  ]),
  avatar: PropTypes.string,
  loading: PropTypes.bool,
  handleMessage: PropTypes.func,
  sending: PropTypes.bool,
  recipient: PropTypes.string,
  proposalTitle: PropTypes.string,
  updateReadStatus: PropTypes.func,
  markAsArchived: PropTypes.func,
  restoreConnection: PropTypes.func,
  isRead: PropTypes.bool,
  isProposalUser: PropTypes.bool,
  canViewMessages: PropTypes.bool,
  canPostMessages: PropTypes.bool,
  connection: PropTypes.instanceOf(Object),
  locale: PropTypes.string,
  acceptConnection: PropTypes.func,
  rejectConnection: PropTypes.func,
  loadMoreMessages: PropTypes.func,
  interestedUserDeactivated: PropTypes.bool,
  acceptingNudge: PropTypes.bool,
  rejectingNudge: PropTypes.bool
};

MessageSection.defaultProps = {
  toggleProfileDetails: () => {},
  showProfileDetails: false,
  conversation: null,
  avatar: '',
  loading: false,
  handleMessage: () => {},
  sending: false,
  recipient: '',
  proposalTitle: '',
  updateReadStatus: () => {},
  markAsArchived: () => {},
  restoreConnection: () => {},
  isRead: false,
  isProposalUser: false,
  canViewMessages: false,
  canPostMessages: false,
  connection: {},
  locale: '',
  acceptConnection: () => {},
  rejectConnection: () => {},
  loadMoreMessages: () => {},
  interestedUserDeactivated: false,
  acceptingNudge: false,
  rejectingNudge: false
};
