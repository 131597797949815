/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';
import ApplyClearButton from '../Buttons/Apply';
import Checkbox from '../Lists/Checkbox';
import RangeSlider from '../RangeSlider/RangeSlider';
import { fetchPlaces } from '../../Util/ApiService';
import MultiRangeSlider from '../RangeSlider/MultiRangeSlider';
import { updateDropdownPlacement } from '../../Util/data';

const Dropdown = ({
  title,
  showDropdown,
  category,
  onShowDropdown,
  onHideDropdown,
  array,
  selectedArray = [],
  selectedChildrenArray = [],
  setValues,
  setCountries,
  applyFilters,
  clearFilters,
  hasChildren,
  allCountries = [],
  setAllCountries,
  setCountriesArray,
  locale,
  currencyCode,
  maxInvestmentLimit,
  isDefaultRange,
  hasInactiveProposals = false,
  hasExpiredProposals = false,
  isFindInvestors = false,
  activeProposals,
  openModal,
  preselectedContinentId = null,
  investmentRange = null
}) => {
  // const ref = useRef();
  // useOnClickOutside(ref, () => hideDropdown());
  const locationsObject = {};
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(null);
  const [updatedLocationsArray, setUpdatedLocationsArray] = useState([]);
  const [countries, setUpdatedCountries] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const ref = useOnclickOutside(() => {
    onHideDropdown();
  });

  // get countries from continent
  const getCountriesByContinentId = async (id) => {
    const countriesResult = await fetchPlaces(
      `/api/locale/${locale}/content/locations.json`,
      {
        parentLocation: id,
        locationType: 'CN',
        includeIndirectChildren: true,
        includeProposalLocations: !isFindInvestors ? true : null
      }
    );
    const countriesArray = countriesResult.results.map((country) => {
      return {
        ...country,
        parentLocation: id
      };
    });
    locationsObject[id] = countriesArray;
    const countriesArrayUpdated = [];
    Object.entries(locationsObject).map((location) => {
      setUpdatedCountries([...countriesArrayUpdated, ...location[1]]);
      const updatedContinent = array.find((continent) => {
        return continent.locationId === parseInt(location[0], 10);
      });
      // eslint-disable-next-line prefer-destructuring
      updatedContinent.countries = location[1];
      // eslint-disable-next-line no-shadow
      return setUpdatedLocationsArray((updatedLocationsArray) => {
        return [...updatedLocationsArray, updatedContinent];
      });
    });
  };

  const getValue = (item) => {
    if (item.id) {
      return item.id;
    }
    if (item.locationId) {
      return item.locationId;
    }
    return item.code || '';
  };

  const updateTab = (e) => {
    if (e === currentTab) {
      return setCurrentTab(null);
    }
    return setCurrentTab(e);
  };

  useEffect(() => {
    updateDropdownPlacement(showDropdown);
  }, [showDropdown]);

  useEffect(() => {
    if (preselectedContinentId) {
      // eslint-disable-next-line no-undef
      const continentList = Array.from(document.querySelectorAll('.continent'));
      const matchingContinent = continentList?.find((continent) => {
        return continent?.classList?.contains(
          `group-${preselectedContinentId}`
        );
      });
      updateTab(continentList?.indexOf(matchingContinent));
    }
  }, [preselectedContinentId]);

  // eslint-disable-next-line no-shadow
  const onContinentChange = (e, category, name, index) => {
    if (category === 'continents') {
      const updatedContinent = updatedLocationsArray.find((continent) => {
        return continent.locationId === parseInt(e.target.value, 10);
      });
      setValues(e, category, name, index, updatedContinent?.countries);
    } else {
      setValues(e, category, name, index);
    }
    if (index === currentTab) {
      return;
    }
    updateTab(index);
  };

  const handleDropdownClick = () => {
    if (isFindInvestors && !activeProposals?.length) {
      if (hasExpiredProposals) {
        // if we have expired proposals and not active proposals
        openModal(null, t('investor.modal.clickBelowToRenew'), 'dropdown');
      } else if (hasInactiveProposals) {
        // if we have Inactive proposals
        openModal(null, t('investor.modal.clickBelowToUpgrade'), 'dropdown');
      } else {
        openModal(null, t('investor.modal.clickBelowToAdd'), 'dropdown');
      }
    } else {
      // eslint-disable-next-line no-unused-expressions
      showDropdown ? onHideDropdown() : onShowDropdown();
    }
  };

  useEffect(() => {
    if (countries.length) {
      setAllCountries([...allCountries, ...countries]);
      setCountriesArray(countries);
    }
  }, [countries]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (category === 'continents') {
      array.map((continent) => {
        return getCountriesByContinentId(continent.locationId);
      });
    }
    if (category === 'invRange' || (category !== 'invRange' && array?.length)) {
      return setDisabled(false);
    }
    if (
      category === 'multiRange' ||
      (category !== 'multiRange' && array?.length)
    ) {
      return setDisabled(false);
    }
    setDisabled(true);
  }, [array]);

  const renderChildItem = (childItem) => {
    const itemValue = getValue(childItem);
    const isSelected = itemValue
      ? selectedChildrenArray.includes(itemValue.toString())
      : false;
    return (
      <Checkbox
        className="mb-3"
        title={childItem.name}
        key={childItem.name}
        value={childItem.locationId}
        category="country"
        onChange={(e) => {
          return setCountries(e, 'country', childItem.name);
        }}
        isSelected={isSelected}
      />
    );
  };

  const renderRangeFilter = () => {
    return (
      <div
        ref={ref}
        className={` 
          absolute
          transform
          md:translate-x-0
          transition-all
          duration-300
          py-6
          px-5
          bg-white
          z-10
          shadow-2xl
          rounded-md
          border
          border-solid
          border-gray-200
          md:w-80
          w-60
          mt-4
          h-auto
          max-h-96
          overflow-auto
          react-dropdown-wrapper
          ${showDropdown ? 'shown' : 'hidden'}
        `}
      >
        <RangeSlider
          locale={locale}
          onChange={(event) => {
            return setValues(event);
          }}
          currencyCode={currencyCode}
          maxInvestmentLimit={maxInvestmentLimit}
          investmentRange={investmentRange}
        />
        <ApplyClearButton onApply={applyFilters} onClear={clearFilters} />
      </div>
    );
  };

  const renderMultiRangeFilter = () => {
    return (
      <div
        ref={ref}
        className={` 
          absolute
          transform
          md:translate-x-0
          transition-all
          duration-300
          py-6
          px-5
          bg-white
          z-10
          shadow-2xl
          rounded-md
          border
          border-solid
          border-gray-200
          md:w-80
          w-60
          mt-4
          h-auto
          max-h-96
          overflow-auto
          react-dropdown-wrapper
          ${showDropdown ? 'shown' : 'hidden'}
        `}
      >
        <MultiRangeSlider
          locale={locale}
          isDefaultRange={isDefaultRange}
          currencyCode={currencyCode}
          maxInvestmentLimit={maxInvestmentLimit}
          onChange={(values) => {
            return setValues(values);
          }}
          investmentRange={investmentRange}
        />
        <ApplyClearButton onApply={applyFilters} onClear={clearFilters} />
      </div>
    );
  };

  const renderItem = (item) => {
    const itemValue = getValue(item);
    const isSelected = itemValue
      ? selectedArray.includes(itemValue.toString())
      : false;
    return (
      <div
        key={item.name}
        className={
          category === 'continents' ? `group-${itemValue} continent` : ''
        }
      >
        <div className="flex w-full items-center justify-between mb-3">
          <Checkbox
            title={item.name}
            value={itemValue}
            category={category}
            onChange={(e) => {
              return onContinentChange(
                e,
                category,
                item.name,
                array.indexOf(item)
              );
            }}
            isSelected={isSelected}
          />
          {hasChildren && (
            <button
              type="button"
              onClick={() => {
                return updateTab(array.indexOf(item));
              }}
              className="material-icons text-base text-gray-800 transition-all"
            >
              {currentTab === array.indexOf(item) ? 'remove' : 'add'}
            </button>
          )}
        </div>
        {hasChildren && category === 'continents' && (
          <div
            className={`flex flex-col pb-4 justify-center px-3 transition-all ${
              currentTab === array.indexOf(item) ? '' : 'hidden'
            }`}
          >
            {item.countries &&
              item.countries.map((country) => {
                return renderChildItem(country);
              })}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`relative inline-block mr-3 mb-2 ${
        showDropdown ? 'open' : ''
      }`}
    >
      <button
        type="button"
        ref={ref}
        onClick={handleDropdownClick}
        disabled={isDisabled}
        className={`
          bg-transparent
          border
          border-solid
          border-gray-300
          text-gray-700
          px-5
          py-2
          text-sm
          rounded-md
          w-auto
          hover:bg-gray-300
          focus:bg-gray-200
          whitespace-nowrap
          ${isDisabled ? 'cursor-not-allowed bg-gray-200' : 'bg-transparent'}
          transition-all
        `}
      >
        {title}
      </button>
      {category === 'invRange' ? (
        renderRangeFilter()
      ) : category === 'multiRange' ? (
        renderMultiRangeFilter()
      ) : (
        <div
          ref={ref}
          className={` 
              absolute
              transform
              md:translate-x-0
              transition-all
              duration-300
              py-6
              px-5
              bg-white
              z-10
              shadow-2xl
              rounded-md
              border
              border-solid
              border-gray-200
              md:w-80
              w-60
              mt-4
              h-auto
              max-h-96
              overflow-auto
              react-dropdown-wrapper
              ${showDropdown ? 'shown' : 'hidden'}
            `}
        >
          {array.map((item) => {
            return renderItem(item);
          })}
          <ApplyClearButton onApply={applyFilters} onClear={clearFilters} />
        </div>
      )}
    </div>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  showDropdown: PropTypes.bool,
  category: PropTypes.string,
  onShowDropdown: PropTypes.func.isRequired,
  onHideDropdown: PropTypes.func.isRequired,
  array: PropTypes.instanceOf(Array),
  selectedArray: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.string
  ]),
  selectedChildrenArray: PropTypes.instanceOf(Array),
  setValues: PropTypes.func.isRequired,
  setCountries: PropTypes.func,
  applyFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  hasChildren: PropTypes.bool,
  allCountries: PropTypes.instanceOf(Array),
  setAllCountries: PropTypes.func,
  setCountriesArray: PropTypes.func,
  locale: PropTypes.string,
  currencyCode: PropTypes.string,
  maxInvestmentLimit: PropTypes.number,
  isDefaultRange: PropTypes.bool,
  hasInactiveProposals: PropTypes.bool,
  hasExpiredProposals: PropTypes.bool,
  isFindInvestors: PropTypes.bool,
  activeProposals: PropTypes.instanceOf(Array),
  openModal: PropTypes.func,
  preselectedContinentId: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.number,
    null
  ]),
  investmentRange: PropTypes.instanceOf(Object)
};

Dropdown.defaultProps = {
  array: [],
  showDropdown: false,
  category: '',
  selectedArray: [],
  selectedChildrenArray: [],
  setCountries: () => {},
  setAllCountries: () => {},
  setCountriesArray: () => {},
  hasChildren: false,
  allCountries: [],
  locale: '',
  currencyCode: '',
  maxInvestmentLimit: 0,
  isDefaultRange: false,
  hasInactiveProposals: false,
  hasExpiredProposals: false,
  isFindInvestors: false,
  activeProposals: [],
  openModal: () => {},
  preselectedContinentId: null,
  investmentRange: null
};
