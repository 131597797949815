import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import Markdown from 'markdown-to-jsx';
import { useTranslation } from 'react-i18next';
import { renderUserAvatar } from '../../Util/data';
import Tooltip from '../Tooltip/Tooltip';

const MessageList = ({
  image,
  lastUpdated,
  lastMessage,
  userName,
  proposalTitle,
  isSelected,
  isAlreadyRead,
  isRead,
  connectionId,
  isArchived,
  setAsArchived,
  restoreConnection,
  changeStatus,
  markAsReadUri,
  markAsNotReadUri,
  markAsArchivedUri,
  markAsNotArchivedUri,
  secondaryConnection,
  secondaryConnectionReadValue
}) => {
  const { t } = useTranslation();

  const [threadAlreadyRead, setThreadReadStatus] = useState(isAlreadyRead);
  // eslint-disable-next-line no-undef
  const currentLang = document.documentElement.lang;

  const updateReadStatus = (value, uri) => {
    changeStatus(value, uri);
  };
  const restore = () => {
    restoreConnection(markAsNotArchivedUri);
  };
  const archiveConnection = () => {
    setAsArchived(markAsArchivedUri);
  };

  useEffect(() => {
    if (isSelected) {
      // eslint-disable-next-line no-unused-expressions
      isRead ? setThreadReadStatus(true) : setThreadReadStatus(false);
    } else if (connectionId === secondaryConnection?.connectionId) {
      setThreadReadStatus(secondaryConnection?.read);
    }
  }, [isRead, connectionId, isSelected, secondaryConnection]);

  useEffect(() => {
    if (connectionId === secondaryConnection?.connectionId) {
      setThreadReadStatus(secondaryConnectionReadValue);
    }
  }, [secondaryConnectionReadValue]);

  return (
    <div
      className={`
        w-full
        flex
        items-start
        py-4
        px-3
        gap-5
        bg-transparent
        hover:bg-slate-200
        ${isSelected ? 'border-r-2 border-blue-500 bg-slate-200' : ''}
        ${threadAlreadyRead ? 'bg-slate-50' : 'bg-white'}
      `}
      role="button"
    >
      <div className="md:w-12 md:h-12 w-10 h-10">
        {image ? (
          <img
            src={image}
            alt={userName}
            className="w-full h-full object-cover object-center rounded-full"
          />
        ) : (
          renderUserAvatar(userName, null, `bg-1`)
        )}
      </div>
      <div className="md:flex md:visible hidden flex-col justify-start w-auto xl:flex-grow">
        <p
          className={`text-gray-800 text-base ${
            threadAlreadyRead || isSelected ? '' : 'font-semibold'
          }`}
        >
          {userName}
        </p>
        <p className="inline text-gray-700 text-sm">
          <span
            className={`${
              threadAlreadyRead || isSelected ? '' : 'font-semibold'
            }`}
          >
            {proposalTitle}
          </span>
        </p>
        {lastMessage && (
          <Markdown className="message-preview inline-flex items-center text-sm text-gray-700 gap-1">
            {lastMessage.length > 15
              ? `${lastMessage.replaceAll('<br />', '').slice(0, 15)} ...`
              : lastMessage}
          </Markdown>
        )}
      </div>
      <div className="md:inline-block md:visible hidden text-right w-1/3 group h-16">
        <span className="text-sm text-gray-700 group-hover:hidden">
          {lastUpdated && moment(lastUpdated).locale(currentLang).fromNow()}
        </span>
        <div className="relative group-hover:flex flex-col items-end hidden">
          <Tooltip
            text={
              threadAlreadyRead
                ? t('auth.inbox.markAsUnread')
                : t('auth.inbox.markAsRead')
            }
            classNames="right-4 bottom-0 mb-3 whitespace-nowrap"
          >
            <button
              type="button"
              onClick={() => {
                return threadAlreadyRead
                  ? updateReadStatus(false, markAsNotReadUri)
                  : updateReadStatus(true, markAsReadUri);
              }}
            >
              <span className="material-icons">
                {threadAlreadyRead ? 'email' : 'drafts'}
              </span>
            </button>
          </Tooltip>
          {!isArchived ? (
            <Tooltip
              text={t('auth.inbox.delete')}
              classNames="right-4 -mb-5 whitespace-nowrap bottom-0"
            >
              <button type="button" onClick={archiveConnection}>
                <span className="material-icons">delete</span>
              </button>
            </Tooltip>
          ) : (
            <Tooltip
              text={t('auth.inbox.restore')}
              classNames="right-4 -mb-5 whitespace-nowrap bottom-0"
            >
              <button type="button" onClick={restore}>
                <span className="material-icons">restore</span>
              </button>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageList;

MessageList.propTypes = {
  image: PropTypes.string,
  lastUpdated: PropTypes.string,
  lastMessage: PropTypes.string,
  userName: PropTypes.string,
  proposalTitle: PropTypes.string,
  isSelected: PropTypes.bool,
  isAlreadyRead: PropTypes.bool,
  isRead: PropTypes.bool,
  connectionId: PropTypes.number,
  isArchived: PropTypes.bool,
  setAsArchived: PropTypes.func,
  restoreConnection: PropTypes.func,
  changeStatus: PropTypes.func,
  markAsReadUri: PropTypes.string,
  markAsNotReadUri: PropTypes.string,
  markAsArchivedUri: PropTypes.string,
  markAsNotArchivedUri: PropTypes.string,
  secondaryConnection: PropTypes.instanceOf(Object),
  secondaryConnectionReadValue: PropTypes.bool
};

MessageList.defaultProps = {
  image: '',
  lastUpdated: '',
  lastMessage: '',
  userName: '',
  proposalTitle: '',
  isSelected: false,
  isAlreadyRead: false,
  isRead: false,
  connectionId: 0,
  isArchived: false,
  setAsArchived: () => {},
  restoreConnection: () => {},
  changeStatus: () => {},
  markAsReadUri: '',
  markAsNotReadUri: '',
  markAsArchivedUri: '',
  markAsNotArchivedUri: '',
  secondaryConnection: {},
  secondaryConnectionReadValue: false
};
