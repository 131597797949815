/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Hero from '../Hero/Hero';
import Loader from '../Loader/Loader';
import { submitCancellationSurvey } from '../../Util/ApiService';
import { displayToast } from '../../Util/Toast';
import GeneralModal from '../Modal/GeneralModal';

const CancellationSurvey = ({
  cancellationReasons,
  endpointUri,
  followUpOptionTypes,
  options,
  subscriptionCancellationSurveyFollowUpOptionCodes
}) => {
  const prepareAvailableOptions = () => {
    const initialOptions = [];
    JSON.parse(options)
      .sort((a, b) => {
        return a.displayOrder - b.displayOrder;
      })
      .forEach((option) => {
        const followupOptions = [];
        option.subscriptionCancellationSurveyFollowUpOptions?.forEach(
          (followupOption) => {
            followupOptions.push({
              ...followupOption,
              currentValue: null
            });
          }
        );
        followupOptions.sort((a, b) => {
          return a.displayOrder - b.displayOrder;
        });
        initialOptions.push({
          ...option,
          subscriptionCancellationSurveyFollowUpOptions: followupOptions,
          selected: false
        });
      });
    return initialOptions;
  };
  const [availableOptions, setAvailableOptions] = useState(
    prepareAvailableOptions
  );
  const selectedOption = availableOptions.find((option) => {
    return option.selected;
  });
  const [feedback, setFeedback] = useState('');
  const [hasTestimonial, setHasTestimonial] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalDescription, setModalDescription] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [isSentSuccessfully, setIsSentSuccessfully] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);
  const { t } = useTranslation();

  const prepareAnswerPayload = () => {
    if (selectedOption?.testimonialContent?.length) {
      setHasTestimonial(true);
    }

    return JSON.stringify({
      subscriptionCancellationSurveyOption:
        selectedOption.subscriptionCancellationSurveyOptionCode,
      feedback,
      subscriptionCancellationSurveyFollowUpOptions:
        selectedOption.subscriptionCancellationSurveyFollowUpOptions.map(
          (followUpOption) => {
            return {
              subscriptionCancellationSurveyFollowUpOption:
                followUpOption.subscriptionCancellationSurveyFollowUpOptionCode,
              value:
                selectedOption.testimonialContent &&
                followUpOption.subscriptionCancellationSurveyFollowUpOptionCode ===
                  subscriptionCancellationSurveyFollowUpOptionCodes.testimonial
                  ? selectedOption.testimonialContent
                  : followUpOption.currentValue?.toString()
            };
          }
        )
    });
  };

  const onOptionUpdate = (chosenOption) => {
    setAvailableOptions(
      availableOptions.map((currentOption) => {
        return chosenOption.subscriptionCancellationSurveyOptionCode ===
          currentOption.subscriptionCancellationSurveyOptionCode
          ? chosenOption
          : { ...currentOption, selected: false };
      })
    );
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setValidationMessage(null);
    setIsLoading(true);
    const currentlySelectedOption = availableOptions.find((option) => {
      return option.selected;
    });
    if (!currentlySelectedOption) {
      setValidationMessage(t('cancellationSurvey.validation.notSelected'));
      setIsLoading(false);
      return;
    }

    submitCancellationSurvey(
      endpointUri,
      prepareAnswerPayload(),
      () => {
        setIsSentSuccessfully(true);
        setModalOpen(true);
      },
      (error) => {
        displayToast(t('cancellationSurvey.submit.error'), 'error');
        // eslint-disable-next-line no-console
        console.error('Failed to submit the survey', error);
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (hasTestimonial) {
      setModalTitle(t('testimonialsPage.thanks'));
      setModalDescription(t('cancellationSurvey.testimonialModal.description'));
    } else {
      setModalTitle(t('cancellationSurvey.submit.success'));
      setModalDescription(
        `<p>${t('cancellationSurvey.feedbackModal.description').replaceAll(
          '%break%',
          '<br>'
        )}</p>`
      );
    }
  }, [hasTestimonial]);

  return (
    <div className="cancellation-survey">
      <Hero text={t('cancellationSurvey.title')} />
      <div className="bg-gray-50 text-xl text-gray-700">
        <div className="max-w-screen-md md:px-5 px-3 py-12 mx-auto flex flex-col gap-12">
          <h3>{t('cancellationSurvey.subTitle')}</h3>
          <form onSubmit={onSubmit}>
            <div className="w-full flex flex-col gap-7">
              <div className=" flex flex-col gap-2">
                {availableOptions.map((option) => {
                  return (
                    <CancellationSurveyOption
                      key={option.subscriptionCancellationSurveyOptionCode}
                      option={option}
                      onOptionUpdate={onOptionUpdate}
                    />
                  );
                })}
              </div>
              <CancellationSurveyFollowupOptions
                onOptionUpdate={onOptionUpdate}
                selectedOption={selectedOption}
                followUpOptionTypes={followUpOptionTypes}
                cancellationReasons={cancellationReasons}
                subscriptionCancellationSurveyFollowUpOptionCodes={
                  subscriptionCancellationSurveyFollowUpOptionCodes
                }
              />
              {selectedOption?.subscriptionCancellationSurveyOptionCode !==
                cancellationReasons?.raisedFunding && (
                <div className="text-lg">
                  <label
                    htmlFor="feedback"
                    className="font-semibold cursor-pointer"
                  >
                    {t('cancellationSurvey.feedback')}
                  </label>
                  <textarea
                    name="feedback"
                    className="py-2 px-3 border border-gray-300 rounded-md w-full overflow-hidden focus:outline-blue600 focus:border-blue-600 textarea-adjust"
                    value={feedback ?? ''}
                    onBlur={(e) => {
                      setFeedback(e.target.value);
                    }}
                    onChange={(e) => {
                      setFeedback(e.target.value);
                    }}
                  />
                </div>
              )}
              {validationMessage && (
                <div className="p-3 w-full bg-red-200 text-red-700 validation-error">
                  {validationMessage}
                </div>
              )}
              <button
                type="submit"
                className="rounded-md focus:outline-none bg-blue-600 hover:bg-blue-500 py-2 px-4 text-white text-lg font-semibold disabled:opacity-50 max-w-max"
                disabled={isLoading || isSentSuccessfully}
              >
                {isLoading ? (
                  <Loader title={t('cancellationSurvey.submit.inProgress')} />
                ) : (
                  t('cancellationSurvey.submit.enabled')
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      {modalOpen && (
        <GeneralModal
          title={modalTitle}
          description={modalDescription}
          innerCloseIcon
          closeModal={() => {
            setModalOpen(false);
            setHasTestimonial(false);
          }}
        >
          <a
            href="/"
            className="bg-blue-600 hover:bg-bue-500 text-white text-lg leading-6 font-semibold py-2 px-4 rounded-md max-w-max"
          >
            {t('cancellationSurvey.feedbackModal.returnToHome')}
          </a>
        </GeneralModal>
      )}
    </div>
  );
};

const CancellationSurveyOption = ({ option, onOptionUpdate }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center gap-2">
      <input
        type="radio"
        className="custom-radio flex-shrink-0 appearance-none cursor-pointer w-5 h-5 rounded-full border border-gray-400 bg-white checked:bg-blue-600 checked:border-transparent focus:outline-none"
        key={option.subscriptionCancellationSurveyOptionCode}
        id={option.subscriptionCancellationSurveyOptionCode}
        value={option.subscriptionCancellationSurveyOptionCode}
        name="cancellation_option"
        onChange={() => {
          return onOptionUpdate({
            ...option,
            selected: true
          });
        }}
      />
      <label
        htmlFor={option.subscriptionCancellationSurveyOptionCode}
        className="cursor-pointer"
      >
        {t(
          `cancellationSurvey.option.${option.subscriptionCancellationSurveyOptionCode}.description`
        )}
      </label>
    </div>
  );
};

const CancellationSurveyFollowupOptions = ({
  selectedOption,
  onOptionUpdate,
  followUpOptionTypes,
  cancellationReasons,
  subscriptionCancellationSurveyFollowUpOptionCodes
}) => {
  const { t } = useTranslation();
  const [displayTestimonialTextArea, setDisplayTestimonialTextArea] =
    useState(false);
  const [testimonialContent, setTestimonialContent] = useState(null);

  const onFollowUpOptionUpdate = (chosenFollowUpOption) => {
    if (
      chosenFollowUpOption.subscriptionCancellationSurveyFollowUpOptionCode ===
      subscriptionCancellationSurveyFollowUpOptionCodes.testimonial
    ) {
      if (!chosenFollowUpOption.currentValue) setTestimonialContent(null);
      setDisplayTestimonialTextArea(chosenFollowUpOption.currentValue);
    }
    const updatedFollowUpOptions =
      selectedOption.subscriptionCancellationSurveyFollowUpOptions.map(
        (currentFollowUpOption) => {
          return chosenFollowUpOption.subscriptionCancellationSurveyFollowUpOptionCode ===
            currentFollowUpOption.subscriptionCancellationSurveyFollowUpOptionCode
            ? chosenFollowUpOption
            : currentFollowUpOption;
        }
      );

    if (
      chosenFollowUpOption.subscriptionCancellationSurveyOptionCode ===
      cancellationReasons.raisedFunding
    ) {
      setTestimonialContent(chosenFollowUpOption.currentValue);
    }

    onOptionUpdate({
      ...selectedOption,
      subscriptionCancellationSurveyFollowUpOptions: updatedFollowUpOptions,
      testimonialContent
    });
  };
  const shouldNotDisplay =
    !selectedOption?.selected ||
    selectedOption.subscriptionCancellationSurveyFollowUpOptions?.length < 1;

  return (
    <>
      {!shouldNotDisplay &&
        selectedOption.subscriptionCancellationSurveyFollowUpOptions.map(
          (option) => {
            const fieldIdentifier = `${option.subscriptionCancellationSurveyFollowUpOptionCode}-description`;
            return (
              <div
                className="flex flex-col items-start gap-2 text-lg"
                key={option.subscriptionCancellationSurveyFollowUpOptionCode}
              >
                <label
                  htmlFor={fieldIdentifier}
                  className="font-semibold cursor-pointer"
                >
                  {t(
                    `cancellationSurvey.option.${selectedOption.subscriptionCancellationSurveyOptionCode}.followUpOption.${option.subscriptionCancellationSurveyFollowUpOptionCode}.description`
                  )}
                </label>
                {option?.subscriptionCancellationSurveyFollowUpOptionType
                  ?.code === followUpOptionTypes?.yesNoQuestion ||
                option?.subscriptionCancellationSurveyFollowUpOptionType
                  ?.code ===
                  followUpOptionTypes?.yesNoQuestionWithLongTextForYes ? (
                  <div className="flex items-center gap-3">
                    <label
                      htmlFor={`${fieldIdentifier}-true`}
                      className="flex items-center gap-2 cursor-pointer"
                    >
                      <input
                        type="radio"
                        className="custom-radio flex-shrink-0 appearance-none cursor-pointer w-5 h-5 rounded-full border border-gray-400 bg-white checked:bg-blue-600 checked:border-transparent focus:outline-none"
                        name={fieldIdentifier}
                        id={`${fieldIdentifier}-true`}
                        checked={option.currentValue === true}
                        onChange={() => {
                          return onFollowUpOptionUpdate({
                            ...option,
                            currentValue: true
                          });
                        }}
                      />
                      <span>{t('cancellationSurvey.option.boolean.yes')}</span>
                    </label>
                    <label
                      htmlFor={`${fieldIdentifier}-false`}
                      className="flex items-center gap-2 cursor-pointer"
                    >
                      <input
                        type="radio"
                        className="custom-radio flex-shrink-0 appearance-none cursor-pointer w-5 h-5 rounded-full border border-gray-400 bg-white checked:bg-blue-600 checked:border-transparent focus:outline-none"
                        name={fieldIdentifier}
                        id={`${fieldIdentifier}-false`}
                        checked={option.currentValue === false}
                        onChange={() => {
                          return onFollowUpOptionUpdate({
                            ...option,
                            currentValue: false
                          });
                        }}
                      />
                      <span>{t('cancellationSurvey.option.boolean.no')}</span>
                    </label>
                  </div>
                ) : (
                  <textarea
                    name={fieldIdentifier}
                    className="py-2 px-3 border border-gray-300 rounded-md w-full overflow-hidden focus:outline-blue600 focus:border-blue-600 textarea-adjust"
                    onBlur={(e) => {
                      return onFollowUpOptionUpdate({
                        ...option,
                        currentValue: e.target.value
                      });
                    }}
                    onChange={(e) => {
                      return onFollowUpOptionUpdate({
                        ...option,
                        currentValue: e.target.value
                      });
                    }}
                    value={option.currentValue ?? ''}
                  />
                )}
              </div>
            );
          }
        )}
      {displayTestimonialTextArea &&
        selectedOption?.subscriptionCancellationSurveyOptionCode ===
          cancellationReasons?.raisedFunding && (
          <label
            htmlFor="testimonial"
            className="w-full flex flex-col gap-2 font-semibold cursor-pointer"
          >
            {t('cancellationSurvey.testimonial')}
            <textarea
              id="testimonial"
              name={
                subscriptionCancellationSurveyFollowUpOptionCodes.testimonial
              }
              required
              className="py-2 px-3 border border-gray-300 rounded-md w-full overflow-hidden focus:outline-blue600 focus:border-blue-600 textarea-adjust"
              onBlur={(e) => {
                return onFollowUpOptionUpdate({
                  ...selectedOption,
                  currentValue: e.target.value
                });
              }}
              onChange={(e) => {
                return onFollowUpOptionUpdate({
                  ...selectedOption,
                  currentValue: e.target.value
                });
              }}
            />
          </label>
        )}
    </>
  );
};

export default CancellationSurvey;

CancellationSurvey.propTypes = {
  cancellationReasons: PropTypes.instanceOf(Object).isRequired,
  endpointUri: PropTypes.string.isRequired,
  followUpOptionTypes: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.string.isRequired,
  subscriptionCancellationSurveyFollowUpOptionCodes:
    PropTypes.instanceOf(Object).isRequired
};

CancellationSurveyFollowupOptions.propTypes = {
  selectedOption: PropTypes.shape({
    displayOrder: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    subscriptionCancellationSurveyFollowUpOptions: PropTypes.arrayOf(
      PropTypes.shape({
        boolean: PropTypes.bool.isRequired,
        currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        subscriptionCancellationSurveyFollowUpOptionCode:
          PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    subscriptionCancellationSurveyOptionCode: PropTypes.string.isRequired
  }),
  onOptionUpdate: PropTypes.func.isRequired,
  followUpOptionTypes: PropTypes.instanceOf(Object).isRequired,
  cancellationReasons: PropTypes.instanceOf(Object).isRequired,
  subscriptionCancellationSurveyFollowUpOptionCodes:
    PropTypes.instanceOf(Object).isRequired
};

CancellationSurveyFollowupOptions.defaultProps = {
  selectedOption: null
};

CancellationSurveyOption.propTypes = {
  option: PropTypes.shape({
    displayOrder: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    subscriptionCancellationSurveyFollowUpOptions: PropTypes.arrayOf(
      PropTypes.shape({
        boolean: PropTypes.bool.isRequired,
        currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        subscriptionCancellationSurveyFollowUpOptionCode:
          PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    subscriptionCancellationSurveyOptionCode: PropTypes.string.isRequired
  }).isRequired,
  onOptionUpdate: PropTypes.func.isRequired
};
