import React from 'react';

const Skeleton = () => {
  return (
    <div className="proposal max-w-sm shadow-md rounded-2xl text-sm flex flex-col bg-white">
      <div className="w-full py-8">
        <div className="w-full h-24 bg-cover flex items-center relative overflow-hidden rounded-t-2xl">
          <div className="w-24 h-full mx-6 rounded animate-pulse bg-gray-200" />
        </div>
      </div>
      <div className="bg-gray-200 w-full h-2 mt-1" />

      <div className="p-6 flex flex-col h-full">
        <div className="mb-0 h-4 animate-pulse bg-gray-200" />
        <p className="h-4 animate-pulse bg-gray-200 text-sm flex items-center" />
        <div className="py-3 text-base h-4 mt-4 animate-pulse bg-gray-200" />
        <div className="py-3 text-base h-4 animate-pulse bg-gray-200" />
        <div className="py-3 text-base h-4 animate-pulse bg-gray-200" />

        <div className="flex flex-row mt-3">
          <div className="w-1/2 h-8">
            <div className="mb-1 animate-pulse bg-gray-200 h-4" />
            <span className="animate-pulse bg-gray-200 h-3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
