import React from 'react';
import { useTranslation } from 'react-i18next';

const InvestorDeactivatedPrompt = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full mb-5">
      <div className="flex flex-col items-start">
        <div className="flex items-start justify-start gap-3 md:max-w-2xl">
          <div className="md:w-12 md:h-12 md:visible md:block hidden">
            <img
              src="/images/small/logo/ain_new-logo.png"
              className="w-12 h-12 rounded-full"
              alt="AIN"
            />
          </div>
          <div className="w-auto">
            <div className="rounded-xl p-5 bg-slate-100 text-gray-700 text-base font-normal">
              {t('auth.inbox.interestedUserDisabled')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InvestorDeactivatedPrompt;
