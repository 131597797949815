/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import MessageList from './MessageList';

const Sidebar = ({
  conversations,
  updateSelectedConversation,
  loading,
  activeId,
  isRead,
  totalMessages,
  fetchMoreMessages,
  hasMoreData,
  filterMessage,
  filterMessageByQuery,
  noResults,
  updateReadStatus,
  markAsArchived,
  markAsUnarchived,
  secondaryConnection,
  secondaryConnectionReadValue
}) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState('');
  const searchSection = useRef();
  const [searchSectionHeight, setSearchSectionHeight] = useState(null);

  const options = ['all', 'deleted', 'read', 'unread'];

  const handleFormSubmit = (event) => {
    event.preventDefault();
    filterMessageByQuery(query);
  };

  const updateSelection = (
    event,
    messagesApiUri,
    firstName,
    lastName,
    proposalTitle,
    logoUri,
    connectionId
  ) => {
    if (
      event.target.classList.contains('material-icons') ||
      event.target.classList.contains('tooltip') ||
      event.target.tagName === 'BUTTON'
    ) {
      return;
    }
    updateSelectedConversation(
      messagesApiUri,
      firstName,
      lastName,
      proposalTitle,
      logoUri,
      connectionId
    );
  };

  const handleKeyUp = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const sectionHeight = searchSection.current.offsetHeight;
    setSearchSectionHeight(sectionHeight);
  }, []);

  return (
    <div
      className="lg:w-1/4 md:w-1/3 md:block w-1/6 h-full border-r border-slate-100 transition-all lg:min-w-0 min-w-max"
      id="sidebar"
    >
      <div
        ref={searchSection}
        className="w-full md:p-5 flex items-center gap-3 border-b border-slate-100"
      >
        <span className="material-icons md:inline-block md:visible hidden">
          search
        </span>
        <form
          onSubmit={handleFormSubmit}
          className="md:visible md:block hidden"
        >
          <input
            type="text"
            className="
              text-left
              px-3
              py-2
              border
              w-auto
              grow
              border-gray-300
              placeholder-gray-300
              rounded
              focus:outline-none
              focus:border-blue-500
              md:block
              md:visible
              hidden
            "
            placeholder={t('auth.inbox.searchInbox')}
            onChange={(e) => {
              return setQuery(e.target.value);
            }}
          />
        </form>
        <div className="relative group md:p-0 p-4" role="button">
          <span className="material-icons">tune</span>
          <div className="absolute z-10 bg-white border border-slate-100 hidden visible group-hover:block shadow">
            <ul className="whitespace-nowrap w-52 max-w-sm">
              {options.map((option, index) => {
                return (
                  <li
                    role="button"
                    key={index}
                    className="text-sm px-4 py-2 hover:bg-slate-100"
                    onClick={() => {
                      return filterMessage(option);
                    }}
                    onKeyUp={handleKeyUp}
                  >
                    {t(`auth.inbox.${option}`)}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div
        id="scrollTarget"
        className="w-full flex flex-col items-center overflow-y-auto max-h-full"
        style={{ height: `calc(100% - ${searchSectionHeight}px)` }}
      >
        <ul className="w-full divide-y divide-slate-100 pb-28 md:h-auto h-screen">
          {loading ? (
            Array.from(Array(2), (_, i) => {
              return (
                <li key={i}>
                  <div className="flex gap-3 items-start p-4">
                    <div className="rounded-full bg-gray-200 w-12 h-10 animate-pulse flex-grow" />
                    <div className="rounded-md bg-gray-200 w-full h-24 animate-pulse md:block md:visible hidden" />
                  </div>
                </li>
              );
            })
          ) : noResults ? (
            <div className="my-10 text-center px-3 break-words">
              {t('auth.inbox.noMessages')}
            </div>
          ) : (
            <InfiniteScroll
              dataLength={totalMessages}
              next={() => {
                return fetchMoreMessages();
              }}
              hasMore={hasMoreData}
              scrollableTarget="scrollTarget"
              loader={
                <div className="text-center">
                  <svg
                    className="animate-spin inline h-3.5 w-3.5 fill-current text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <title>Loading...</title>
                    <path
                      className="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                </div>
              }
            >
              {conversations.map((conversation) => {
                return conversation.lastMessage ? (
                  <li
                    key={conversation.connectionId}
                    role="button"
                    onClick={(event) => {
                      return updateSelection(
                        event,
                        conversation?.messagesApiUri,
                        `${conversation?.interlocutor?.name?.firstName} ${conversation?.interlocutor?.name?.lastName}`,
                        conversation?.proposal?.title,
                        conversation?.interlocutor?.logoUri,
                        conversation?.connectionId
                      );
                    }}
                    id={conversation.connectionId}
                    onKeyUp={handleKeyUp}
                  >
                    <MessageList
                      image={conversation.interlocutor.logoUri}
                      lastUpdated={conversation.lastMessage.sentDate}
                      lastMessage={conversation.lastMessage.content}
                      userName={`${conversation.interlocutor.name.firstName} ${conversation.interlocutor.name.lastName}`}
                      proposalTitle={conversation.proposal.title}
                      isSelected={activeId === conversation.connectionId}
                      isRead={isRead}
                      isAlreadyRead={conversation.read}
                      connectionId={conversation.connectionId}
                      changeStatus={updateReadStatus}
                      setAsArchived={markAsArchived}
                      isArchived={conversation.archived}
                      restoreConnection={markAsUnarchived}
                      markAsReadUri={
                        conversation?.connectionUserActions?.markAsReadUri
                      }
                      markAsNotReadUri={
                        conversation?.connectionUserActions?.markAsNotReadUri
                      }
                      markAsArchivedUri={
                        conversation?.connectionUserActions?.markAsArchivedUri
                      }
                      markAsNotArchivedUri={
                        conversation?.connectionUserActions
                          ?.markAsNotArchivedUri
                      }
                      secondaryConnection={secondaryConnection}
                      secondaryConnectionReadValue={
                        secondaryConnectionReadValue
                      }
                    />
                  </li>
                ) : (
                  <li
                    key={conversation.connectionId}
                    role="button"
                    onClick={(event) => {
                      return updateSelection(
                        event,
                        conversation?.messagesApiUri,
                        `${conversation?.interlocutor?.name?.firstName} ${conversation?.interlocutor?.name?.lastName}`,
                        conversation?.proposal?.title,
                        conversation?.interlocutor?.logoUri,
                        conversation?.connectionId
                      );
                    }}
                    id={conversation.connectionId}
                    onKeyUp={handleKeyUp}
                  >
                    <MessageList
                      image={conversation.interlocutor.logoUri}
                      userName={`${conversation.interlocutor.name.firstName} ${conversation.interlocutor.name.lastName}`}
                      proposalTitle={conversation.proposal.title}
                      isSelected={activeId === conversation.connectionId}
                      isRead={isRead}
                      isAlreadyRead={conversation.read}
                      connectionId={conversation.connectionId}
                      changeStatus={updateReadStatus}
                      setAsArchived={markAsArchived}
                      isArchived={conversation.archived}
                      restoreConnection={markAsUnarchived}
                      markAsReadUri={
                        conversation?.connectionUserActions?.markAsReadUri
                      }
                      markAsNotReadUri={
                        conversation?.connectionUserActions?.markAsNotReadUri
                      }
                      markAsArchivedUri={
                        conversation?.connectionUserActions?.markAsArchivedUri
                      }
                      markAsNotArchivedUri={
                        conversation?.connectionUserActions
                          ?.markAsNotArchivedUri
                      }
                      secondaryConnection={secondaryConnection}
                      secondaryConnectionReadValue={
                        secondaryConnectionReadValue
                      }
                    />
                  </li>
                );
              })}
            </InfiniteScroll>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  conversations: PropTypes.instanceOf(Array),
  updateSelectedConversation: PropTypes.func,
  loading: PropTypes.bool,
  activeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRead: PropTypes.bool,
  totalMessages: PropTypes.number,
  fetchMoreMessages: PropTypes.func,
  hasMoreData: PropTypes.bool,
  filterMessage: PropTypes.func,
  filterMessageByQuery: PropTypes.func,
  noResults: PropTypes.bool,
  updateReadStatus: PropTypes.func,
  markAsArchived: PropTypes.func,
  markAsUnarchived: PropTypes.func,
  secondaryConnection: PropTypes.instanceOf(Object),
  secondaryConnectionReadValue: PropTypes.bool
};

Sidebar.defaultProps = {
  conversations: [],
  updateSelectedConversation: () => {},
  loading: false,
  activeId: '',
  isRead: false,
  totalMessages: 0,
  fetchMoreMessages: () => {},
  hasMoreData: false,
  filterMessage: () => {},
  filterMessageByQuery: () => {},
  noResults: false,
  updateReadStatus: () => {},
  markAsArchived: () => {},
  markAsUnarchived: () => {},
  secondaryConnection: {},
  secondaryConnectionReadValue: false
};
