import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnclickOutside from 'react-cool-onclickoutside';

const NewsfeedPopup = ({ popupType, hidePopup, url }) => {
  const { t } = useTranslation();

  const popupContent = useOnclickOutside(() => {
    hidePopup();
  });

  return (
    <div
      tabIndex="-1"
      className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full md:h-screen justify-center items-center flex bg-black bg-opacity-90"
      role="dialog"
    >
      <div
        ref={popupContent}
        className="
          bg-white
          rounded-2xl
          py-6
          px-6
          md:w-1/2
          xl:w-1/3
          w-11/12
          max-w-full
          h-auto
          m-auto
          md:mt-12
          mt-14
          flex
          flex-col
          gap-5
        "
      >
        <div className="flex items-center justify-between">
          <h2 className="text-2xl text-gray-800 w-full text-center mb-0 font-semibold">
            {t(`newsfeed.popup.${popupType}.title`)}
          </h2>
          <button
            type="button"
            onClick={hidePopup}
            className="inline-flex items-center justify-center w-6 h-6"
          >
            <span className="material-icons text-gray-600">close</span>
          </button>
        </div>
        <div className="flex flex-col flex-grow gap-5 lg:w-auto w-full">
          <p className="text-lg text-gray-700">
            {t(`newsfeed.popup.${popupType}.content`)}
          </p>
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="
              flex
              justify-center
              items-center
              py-2
              font-semibold
              text-base
              rounded-md
              bg-blue-600
              text-white
              cursor-pointer
            "
          >
            {t(`newsfeed.popup.${popupType}.button`)}
          </a>
        </div>
      </div>
    </div>
  );
};

export default NewsfeedPopup;

NewsfeedPopup.propTypes = {
  popupType: PropTypes.string,
  hidePopup: PropTypes.func,
  url: PropTypes.string
};

NewsfeedPopup.defaultProps = {
  popupType: '',
  hidePopup: () => {},
  url: ''
};
