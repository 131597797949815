import React from 'react';
import * as PropTypes from 'prop-types';

const AddProposalCard = ({ buttonTitle, onClick }) => {
  return (
    <div className="md:max-w-xl flex-grow rounded-lg border-2 border-dashed border-blue-200 bg-blue-50 py-32 flex items-center justify-center">
      <div className="px-16 text-center">
        <div className="flex justify-center">
          <button
            type="button"
            onClick={onClick}
            className="
              py-3
              px-6
              gap-2
              bg-blue-600
              font-semibold
              rounded-full
              text-white
              text-lg
              flex
              justify-center
              items-center
            "
          >
            <span className="material-icons -mt-1">add</span>
            {buttonTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddProposalCard;

AddProposalCard.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};
