/* eslint-disable indent */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';
import {
  removeEntryFromArray,
  renderUserAvatar,
  checkProtocol
} from '../../Util/data';
import { removeNewsfeedImage } from '../../Util/ApiService';
import { displayToast } from '../../Util/Toast';
import ModalButtons from './ModalButtons';
import NewsfeedProposalCard from './ProposalCard';
import LightBox from '../Lightbox/LightBox';
import ImageForm from './ImageForm';
import UrlForm from './UrlForm';
import NewsfeedButton from './Button';

const NewsfeedModal = ({
  loggedInUserLogo,
  loggedInUserName,
  title,
  hideModal,
  modalType,
  openImageModal,
  openUrlModal,
  submitPost,
  proposals,
  isPosting,
  isFromAdminPage = false,
  sites,
  languages,
  updateNewsfeedParameters,
  presetFeed,
  deleteImageUri,
  updatePreSetImageArray,
  pitchUpdateCount,
  pitchUpdatePurchaseUri
}) => {
  const { t } = useTranslation();
  const regexForTime = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/;

  const [selectedImages, setSelectedImages] = useState([]);
  const [imagesForLightBox, setSelectedImagesForLb] = useState([]);
  const [index, setSlideIndex] = useState(0);
  const [updateMessage, setUpdateMessage] = useState(null);
  const [url, setUrl] = useState(null);
  const [proposalId, setProposalId] = useState('');
  const [selectedProposal, setSelectedProposal] = useState(null);

  const [newsfeedLanguage, setNewsfeedLanguage] = useState('');
  const [newsfeedSite, setNewsfeedSite] = useState('');
  const [publishDate, setPublishDate] = useState(
    regexForTime.exec(new Date().toISOString())[0]
  );
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [removingImage, setRemovingImage] = useState(false);

  const modalContent = useOnclickOutside(() => {
    if (!isFromAdminPage) {
      hideModal(
        selectedImages,
        imagesForLightBox,
        url,
        proposalId,
        updateMessage
      );
    }
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    return submitPost(
      selectedImages,
      imagesForLightBox,
      url,
      proposalId,
      updateMessage
    );
  };

  const handleCurrentIndexUpdate = (idx) => {
    setCurrentSlideIndex(idx?.index);
  };

  const removeImages = async (event) => {
    event.preventDefault();
    if (presetFeed && isFromAdminPage) {
      setRemovingImage(true);
      const newArray = removeEntryFromArray(
        imagesForLightBox,
        currentSlideIndex
      );

      const formData = new FormData();
      formData.append(
        'images[]',
        new Blob([JSON.stringify(presetFeed?.images[currentSlideIndex])]),
        presetFeed?.images[currentSlideIndex]?.alt
      );

      const apiUrl = deleteImageUri?.replace(
        '0',
        presetFeed?.images[currentSlideIndex]?.imageMetadataUuid
      );

      await removeNewsfeedImage(apiUrl, formData).then(() => {
        setSelectedImagesForLb(newArray);
        updatePreSetImageArray(newArray);
        setSlideIndex(0);
        setRemovingImage(false);
      });

      return;
    }
    setSelectedImages([]);
    setSelectedImagesForLb([]);
    setSlideIndex(0);
  };

  const closeImageModal = (e) => {
    e.preventDefault();
    if (setSelectedImagesForLb?.length && !isFromAdminPage) {
      setSelectedImages([]);
      setSelectedImagesForLb([]);
    }
    hideModal(
      selectedImages,
      imagesForLightBox,
      url,
      proposalId,
      updateMessage
    );
  };

  const closeUrlModal = (e) => {
    e.preventDefault();
    hideModal();
  };

  const updateImages = (images) => {
    if (isFromAdminPage) {
      return setSelectedImagesForLb([...imagesForLightBox, ...images]);
    }
    return setSelectedImagesForLb(images);
  };

  const renderImageForm = () => {
    return (
      <ImageForm
        selectedImages={selectedImages}
        lightboxImages={imagesForLightBox}
        imgIndex={index}
        clickedBack={closeImageModal}
        modalConfirmed={() => {
          return hideModal(
            selectedImages,
            imagesForLightBox,
            url,
            proposalId,
            updateMessage
          );
        }}
        addImagesToLb={updateImages}
        updateSelectedImages={(e) => {
          return setSelectedImages(e);
        }}
      />
    );
  };

  const renderUrlForm = () => {
    return (
      <UrlForm
        url={url}
        updateUrl={(e) => {
          return setUrl(e);
        }}
        clickedBack={closeUrlModal}
        modalConfirmed={() => {
          return hideModal(
            selectedImages,
            imagesForLightBox,
            url,
            proposalId,
            updateMessage
          );
        }}
      />
    );
  };

  // eslint-disable-next-line consistent-return
  const updateNewsfeedLang = (lang) => {
    setNewsfeedLanguage(lang);
    if (newsfeedSite && newsfeedSite !== '') {
      const selectedSite = sites?.find((site) => {
        return site?.siteCode === newsfeedSite;
      });
      if (selectedSite?.language?.code !== lang) {
        displayToast(
          "Selected site and language don't match. Note: Site is optional.",
          'warning'
        );
        return setNewsfeedSite('');
      }
    }
  };

  const updateTime = (time) => {
    setPublishDate(time);
  };

  useEffect(() => {
    if (newsfeedSite) {
      const selectedSite = sites?.find((site) => {
        return site?.siteCode === newsfeedSite;
      });
      setNewsfeedLanguage(selectedSite?.language?.code);
    }
  }, [newsfeedSite]);

  useEffect(() => {
    if (isFromAdminPage)
      updateNewsfeedParameters(newsfeedLanguage, newsfeedSite, publishDate);
  }, [newsfeedLanguage, newsfeedSite, publishDate]);

  useEffect(() => {
    if ((proposalId || proposalId?.length) && proposals?.length) {
      const changedProposal = proposals?.find((proposal) => {
        return proposal?.proposalId?.toString() === proposalId?.toString();
      });
      setSelectedProposal(changedProposal);
    }
  }, [proposalId]);

  useEffect(() => {
    if (proposals?.length) {
      setProposalId(proposals[0]?.proposalId);
    }
  }, [proposals]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        hideModal();
      }
    });

    if (presetFeed) {
      setUpdateMessage(presetFeed?.content);
      setSelectedImagesForLb(presetFeed?.images);
      setUrl(presetFeed?.externalLink ? presetFeed?.externalLink?.url : null);
      setNewsfeedLanguage(
        presetFeed?.language?.code ? presetFeed?.language?.code : ''
      );
      setNewsfeedSite(presetFeed?.site ? presetFeed?.site?.code : '');
      setPublishDate(presetFeed?.createdDate?.slice(0, -9));
      setProposalId(
        presetFeed?.proposal ? presetFeed?.proposal?.proposalId : ''
      );
    }
  }, []);

  return (
    <div
      tabIndex="-1"
      className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full md:h-screen justify-center items-center flex bg-black bg-opacity-90"
      role="dialog"
    >
      <div
        ref={modalContent}
        className="
          bg-white
          rounded-2xl
          py-6
          md:w-1/2
          xl:w-2/5
          w-11/12
          max-w-full
          h-auto
          m-auto
          md:mt-12
          mt-14
          flex
          flex-col
          gap-4
        "
      >
        <div className="flex items-center justify-between px-6">
          <h2 className="text-gray-800 font-semibold md:text-xl text-lg leading-8 mb-0">
            {title}
          </h2>
          <button
            type="button"
            onClick={hideModal}
            className="inline-flex items-center justify-center w-6 h-6"
          >
            <span className="material-icons text-gray-600">close</span>
          </button>
        </div>
        <form onSubmit={handleFormSubmit}>
          {modalType === 'image' ? (
            renderImageForm()
          ) : modalType === 'url' ? (
            renderUrlForm()
          ) : (
            <div className="lg:px-6 px-4 w-full flex items-start gap-4">
              {loggedInUserLogo ? (
                <img
                  src={loggedInUserLogo}
                  alt={loggedInUserName}
                  className="w-16 h-16 flex-shrink-0 lg:inline-flex hidden rounded-full"
                />
              ) : (
                renderUserAvatar(
                  loggedInUserName,
                  'w-16 h-16 flex-shrink-0 lg:inline-flex hidden'
                )
              )}
              <div className="flex flex-col flex-grow gap-3 lg:w-auto w-full">
                <div className="w-full">
                  <textarea
                    className="
                      w-full
                      rounded
                      px-4
                      py-2
                      bg-white
                      border
                      border-gray-300
                      leading-6
                      text-lg
                      text-gray-700
                      focus:outline-none
                    "
                    cols="20"
                    rows="3"
                    maxLength={!isFromAdminPage ? 150 : ''}
                    placeholder="What's the latest?"
                    onChange={(e) => {
                      return setUpdateMessage(e.target.value);
                    }}
                    value={updateMessage || ''}
                    required
                  />
                  {!isFromAdminPage && (
                    <div className="flex justify-between">
                      <span className="text-gray-600 text-sm">
                        {updateMessage?.length
                          ? 150 - updateMessage.length
                          : 150}
                        {` ${t('newsfeed.pageContent.charactersRemaining')}`}
                      </span>
                      <span
                        className={`
                        ${
                          pitchUpdateCount > 0
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800'
                        }
                        text-sm 
                        rounded-sm 
                        p-1
                      `}
                      >
                        {pitchUpdateCount}
                        {` ${t('newsfeed.pageContent.updatesRemaining')}`}
                      </span>
                    </div>
                  )}
                </div>
                {url?.length && (
                  <a
                    href={checkProtocol(url)}
                    target="_blank"
                    className="break-all text-blue-600 text-base leading-6"
                    rel="noreferrer"
                  >
                    {checkProtocol(url)}
                  </a>
                )}
                {imagesForLightBox?.length ? (
                  <div className="w-full md:h-96 h-64 relative rounded-xl bg-gray-200 overflow-hidden">
                    {removingImage ? (
                      <div className="absolute inset-0 w-full h-full bg-gray-200 bg-opacity-50">
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                          <span className="font-semibold text-gray-800 text-xl">
                            Refreshing...
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <LightBox
                          type="carousel"
                          images={imagesForLightBox}
                          isOpen
                          initialIdx={index}
                          updateSlideIndex={handleCurrentIndexUpdate}
                          hasUpdateIndexFunction
                        />
                        <button
                          type="button"
                          onClick={(e) => {
                            return removeImages(e);
                          }}
                          className="absolute top-3 right-3 bg-white p-2 rounded-full inline-flex items-center justify-center text-gray-700"
                        >
                          <span className="material-icons">close</span>
                        </button>
                      </>
                    )}
                  </div>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <></>
                )}
                {proposalId && (
                  <NewsfeedProposalCard
                    isModal
                    handleOnClick={() => {
                      return setProposalId('');
                    }}
                    proposalTitle={selectedProposal?.title}
                    proposalUri={selectedProposal?.proposalUri}
                    proposalLogo={selectedProposal?.logoUri}
                    proposalDescription={selectedProposal?.description}
                  />
                )}
                {proposals?.length ? (
                  <div className="flex flex-wrap gap-2 items-center justify-between">
                    <label
                      htmlFor="pitch"
                      className="text-gray-800 text-lg font-semibold leading-6"
                    >
                      {t('newsfeed.pageContent.selectPitch')}
                    </label>
                    <div className="relative flex-grow md:w-auto w-full">
                      <select
                        onChange={(e) => {
                          return setProposalId(e.target.value);
                        }}
                        value={proposalId}
                        id="pitch"
                        className="
                          w-full
                          bg-white
                          border
                          border-gray-300
                          focus:border-blue-600
                          focus:outline-none
                          text-gray-800
                          text-base
                          leading-6
                          rounded
                          p-2.5
                          appearance-none
                          cursor-pointer
                        "
                        required
                      >
                        <option disabled value="">
                          {t('newsfeed.pageContent.selectAPitch')}
                        </option>
                        {proposals.map((option) => {
                          return (
                            <option
                              key={option?.proposalId}
                              value={option?.proposalId}
                            >
                              {option?.title}
                            </option>
                          );
                        })}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800">
                        <span className="material-icons">expand_more</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <></>
                )}
                {isFromAdminPage && sites?.length && languages?.length && (
                  <>
                    <div className="flex flex-wrap gap-2 items-center justify-between mb-3">
                      <label
                        htmlFor="language"
                        className="text-gray-800 text-lg font-semibold leading-6"
                      >
                        Language
                      </label>
                      <div className="relative flex-grow md:w-auto w-full">
                        <select
                          onChange={(e) => {
                            return updateNewsfeedLang(e.target.value);
                          }}
                          value={newsfeedLanguage}
                          id="language"
                          className="
                            w-full
                            bg-white
                            border
                            border-gray-300
                            focus:border-blue-600
                            focus:outline-none
                            text-gray-800
                            text-base
                            leading-6
                            rounded
                            p-2.5
                            appearance-none
                            cursor-pointer
                          "
                        >
                          <option value="">All Language</option>
                          {languages?.map((language) => {
                            return (
                              <option
                                key={language?.languageCode}
                                value={language?.languageCode}
                              >
                                {language?.name}
                              </option>
                            );
                          })}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800">
                          <span className="material-icons">expand_more</span>
                        </div>
                      </div>
                      <p className="w-full text-sm italic">
                        Note: Language will be ignored during searching
                      </p>
                    </div>
                    <div className="flex flex-wrap gap-2 items-center justify-between mb-3">
                      <label
                        htmlFor="site"
                        className="text-gray-800 text-lg font-semibold leading-6"
                      >
                        Site
                      </label>
                      <div className="relative flex-grow md:w-auto w-full">
                        <select
                          onChange={(e) => {
                            return setNewsfeedSite(e.target.value);
                          }}
                          value={newsfeedSite}
                          id="site"
                          className="
                            w-full
                            bg-white
                            border
                            border-gray-300
                            focus:border-blue-600
                            focus:outline-none
                            text-gray-800
                            text-base
                            leading-6
                            rounded
                            p-2.5
                            appearance-none
                            cursor-pointer
                          "
                        >
                          <option value="">All Sites</option>
                          {sites?.map((site) => {
                            return (
                              <option
                                key={site?.siteCode}
                                value={site?.siteCode}
                              >
                                {site?.topLocation?.name}
                              </option>
                            );
                          })}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800">
                          <span className="material-icons">expand_more</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-2 items-center justify-between mb-3">
                      <label
                        htmlFor="time"
                        className="text-gray-800 text-lg font-semibold leading-6"
                      >
                        Set Publish Time
                      </label>
                      <div className="relative flex-grow md:w-auto w-full">
                        <input
                          value={publishDate}
                          className=" w-full bg-white border border-gray-300 focus:border-blue-600 focus:outline-none text-gray-800 text-base leading-6 rounded p-2.5 appearance-none cursor-pointer"
                          type="datetime-local"
                          id="time"
                          name="time"
                          onChange={(e) => {
                            return updateTime(e.target.value);
                          }}
                          required
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="flex md:flex-nowrap flex-wrap md:gap-0 gap-2 items-center justify-between">
                  <ModalButtons
                    openImageModal={openImageModal}
                    openUrlModal={openUrlModal}
                    nowrap
                    isFromAdminPage={isFromAdminPage}
                  />
                  {isFromAdminPage ? (
                    <NewsfeedButton
                      classNames="md:w-auto w-full"
                      type="primary"
                      title={
                        presetFeed ? 'Update' : t('newsfeed.pageContent.post')
                      }
                      loading={isPosting}
                    />
                  ) : pitchUpdateCount > 0 ? (
                    <NewsfeedButton
                      classNames="md:w-auto w-full"
                      type="primary"
                      title={t('newsfeed.pageContent.post')}
                      loading={isPosting}
                    />
                  ) : (
                    <a
                      href={pitchUpdatePurchaseUri}
                      target="_blank"
                      rel="noreferrer"
                      className="
                          px-4
                          py-2
                          md:w-auto
                          w-full
                          bg-blue-600
                          text-white
                          font-semibold
                          text-base
                          rounded-md
                          whitespace-nowrap
                        "
                    >
                      {` ${t('newsfeed.pageContent.buyUpdates')}`}
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default NewsfeedModal;

NewsfeedModal.propTypes = {
  loggedInUserLogo: PropTypes.string,
  loggedInUserName: PropTypes.string,
  title: PropTypes.string,
  hideModal: PropTypes.func,
  modalType: PropTypes.string,
  openImageModal: PropTypes.func,
  openUrlModal: PropTypes.func,
  submitPost: PropTypes.func,
  proposals: PropTypes.instanceOf(Array),
  isPosting: PropTypes.bool,
  isFromAdminPage: PropTypes.bool,
  sites: PropTypes.instanceOf(Array),
  languages: PropTypes.instanceOf(Array),
  updateNewsfeedParameters: PropTypes.func,
  presetFeed: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array)
  ]),
  deleteImageUri: PropTypes.string,
  updatePreSetImageArray: PropTypes.func,
  pitchUpdateCount: PropTypes.number,
  pitchUpdatePurchaseUri: PropTypes.string
};

NewsfeedModal.defaultProps = {
  loggedInUserLogo: '',
  loggedInUserName: '',
  title: '',
  hideModal: () => {},
  modalType: '',
  openImageModal: () => {},
  openUrlModal: () => {},
  submitPost: () => {},
  proposals: [],
  isPosting: false,
  isFromAdminPage: false,
  sites: [],
  languages: [],
  updateNewsfeedParameters: () => {},
  presetFeed: null,
  deleteImageUri: '',
  updatePreSetImageArray: () => {},
  pitchUpdateCount: null,
  pitchUpdatePurchaseUri: ''
};
