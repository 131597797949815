import React from 'react';
import * as PropTypes from 'prop-types';
import { renderDocumentIcon } from '../../Util/data';

const Attachment = ({ attachment, classNames }) => {
  const getDocumentIcon = (fileName) => {
    return renderDocumentIcon(fileName);
  };

  return (
    <div
      className={`
        relative
        w-full
        mt-3
        rounded-lg
        px-4
        py-2.5
        gap-x-4
        h-full
        flex
        items-center
        justify-start
        border
        border-gray-200
        ${classNames || ''}
      `}
    >
      <div className="w-9 h-full">{getDocumentIcon(attachment.name)}</div>
      <p className="flex-grow md:text-base text-sm text-gray-800 font-medium max-w-xs break-all">
        {attachment.name}
      </p>
      <a
        className="bg-gray-200 hover:bg-gray-300 p-3 rounded-md"
        href={attachment.uri}
        download={attachment.name}
      >
        <svg
          width="12"
          height="15"
          viewBox="0 0 12 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Download file</title>
          <path
            d="M8.49935 5.5H11.8327L5.99935 11.3333L0.166016 5.5H3.49935V0.5H8.49935V5.5ZM0.166016 14.6667V13H11.8327V14.6667H0.166016Z"
            fill="#333333"
          />
        </svg>
      </a>
    </div>
  );
};

export default Attachment;

Attachment.propTypes = {
  attachment: PropTypes.instanceOf(Object).isRequired,
  classNames: PropTypes.string
};

Attachment.defaultProps = {
  classNames: null
};
