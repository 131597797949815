import React from 'react';

const NewsfeedSkeleton = () => {
  return (
    <div className="w-full p-6 rounded-lg flex flex-col items-start gap-4 bg-white shadow">
      <div className="flex w-full items-start gap-4">
        <div className="w-16 h-16 flex-shrink-0 rounded-full animate-pulse bg-gray-200" />
        <div className="flex flex-col w-48 gap-1">
          <div className="h-6 rounded animate-pulse bg-gray-300" />
          <span className="h-5 rounded animate-pulse bg-gray-200" />
        </div>
      </div>
      <div className="flex flex-col gap-1.5 w-full">
        <div className="w-full h-6 rounded animate-pulse bg-gray-200" />
        <div className="w-1/2 h-6 rounded animate-pulse bg-gray-200" />
      </div>
      <div className="w-full relative">
        <div className="flex w-full items-center rounded-lg p-3 gap-x-4 bg-white shadow">
          <div className="md:w-20 md:h-20 w-16 h-16 animate-pulse bg-gray-200 flex-shrink-0 rounded-lg" />
          <div className="flex gap-x-3 items-center flex-grow">
            <div className="flex flex-col w-full h-full gap-1">
              <div className="h-5 rounded animate-pulse bg-gray-300" />
              <span className="h-3 rounded animate-pulse bg-gray-200" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsfeedSkeleton;
