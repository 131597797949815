import React from 'react';
import * as PropTypes from 'prop-types';

const NewsfeedProposalCard = ({
  isModal,
  handleOnClick,
  proposalTitle,
  proposalUri,
  proposalLogo,
  isFeatured,
  proposalDescription
}) => {
  return (
    <div className="w-full relative">
      <a href={proposalUri} target="_blank" rel="noreferrer">
        <div
          className={`flex w-full items-start rounded-lg p-3 gap-x-4 ${
            isFeatured
              ? 'bg-blue-50 border border-blue-50 hover:border-blue-600'
              : 'bg-white'
          } shadow`}
        >
          <img
            src={proposalLogo}
            alt={proposalTitle}
            className="md:w-20 md:h-20 w-16 h-16 object-cover object-center flex-shrink-0 rounded-lg"
          />
          <div className="flex gap-x-3 items-center flex-grow">
            <div className="flex flex-col w-full h-full">
              <h5 className="font-semibold text-gray-800 sm:text-lg text-base leading-5">
                {proposalTitle}
              </h5>
              {proposalDescription && (
                <p className="font-normal leading-6 text-base text-gray-700">
                  {proposalDescription?.length > 150
                    ? `${proposalDescription?.slice(0, 150)}...`
                    : proposalDescription}
                </p>
              )}
            </div>
            {!isModal && (
              <div className="w-6 h-6 m-3 md:inline-flex hidden justify-end">
                <span
                  className={`${
                    isFeatured ? 'text-blue-600' : 'text-gray-600'
                  } material-icons`}
                >
                  open_in_new
                </span>
              </div>
            )}
          </div>
        </div>
      </a>
      {isModal && (
        <button
          type="button"
          onClick={handleOnClick}
          className="absolute top-2 right-2 rounded-md bg-gray-200 text-gray-800 p-1.5 md:w-8 md:h-8 w-5 h-5 inline-flex items-center justify-center"
        >
          <span className="md:text-base text-sm text-gray-800 material-icons">
            close
          </span>
        </button>
      )}
    </div>
  );
};

export default NewsfeedProposalCard;

NewsfeedProposalCard.propTypes = {
  isModal: PropTypes.bool,
  handleOnClick: PropTypes.func,
  proposalTitle: PropTypes.string,
  proposalUri: PropTypes.string,
  proposalLogo: PropTypes.string,
  isFeatured: PropTypes.bool,
  proposalDescription: PropTypes.string
};

NewsfeedProposalCard.defaultProps = {
  isModal: false,
  handleOnClick: () => {},
  isFeatured: false,
  proposalTitle: '',
  proposalUri: '',
  proposalLogo: '',
  proposalDescription: ''
};
