export const connectionStatusCodes = {
  deleted: 'DL',
  dueDiligence: 'DD',
  inDiscussions: 'ID',
  invested: 'NV',
  meeting: 'MT',
  messaging: 'MS',
  new: 'NW',
  noLongerInterested: 'NI',
  nudgeSent: 'NS',
  offerMade: 'OM',
  pendingAcceptance: 'PA',
  phoneCall: 'PC',
  reviewingDocument: 'RD',
  rejected: 'RJ',
  qna: 'QA'
};

export default connectionStatusCodes;
