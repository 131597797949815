/* eslint-disable indent */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Lightbox } from 'yet-another-react-lightbox';
import * as PropTypes from 'prop-types';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Inline from 'yet-another-react-lightbox/plugins/inline';
import LightBoxButton from './LightBoxButton';
import VideoPlayer from '../Newsfeed/VideoPlayer';

const LightBox = ({
  type,
  images,
  isOpen,
  initialIdx,
  closeLightBox,
  updateSlideIndex,
  hasUpdateIndexFunction = false
}) => {
  return (
    <Lightbox
      styles={{
        root: {
          '--yarl__color_backdrop': 'rgba(0, 0, 0, .85)'
        }
      }}
      on={{
        view: hasUpdateIndexFunction
          ? (index) => {
              return updateSlideIndex(index);
            }
          : null
      }}
      render={{
        iconNext: () => {
          return <LightBoxButton direction="right" />;
        },
        iconPrev: () => {
          return <LightBoxButton direction="left" />;
        },
        buttonPrev:
          images?.length <= 1
            ? () => {
                return null;
              }
            : undefined,
        buttonNext:
          images?.length <= 1
            ? () => {
                return null;
              }
            : undefined,
        slide: ({ slide, rect }) => {
          return slide?.type && slide?.type === 'video' ? (
            slide?.provider === 'youtube' ? (
              <div className="md:w-2/3 w-11/12 h-auto">
                <VideoPlayer
                  videoSource={slide?.provider}
                  videoUrl={slide?.src}
                />
              </div>
            ) : (
              // Using custom iframe here because
              // the VideoPlayer component is not working inside a lightbox with Vimeo unless the window is resized
              <iframe
                title="Proposal Video"
                width={Math.min(
                  slide.width,
                  rect.width,
                  (slide.width * rect.height) / slide.height
                )}
                height={Math.min(
                  slide.height,
                  rect.height,
                  (slide.height * rect.width) / slide.width
                )}
                src={`https://player.vimeo.com/video/${slide?.src}?loop=false&autoplay=false&muted=false&gesture=media&playsinline=false&byline=false&portrait=false&title=false&speed=true&transparent=false&customControls=true`}
                className="border-0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )
          ) : null;
        }
      }}
      open={isOpen}
      index={initialIdx}
      close={type !== 'carousel' ? closeLightBox : null}
      slides={images}
      plugins={[type === 'carousel' ? Inline : Counter]}
      animation={{
        fade: type === 'carousel' ? 0 : 250,
        swipe: type === 'carousel' ? 0 : 500,
        navigation: type === 'carousel' ? 0 : 500,
        easing: {
          fade: type === 'carousel' ? 'none' : 'ease',
          swipe: type === 'carousel' ? 'none' : 'ease-out',
          navigation: type === 'carousel' ? 'none' : 'ease-in-out'
        }
      }}
      controller={{
        closeOnBackdropClick: true
      }}
      carousel={{
        padding: 0,
        spacing: 0,
        imageFit: type === 'carousel' ? 'cover' : 'contain',
        finite: true
      }}
      inline={{
        style: {
          width: '100%',
          maxWidth: '900px',
          height: '100%',
          margin: '0 auto'
        }
      }}
    />
  );
};

export default LightBox;

LightBox.propTypes = {
  type: PropTypes.oneOf(['lightbox', 'carousel']),
  images: PropTypes.instanceOf(Array),
  isOpen: PropTypes.bool,
  initialIdx: PropTypes.number,
  closeLightBox: PropTypes.func,
  updateSlideIndex: PropTypes.func,
  hasUpdateIndexFunction: PropTypes.bool
};

LightBox.defaultProps = {
  type: 'lightbox',
  images: [],
  isOpen: false,
  initialIdx: 0,
  closeLightBox: () => {},
  updateSlideIndex: () => {},
  hasUpdateIndexFunction: false
};
