import React from 'react';
import * as PropTypes from 'prop-types';
import useOnclickOutside from 'react-cool-onclickoutside';

const DashboardBottomSheet = ({
  title,
  description,
  confirmButtonText,
  discardButtonText,
  handleConfirmButtonClick,
  hideModal,
  modalType
}) => {
  const modalContent = useOnclickOutside(() => {
    hideModal();
  });

  return (
    <div
      role="dialog"
      tabIndex={-1}
      className="fixed z-50 w-full inset-0 h-screen justify-center items-center flex bg-black bg-opacity-90"
    >
      <div
        ref={modalContent}
        className="absolute bottom-0 inset-x-0 h-5/6 w-full bg-white pt-24 pb-8 px-5 overflow-auto"
      >
        <button
          type="button"
          onClick={hideModal}
          className="absolute inline-flex items-center justify-center top-4 right-5 bg-blue-50 w-12 h-12 rounded-full group"
        >
          <span className="material-icons text-gray-500 group-hover:text-gray-600">
            close
          </span>
        </button>
        <div className="max-w-lg mx-auto flex flex-col items-center text-center p-8 gap-6 border border-blue-100 rounded-xl">
          <h3 className="text-2xl leading-6 text-gray-800 font-semibold">
            {title}
          </h3>
          <div className="w-full flex flex-col items-center gap-4">
            <p className="text-sm text-gray-700">{description}</p>
            <button
              type="button"
              onClick={() => {
                return handleConfirmButtonClick(modalType);
              }}
              className="inline-flex justify-center items-center w-full max-w-xxs px-6 py-2 font-semibold text-base rounded-md bg-blue-600 hover:bg-blue-500 text-white"
            >
              {confirmButtonText}
            </button>
            <button
              type="button"
              onClick={hideModal}
              className="inline-flex justify-center items-center w-full max-w-xxs px-6 py-2 font-semibold text-base rounded-md bg-gray-200 hover:bg-gray-300 text-gray-800"
            >
              {discardButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBottomSheet;

DashboardBottomSheet.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  discardButtonText: PropTypes.string.isRequired,
  handleConfirmButtonClick: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.oneOf(['add', 'delete']).isRequired
};
