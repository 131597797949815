/* eslint-disable indent */
import React from 'react';
import * as PropTypes from 'prop-types';

const CarouselButton = ({ direction, onClick }) => {
  return (
    <button
      type="button"
      className={`
        inline-flex
        items-center
        justify-center
        md:inline-flex
        hidden
        absolute
        inset-y-0
        px-4
        h-full
        focus:outline-none
        focus:bg-white
        text-2xl
        ${
          direction === 'left'
            ? 'bg-gradient-to-l left-0'
            : 'bg-gradient-to-r right-0'
        }
        from-transparent
        to-white
        focus:shadow-inner
        m-0
        text-gray-700
      `}
      onClick={onClick}
    >
      <i
        className={`fas fa-angle-right ${
          direction === 'left' ? 'transform scale-negative' : ''
        }`}
      />
    </button>
  );
};

export default CarouselButton;

CarouselButton.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']),
  onClick: PropTypes.func.isRequired
};

CarouselButton.defaultProps = {
  direction: 'right'
};
