import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ModalButtons = ({ openImageModal, openUrlModal, nowrap }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`flex gap-2 ${nowrap ? 'flex-nowrap' : 'flex-wrap'} w-full`}
    >
      <button
        type="button"
        onClick={openImageModal}
        className={`
          bg-gray-200
          rounded-md
          gap-2
          flex
          items-center
          ${nowrap ? 'md:px-4 px-2' : 'px-4'}
          py-2
          text-gray-800
          lg:text-base
          text-sm
          whitespace-nowrap
          font-semibold
        `}
      >
        <span
          className={`material-icons ${nowrap ? 'md:text-base text-sm' : ''}`}
        >
          photo_library
        </span>
        {` ${t('newsfeed.pageContent.addImage')}`}
      </button>
      <button
        type="button"
        onClick={openUrlModal}
        className={`
          bg-gray-200
          rounded-md
          gap-2
          flex
          items-center
          ${nowrap ? 'md:px-4 px-2' : 'px-4'}
          py-2
          text-gray-800
          lg:text-base
          text-sm
          whitespace-nowrap
          font-semibold
        `}
      >
        <span
          className={`material-icons ${nowrap ? 'md:text-base text-sm' : ''}`}
        >
          link
        </span>
        {` ${t('newsfeed.pageContent.shareLink')}`}
      </button>
    </div>
  );
};

export default ModalButtons;

ModalButtons.propTypes = {
  openImageModal: PropTypes.func,
  openUrlModal: PropTypes.func,
  nowrap: PropTypes.bool
};

ModalButtons.defaultProps = {
  openImageModal: () => {},
  openUrlModal: () => {},
  nowrap: false
};
