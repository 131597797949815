import React from 'react';
const NoInvestorFound = ({ message, addPitchUri }) => {
    return (React.createElement("div", { className: "max-w-sm rounded-md flex flex-col p-6" },
        React.createElement("div", { className: "text-center bg-blue-50 text-gray-500 border-2 border-dashed border-blue-100 rounded-xl h-full" },
            React.createElement("div", { className: "p-16" },
                React.createElement("div", { className: "text-xl w-full text-center whitespace-pre-wrap" }, message),
                React.createElement("img", { src: "/images/small/curve-arrow-28X84.png", alt: "curved arrow" }),
                React.createElement("div", { className: "flex justify-center" },
                    React.createElement("a", { href: addPitchUri, className: "btn--primary cursor-pointer rounded-full w-28 h-28 text-white text-7xl flex justify-center items-center" }, "\uFF0B"))))));
};
export default NoInvestorFound;
