import React from 'react';
import { useTranslation } from 'react-i18next';

const Endorsement = () => {
  const { t } = useTranslation();
  return (
    <>
      <p className="font-normal leading-6 text-base text-gray-700">
        {/* FIXME - ent and inv names should come from the backend when we implement endorsements */}
        <span className="text-blue-600">ENTREPRENEUR</span>{' '}
        {t('newsfeed.pageContent.endorsed')}{' '}
        <span className="text-blue-600">INVESTOR</span>
      </p>
      <div className="w-full border-l border-gray-300 pl-4">
        <p className="text-xl leading-7 font-normal text-gray-700">
          {/* FIXME - this should come from the backend when we implement endorsements */}
          “Daryll is a great guy.. we worked together at Super Startup. He is a
          great team player.. this is an endorsement.”
        </p>
      </div>
    </>
  );
};

export default Endorsement;
