import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import moment from 'moment';
import BarChart from './BarChart';
import LineChart from './LineChart';

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Chart = ({
  currencyCode,
  data,
  datasets,
  locale,
  preFetchedLabels,
  type
}) => {
  const [labels, setLabels] = useState([]);

  const chartData = {
    labels,
    datasets
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (data) {
      const labelsArr = [];
      data?.created_date?.map((date) => {
        const formattedDate = moment(date).locale('en').format('MMM D, YYYY');
        return labelsArr.push(formattedDate);
      });
      setLabels(labelsArr);
    }
    if (preFetchedLabels) {
      if (type === 'bar') {
        return setLabels(preFetchedLabels);
      }
      const labelsArr = [];
      preFetchedLabels?.map((label) => {
        const formattedDate = moment(label).locale('en').format('MMM D, YYYY');
        return labelsArr.push(formattedDate);
      });
      setLabels(labelsArr);
    }
  }, [data, preFetchedLabels]);

  return type === 'bar' ? (
    <BarChart
      chartData={chartData}
      currencyCode={currencyCode}
      locale={locale}
    />
  ) : (
    <LineChart chartData={chartData} />
  );
};

export default Chart;

Chart.propTypes = {
  currencyCode: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  datasets: PropTypes.instanceOf(Array),
  locale: PropTypes.string,
  preFetchedLabels: PropTypes.instanceOf(Array),
  type: PropTypes.oneOf(['bar', 'line'])
};

Chart.defaultProps = {
  currencyCode: null,
  data: null,
  datasets: null,
  locale: null,
  preFetchedLabels: null,
  type: 'line'
};
