import React from 'react';
import * as PropTypes from 'prop-types';

const ProposalPlaceholder = ({ buttonText, buttonUri }) => {
  return (
    <div className="proposal md:max-w-xl flex-grow rounded-2xl flex items-center justify-center bg-white text-left border border-blue-50 h-full">
      <a
        href={buttonUri}
        className="py-2 px-5 text-base bg-gray-200 rounded-md hover:bg-gray-300 font-semibold text-gray-800 text-lg"
      >
        {buttonText}
      </a>
    </div>
  );
};

export default ProposalPlaceholder;

ProposalPlaceholder.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonUri: PropTypes.string.isRequired
};
