import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ApplyClearButton = ({ onApply, onClear }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-8 flex justify-between">
      <button
        type="button"
        className="underline text-blue-500 text-base"
        onClick={onClear}
      >
        {t('findInvestors.clear')}
      </button>
      <button
        type="button"
        className="underline text-blue-500 text-base"
        onClick={onApply}
      >
        {t('findInvestors.apply')}
      </button>
    </div>
  );
};

export default ApplyClearButton;

ApplyClearButton.propTypes = {
  onApply: PropTypes.func,
  onClear: PropTypes.func
};

ApplyClearButton.defaultProps = {
  onApply: () => {},
  onClear: () => {}
};
