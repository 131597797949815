import React from 'react';
import Marquee from 'react-fast-marquee';
import * as PropTypes from 'prop-types';

const MarqueeComponent = ({ images }) => {
  return (
    <div className="w-full opacity-50">
      <Marquee autoFill pauseOnHover className="md:flex hidden">
        {images.map((image) => {
          return (
            <img
              key={image.src}
              className="object-contain mx-8"
              src={image.src}
              alt={image.alt}
            />
          );
        })}
      </Marquee>
      <div className="flex md:hidden flex-col items-center gap-8">
        {images.map((image) => {
          return (
            <img
              key={image.src}
              className="object-contain"
              src={image.src}
              alt={image.alt}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MarqueeComponent;

MarqueeComponent.propTypes = {
  images: PropTypes.instanceOf(Array)
};

MarqueeComponent.defaultProps = {
  images: []
};
