import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import Loader from '../Loader/Loader';

const NewsfeedButton = ({ onClick, type, title, classNames, loading }) => {
  const [buttonType, setButtonType] = useState('disabled');

  useEffect(() => {
    switch (type) {
      case 'secondary':
        setButtonType('bg-gray-200 text-gray-800');
        break;
      case 'primary':
        setButtonType('bg-blue-600 text-white');
        break;
      default:
        setButtonType('bg-gray-100 text-gray-400 cursor-not-allowed');
        break;
    }
  }, [type]);

  return (
    <button
      type={type === 'primary' ? 'submit' : 'button'}
      disabled={type === 'disabled' || loading}
      onClick={onClick}
      className={`
        px-4
        py-2
        ${classNames || ''}
        ${buttonType}
        ${loading ? 'cursor-not-allowed' : ''}
        font-semibold
        text-base
        rounded-md
      `}
    >
      {loading ? <Loader title="Posting" /> : title}
    </button>
  );
};

export default NewsfeedButton;

NewsfeedButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  classNames: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['disabled', 'secondary', 'primary'])
};

NewsfeedButton.defaultProps = {
  onClick: () => {},
  title: null,
  classNames: null,
  loading: false,
  type: 'disabled'
};
