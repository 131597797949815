/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { postNewsfeed, fetchProposals } from '../../Util/ApiService';
import NewsfeedModal from '../../Components/Newsfeed/Modal';
import { displayToast } from '../../Util/Toast';

const NewsfeedAdmin = ({
  addNewsfeedUri,
  getProposal,
  languages,
  loggedInUserLogo,
  newsfeed,
  sites,
  updateNewsfeedUri,
  userName,
  deleteImageUri,
  addImageUri,
  userId
}) => {
  const { t } = useTranslation();
  const sitesArray = JSON.parse(sites);
  const languagesArray = JSON.parse(languages);
  const preFetchedNewsfeed = newsfeed ? JSON.parse(newsfeed) : null;

  const [language, setLanguage] = useState(null);
  const [modalTitle, setModalTitle] = useState('Post an update');
  const [modalType, setModalType] = useState('default');
  const [posting, setPosting] = useState(false);
  const [site, setSite] = useState(null);
  const [date, setDate] = useState(null);
  const [userProposals, setUserProposals] = useState([]);

  // eslint-disable-next-line no-shadow
  const handleModalOpen = (modalType, modalTitle) => {
    setModalType(modalType);
    setModalTitle(modalTitle);
  };

  const openDefaultModal = () => {
    handleModalOpen('default', 'Post an update');
  };

  const handleModalHide = (images) => {
    if (images?.length && preFetchedNewsfeed) {
      // eslint-disable-next-line no-use-before-define
      uploadSelectedImages(images);
    }
    // eslint-disable-next-line no-undef
    const modalWrapper = document.querySelector('.modal-wrapper');
    modalType !== 'default'
      ? openDefaultModal()
      : modalWrapper.classList.toggle('hidden');
  };

  // eslint-disable-next-line no-shadow
  const handleNewsfeedParametersChange = (language, newsfeedSite, date) => {
    setLanguage(language);
    setSite(newsfeedSite);
    setDate(date);
  };

  const uploadSelectedImages = async (images) => {
    const formData = new FormData();
    images?.forEach((image) => {
      formData.append('images[]', image, image.name);
    });

    await postNewsfeed(addImageUri, formData);
  };

  const handlePostSubmit = async (
    selectedImages,
    imagesForLightBox,
    url,
    proposalId,
    updateMessage
    // eslint-disable-next-line consistent-return
  ) => {
    setPosting(true);
    const formData = new FormData();
    updateMessage && formData.append('content', updateMessage);
    // eslint-disable-next-line no-unused-expressions
    proposalId && formData.append('proposal', proposalId);
    site && site !== '' && formData.append('site', site);
    language && language !== '' && formData.append('language', language);
    date && date !== '' && formData.append('publishAt', date);
    url && url !== '' && formData.append('link', url);
    if (!preFetchedNewsfeed || !updateNewsfeedUri) {
      selectedImages?.forEach((selectedImage) => {
        formData.append('images[]', selectedImage, selectedImage.name);
      });
    }
    if (preFetchedNewsfeed && updateNewsfeedUri) {
      await postNewsfeed(updateNewsfeedUri, formData).then(() => {
        setPosting(false);
        handleModalHide();
        displayToast('Newsfeed updated', 'success');
      });
      // eslint-disable-next-line no-restricted-globals, no-undef
      return location.reload();
    }
    await postNewsfeed(addNewsfeedUri, formData).then(() => {
      setPosting(false);
      handleModalHide();
      displayToast(t('newsfeed.pageContent.updatePosted'), 'success');
      // eslint-disable-next-line no-restricted-globals, no-undef
      return location.reload();
    });
  };

  const openImageModal = () => {
    handleModalOpen('image', t('newsfeed.pageContent.addImages'));
  };

  const openUrlModal = () => {
    handleModalOpen('url', t('newsfeed.pageContent.shareLink'));
  };

  const updatePreSetImageArray = (images) => {
    if (preFetchedNewsfeed.images?.length) {
      preFetchedNewsfeed.images?.push(images);
    } else {
      preFetchedNewsfeed.images = images;
    }
  };

  const fetchUserProposals = async () => {
    if (userProposals?.length) {
      return;
    }
    const proposals = await fetchProposals(getProposal, {
      pageNumber: 1,
      pageSize: 25,
      user: userId
    });
    setUserProposals(proposals?.results);
  };
  useEffect(() => {
    if (userId) {
      fetchUserProposals();
    }
  }, []);

  return (
    <NewsfeedModal
      title={modalTitle}
      modalType={modalType}
      hideModal={handleModalHide}
      submitPost={handlePostSubmit}
      loggedInUserLogo={loggedInUserLogo}
      loggedInUserName={userName}
      openImageModal={openImageModal}
      openUrlModal={openUrlModal}
      isPosting={posting}
      sites={sitesArray}
      languages={languagesArray}
      isFromAdminPage
      updateNewsfeedParameters={handleNewsfeedParametersChange}
      presetFeed={preFetchedNewsfeed}
      deleteImageUri={deleteImageUri}
      updatePreSetImageArray={updatePreSetImageArray}
      proposals={userProposals}
    />
  );
};

export default NewsfeedAdmin;

NewsfeedAdmin.propTypes = {
  addNewsfeedUri: PropTypes.string,
  getProposal: PropTypes.string,
  languages: PropTypes.string,
  loggedInUserLogo: PropTypes.string,
  newsfeed: PropTypes.string,
  sites: PropTypes.string,
  updateNewsfeedUri: PropTypes.string,
  userName: PropTypes.string,
  deleteImageUri: PropTypes.string,
  addImageUri: PropTypes.string,
  userId: PropTypes.number
};

NewsfeedAdmin.defaultProps = {
  addNewsfeedUri: '',
  getProposal: '',
  languages: null,
  loggedInUserLogo: '',
  newsfeed: null,
  sites: null,
  updateNewsfeedUri: '',
  userName: '',
  deleteImageUri: '',
  addImageUri: '',
  userId: null
};
