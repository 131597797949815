/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import useOnclickOutside from 'react-cool-onclickoutside';

const levels = [
  {
    value: 1,
    title: 'Heading 1'
  },
  {
    value: 2,
    title: 'Heading 2'
  },
  {
    value: 3,
    title: 'Heading 3'
  },
  {
    value: 4,
    title: 'Heading 4'
  },
  {
    value: 5,
    title: 'Heading 5'
  },
  {
    value: 6,
    title: 'Normal'
  }
];

const Tiptap = ({ content, updateContent }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [previousUrl, setPreviousUrl] = useState('');
  const [textSizeValue, setTextSizeValue] = useState('Normal');

  const levelSelection = useOnclickOutside(() => {
    setDropdownOpen(false);
  });

  const editor = useEditor({
    content,
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false
      })
    ],
    editorProps: {
      attributes: {
        class:
          'border border-gray-300 focus:border-blue-600 focus:outline-blue600 rounded w-full p-3 flex flex-col gap-2 rounded-t-none max-h-96 overflow-auto focus:outline-none custom-thumb',
        testid: 'editor-content'
      }
    },
    onUpdate: () => {
      updateContent(editor.getHTML());
    }
  });

  const updateUrl = (url) => {
    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  };

  const handleUrlInput = (e) => {
    setPreviousUrl(e.target.value);
  };

  const toggleLink = () => {
    setPreviousUrl(editor.getAttributes('link').href);
    // eslint-disable-next-line no-alert
    const url = window.prompt('URL', previousUrl);

    updateUrl(url);
  };

  const updateSelectedUrl = (url) => {
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  };

  const updateHeading = (heading) => {
    editor.chain().focus().toggleHeading({ level: heading.value }).run();
    setTextSizeValue(heading.title);
    setDropdownOpen(false);
  };

  useEffect(() => {
    const currentUrl = editor.getAttributes('link').href || '';
    setPreviousUrl(currentUrl);
  }, [editor?.state.selection]);

  useEffect(() => {
    if (editor) {
      editor.commands.focus('end');
    }
  }, []);

  return (
    <div className="w-full" tabIndex={-1}>
      <div className="w-full flex flex-wrap gap-2 py-2 rounded-t border border-b-0 border-gray-300 px-3">
        <div
          className="relative inline-flex items-center justify-center"
          ref={levelSelection}
        >
          <button
            type="button"
            className="inline-flex items-center gap-2 hover:bg-gray-200 px-2 rounded"
            onClick={() => {
              return setDropdownOpen((value) => {
                return !value;
              });
            }}
          >
            {textSizeValue}
            <span className="material-symbols-outlined text-xl text-gray-700">
              expand_all
            </span>
          </button>
          {isDropdownOpen && (
            <div
              ref={levelSelection}
              className="z-10 absolute top-9 left-0 w-36 py-2.5 bg-white rounded-xl shadow-xl border border-gray-200"
            >
              <ul className="list-none list-inside w-full pl-0">
                {levels.map((item) => {
                  return (
                    <li key={item.value} className="w-full">
                      <button
                        type="button"
                        onClick={() => {
                          return updateHeading(item);
                        }}
                        className="px-4 py-2 hover:bg-gray-200 w-full block text-left"
                      >
                        {item.title}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <button
          type="button"
          onClick={() => {
            return editor.chain().focus().toggleBold().run();
          }}
          className={`inline-flex items-center justify-center w-7 h-7 hover:bg-gray-200 rounded ${
            editor?.isActive('bold') ? 'bg-gray-200' : ''
          }`}
        >
          <span className="material-symbols-outlined text-xl text-gray-700">
            format_bold
          </span>
        </button>
        <button
          type="button"
          onClick={() => {
            return editor.chain().focus().toggleItalic().run();
          }}
          className={`inline-flex items-center justify-center w-7 h-7 hover:bg-gray-200 rounded ${
            editor?.isActive('italic') ? 'bg-gray-200' : ''
          }`}
        >
          <span className="material-symbols-outlined text-xl text-gray-700">
            format_italic
          </span>
        </button>
        <button
          type="button"
          onClick={() => {
            return editor.chain().focus().toggleBlockquote().run();
          }}
          className={`inline-flex items-center justify-center w-7 h-7 hover:bg-gray-200 rounded ${
            editor?.isActive('blockquote') ? 'bg-gray-200' : ''
          }`}
        >
          <span className="material-symbols-outlined text-xl text-gray-700">
            {editor?.isActive('blockquote')
              ? 'format_quote_off'
              : 'format_quote'}
          </span>
        </button>
        <button
          type="button"
          onClick={() => {
            return editor.chain().focus().toggleOrderedList().run();
          }}
          className={`inline-flex items-center justify-center w-7 h-7 hover:bg-gray-200 rounded ${
            editor?.isActive('orderedList') ? 'bg-gray-200' : ''
          }`}
        >
          <span className="material-icons text-xl text-gray-700">
            format_list_numbered
          </span>
        </button>
        <button
          type="button"
          onClick={() => {
            return editor.chain().focus().toggleBulletList().run();
          }}
          className={`inline-flex items-center justify-center w-7 h-7 hover:bg-gray-200 rounded ${
            editor?.isActive('bulletList') ? 'bg-gray-200' : ''
          }`}
        >
          <span className="material-icons text-xl text-gray-700">
            format_list_bulleted
          </span>
        </button>
        <button
          type="button"
          onClick={toggleLink}
          className={`inline-flex items-center justify-center w-7 h-7 hover:bg-gray-200 rounded ${
            editor?.isActive('link') ? 'bg-gray-200' : ''
          }`}
        >
          <span className="material-symbols-outlined text-xl text-gray-700">
            {!editor?.isActive('link') ? 'link' : 'link_off'}
          </span>
        </button>
      </div>
      {editor && (
        <BubbleMenu
          editor={editor}
          tippyOptions={{ duration: 100, placement: 'bottom' }}
        >
          <div className="p-4 rounded-lg bg-white shadow-xl flex flex-col w-full gap-3 border border-gray-200">
            <input
              onInput={handleUrlInput}
              value={previousUrl}
              type="url"
              required
              className="block px-3 py-2 w-full input--brand"
            />
            <div className="flex items-center gap-2">
              {editor?.isActive('link') && (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      return editor.chain().focus().unsetLink().run();
                    }}
                    className="p-1 bg-gray-200 rounded"
                  >
                    <span className="material-symbols-outlined text-xl text-gray-700">
                      link_off
                    </span>
                  </button>
                  <a
                    href={editor.getAttributes('link').href}
                    rel="noreferrer"
                    target="_blank"
                    className="p-1 bg-gray-200 rounded"
                  >
                    <span className="material-symbols-outlined text-xl text-gray-700">
                      open_in_new
                    </span>
                  </a>
                </>
              )}
              <button
                type="button"
                onClick={() => {
                  return updateSelectedUrl(previousUrl);
                }}
                className="rounded-full bg-gray-200 text-gray-800 py-1.5 px-3 text-sm"
              >
                {!editor?.isActive('link') ? 'Add link' : 'Update'}
              </button>
            </div>
          </div>
        </BubbleMenu>
      )}
      <EditorContent
        className="editable-content prose max-w-none"
        editor={editor}
      />
    </div>
  );
};

export default Tiptap;

Tiptap.propTypes = {
  content: PropTypes.string.isRequired,
  updateContent: PropTypes.func.isRequired
};
