import React from 'react';
import * as PropTypes from 'prop-types';

const Hero = ({ text }) => {
  return (
    <div className="max-w-container md:px-5 px-3 py-32 mx-auto">
      <h1 className="text-center text-3xl text-gray-800 font-semibold">
        {text}
      </h1>
    </div>
  );
};

Hero.propTypes = {
  text: PropTypes.string.isRequired
};

export default Hero;
