import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import Chart from '../../Components/Chart/Chart';
import { chartColors } from '../../Util/data';

const FinancialChart = ({ currencyCode, data, locale, title1, title2 }) => {
  const chartData = JSON.parse(data);
  const [profits, setProfits] = useState([]);
  const [turnovers, setTurnovers] = useState([]);
  const [years, setYears] = useState([]);

  const generateChartData = () => {
    const yearList = [];
    const profitList = [];
    const turnoverList = [];

    chartData?.forEach((response) => {
      yearList?.push(response?.year);
      profitList?.push(response?.profit);
      turnoverList?.push(response?.turnover);
    });

    setProfits(profitList);
    setTurnovers(turnoverList);
    setYears(yearList);
  };

  useEffect(() => {
    if (data) {
      generateChartData();
    }
  }, []);

  return (
    <Chart
      preFetchedLabels={years}
      currencyCode={currencyCode}
      datasets={[
        {
          label: title1,
          data: turnovers,
          backgroundColor: chartColors.chartLighterMint,
          barThickness: 'flex',
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          maxBarThickness: 50
        },
        {
          label: title2,
          data: profits,
          backgroundColor: chartColors.chartBlue,
          barThickness: 'flex',
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          maxBarThickness: 50
        }
      ]}
      locale={locale}
      type="bar"
    />
  );
};

export default FinancialChart;

FinancialChart.propTypes = {
  currencyCode: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  title1: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired
};
