/* eslint-disable indent */
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import Markdown from 'markdown-to-jsx';
import { useTranslation } from 'react-i18next';
import VideoPlayer from './VideoPlayer';
import NewsfeedProposalCard from './ProposalCard';
import LightBox from '../Lightbox/LightBox';
import ProfileUpdate from './ProfileUpdate';
import NewInvestors from './NewInvestors';
import ImageGroup from './ImageGroup';
import Endorsement from './Endorsement';
import { renderUserAvatar } from '../../Util/data';

const NewsfeedCard = ({
  images,
  type,
  externalLink,
  title,
  logoUrl,
  logoType,
  description,
  videoUrl,
  videoSource,
  time,
  locale,
  proposal,
  investorsApproved,
  investorsUpdated,
  findInvestorsUrl,
  proposals,
  newsfeedTypes,
  userProfileUri,
  feedUserIdLastChar
}) => {
  const { t } = useTranslation();

  const [isLightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxIndex, setIndex] = useState(0);

  const openLightBox = (index) => {
    setIndex(index);
    setLightBoxOpen(true);
  };

  return (
    <>
      <div className="w-full p-6 rounded-lg flex flex-col items-start gap-4 bg-white shadow">
        <div className="flex w-full items-center gap-4">
          {logoType === 'brand' ? (
            <img
              src="/images/small/logo/ain_new-logo.png"
              alt={title}
              className="w-16 h-16 flex-shrink-0 rounded object-cover object-center"
            />
          ) : logoUrl ? (
            type === newsfeedTypes?.investorViewedProposal ||
            type === newsfeedTypes?.proposalDocumentDownloaded ||
            type === newsfeedTypes?.investorInterestedInProposal ? (
              <a href={userProfileUri} target="_blank" rel="noreferrer">
                <img
                  src={logoUrl}
                  alt={title}
                  className="w-16 h-16 flex-shrink-0 rounded-full object-cover object-center"
                />
              </a>
            ) : (
              <img
                src={logoUrl}
                alt={title}
                className="w-16 h-16 flex-shrink-0 rounded-full object-cover object-center"
              />
            )
          ) : type === newsfeedTypes?.investorViewedProposal ||
            type === newsfeedTypes?.proposalDocumentDownloaded ||
            type === newsfeedTypes?.investorInterestedInProposal ? (
            <a href={userProfileUri} target="_blank" rel="noreferrer">
              {renderUserAvatar(
                title,
                'w-16 h-16 flex-shrink-0',
                `bg-${feedUserIdLastChar}`
              )}
            </a>
          ) : (
            renderUserAvatar(
              title,
              'w-16 h-16 flex-shrink-0',
              `bg-${feedUserIdLastChar}`
            )
          )}
          <div className="flex flex-col flex-grow">
            <h5 className="text-gray-800 text-lg font-semibold leading-6">
              {type === newsfeedTypes?.investorViewedProposal ||
              type === newsfeedTypes?.proposalDocumentDownloaded ||
              type === newsfeedTypes?.investorInterestedInProposal ? (
                <a
                  href={userProfileUri}
                  className="hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {title}
                </a>
              ) : (
                title
              )}
            </h5>
            {time && (
              <span className="text-gray-600 font-normal leading-5 text-sm">
                {moment(time).locale(locale).fromNow()}
              </span>
            )}
          </div>
          {type === newsfeedTypes?.proposalFeatured && (
            <div className="bg-blue-600 py-2 px-4 text-white rounded-full md:text-base text-sm leading-6 font-semibold">
              {t('newsfeed.pageContent.featured')}
            </div>
          )}
        </div>
        <p className="font-normal leading-6 text-base text-gray-700">
          {type === newsfeedTypes?.proposalUpdate ? (
            <>
              <a
                className="text-blue-600 underline uppercase"
                href={proposal?.proposalUri}
                target="_blank"
                rel="noreferrer"
              >
                {proposal?.title}
              </a>{' '}
              {t('newsfeed.pageContent.update')}
              <br />
              <br />
            </>
          ) : type === newsfeedTypes?.proposalDocumentDownloaded ? (
            <>
              {t('newsfeed.pageContent.downloaded')}&nbsp;
              <a
                className="text-blue-600 underline uppercase"
                href={proposal?.proposalUri}
                target="_blank"
                rel="noreferrer"
              >
                {proposal?.title}
              </a>
            </>
          ) : type === newsfeedTypes?.investorViewedProposal ? (
            <>
              {t('newsfeed.pageContent.checkedOut')}&nbsp;
              <a
                className="text-blue-600 underline uppercase"
                href={proposal?.proposalUri}
                target="_blank"
                rel="noreferrer"
              >
                {proposal?.title}
              </a>
            </>
          ) : type === newsfeedTypes?.investorInterestedInProposal ? (
            <>
              {t('newsfeed.pageContent.interested')}&nbsp;
              <a
                className="text-blue-600 underline uppercase"
                href={proposal?.proposalUri}
                target="_blank"
                rel="noreferrer"
              >
                {proposal?.title}
              </a>
            </>
          ) : (
            type === newsfeedTypes?.proposalFeatured && (
              <>
                {t('newsfeed.pageContent.justUpdated')}&nbsp;
                <a
                  className="text-blue-600 underline uppercase"
                  href={proposal?.proposalUri}
                  target="_blank"
                  rel="noreferrer"
                >
                  {proposal?.title}
                </a>
                <br />
                <br />
              </>
            )
          )}
        </p>
        {description && (
          <Markdown className="font-normal leading-6 text-base text-gray-700">
            {description}
          </Markdown>
        )}
        {externalLink && (
          <a
            href={externalLink}
            target="_blank"
            className="text-blue-600 text-base font-normal break-all"
            rel="noreferrer"
          >
            {externalLink}
          </a>
        )}
        {proposal &&
          type !== newsfeedTypes?.newProposalsAdded &&
          type !== newsfeedTypes?.latestInvestors &&
          type !== newsfeedTypes?.updatedInvestors && (
            <NewsfeedProposalCard
              proposalTitle={proposal?.title}
              proposalUri={proposal?.proposalUri}
              proposalLogo={proposal?.logoUri}
              proposalDescription={proposal?.description}
              isFeatured={type === newsfeedTypes?.proposalFeatured}
            />
          )}
        {type === 'endorsement' && <Endorsement />}
        {images?.length > 0 && (
          <ImageGroup
            images={images}
            openLightBox={(e) => {
              return openLightBox(e);
            }}
          />
        )}
        {videoUrl && videoSource && (
          <VideoPlayer videoUrl={videoUrl} videoSource={videoSource} />
        )}
        {type === newsfeedTypes?.latestInvestors && (
          <NewInvestors
            findInvestorsUrl={findInvestorsUrl}
            investors={investorsApproved}
          />
        )}
        {type === newsfeedTypes?.updatedInvestors && (
          <ProfileUpdate profileUpdates={investorsUpdated} />
        )}
        {type === newsfeedTypes?.newProposalsAdded &&
          proposals?.length &&
          // eslint-disable-next-line no-shadow
          proposals?.map((proposal) => {
            return (
              <NewsfeedProposalCard
                key={proposal?.proposalId}
                proposalTitle={proposal?.title}
                proposalUri={proposal?.proposalUri}
                proposalLogo={proposal?.logoUri}
                proposalDescription={proposal?.description}
              />
            );
          })}
      </div>
      {images?.length > 0 && (
        <LightBox
          type="lightbox"
          images={images}
          isOpen={isLightBoxOpen}
          initialIdx={lightBoxIndex}
          closeLightBox={() => {
            return setLightBoxOpen(false);
          }}
        />
      )}
    </>
  );
};

export default NewsfeedCard;

NewsfeedCard.propTypes = {
  images: PropTypes.instanceOf(Array),
  type: PropTypes.string,
  externalLink: PropTypes.string,
  title: PropTypes.string,
  logoUrl: PropTypes.string,
  logoType: PropTypes.string,
  description: PropTypes.string,
  videoUrl: PropTypes.string,
  videoSource: PropTypes.string,
  time: PropTypes.string,
  locale: PropTypes.string,
  proposal: PropTypes.instanceOf(Object),
  investorsApproved: PropTypes.instanceOf(Array),
  investorsUpdated: PropTypes.instanceOf(Array),
  findInvestorsUrl: PropTypes.string,
  proposals: PropTypes.instanceOf(Array),
  newsfeedTypes: PropTypes.instanceOf(Object),
  userProfileUri: PropTypes.string,
  feedUserIdLastChar: PropTypes.string
};

NewsfeedCard.defaultProps = {
  images: [],
  type: '',
  externalLink: '',
  title: '',
  logoUrl: '',
  logoType: '',
  description: '',
  videoUrl: '',
  videoSource: '',
  time: '',
  locale: '',
  proposal: {},
  investorsApproved: [],
  investorsUpdated: [],
  findInvestorsUrl: '',
  proposals: [],
  newsfeedTypes: {},
  userProfileUri: '',
  feedUserIdLastChar: '0'
};
