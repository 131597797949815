/* eslint-disable indent */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import * as PropTypes from 'prop-types';

const ImageGroup = ({ images, openLightBox }) => {
  const handleKeyUp = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className={`
        w-full
        overflow-hidden
        max-h-96
        grid
        gap-1
        ${
          images?.length <= 1
            ? 'grid-cols-1 grid-rows-1'
            : images?.length === 2
            ? 'grid-cols-2 grid-rows-1'
            : images?.length === 3
            ? 'grid-cols-2 grid-rows-2'
            : 'grid-cols-4 grid-rows-2'
        }
        md:h-80
        h-64
      `}
    >
      {images?.length <= 2 ? (
        images?.map((image, idx) => {
          return (
            <img
              role="button"
              onClick={() => {
                return openLightBox(idx);
              }}
              key={idx}
              src={image?.src}
              alt={image?.alt}
              className="w-full h-full object-cover object-center bg-gray-200"
              onKeyUp={handleKeyUp}
            />
          );
        })
      ) : images?.length === 3 ? (
        <>
          <div
            className="row-span-2"
            role="button"
            onClick={() => {
              return openLightBox(0);
            }}
            onKeyUp={handleKeyUp}
          >
            <img
              src={images[0]?.src}
              alt={images[0]?.alt}
              className="w-full h-full object-cover object-center bg-gray-200"
            />
          </div>
          {images.slice(1)?.map((image, idx) => {
            return (
              <div
                key={idx}
                className={`row-start-${idx + 1} col-start-2`}
                role="button"
                onClick={() => {
                  return openLightBox(idx + 1);
                }}
                onKeyUp={handleKeyUp}
              >
                <img
                  src={image?.src}
                  alt={image?.alt}
                  className="w-full h-full object-cover object-center bg-gray-200"
                />
              </div>
            );
          })}
        </>
      ) : (
        <>
          <div
            role="button"
            onClick={() => {
              return openLightBox(0);
            }}
            className="row-span-2 col-span-2"
            onKeyUp={handleKeyUp}
          >
            <img
              src={images[0]?.src}
              alt={images[0]?.alt}
              className="w-full h-full object-cover object-center bg-gray-200"
            />
          </div>
          {images.slice(1, 3)?.map((image, idx) => {
            return (
              <div
                key={idx}
                role="button"
                onClick={() => {
                  return openLightBox(idx + 1);
                }}
                className={`row-start-1 col-start-${idx + 3}`}
                onKeyUp={handleKeyUp}
              >
                <img
                  src={image?.src}
                  alt={image?.alt}
                  className="w-full h-full object-cover object-center bg-gray-200"
                />
              </div>
            );
          })}
          <div
            role="button"
            onClick={() => {
              return openLightBox(3);
            }}
            className="relative group row-start-2 col-start-3 col-span-2"
            onKeyUp={handleKeyUp}
          >
            <img
              src={images[3]?.src}
              alt={images[3]?.alt}
              className="w-full h-full object-cover object-center bg-gray-200"
            />
            {images?.length > 4 && (
              <span
                className="
                  hidden
                  absolute
                  group-hover:inline-flex
                  bg-gray-200
                  bg-opacity-80
                  inset-0
                  md:text-2xl
                  text-sm
                  font-semibold
                  text-gray-700
                  items-center
                  justify-center
                "
              >
                + {images.length - 4}
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ImageGroup;

ImageGroup.propTypes = {
  images: PropTypes.instanceOf(Array),
  openLightBox: PropTypes.func
};

ImageGroup.defaultProps = {
  images: [],
  openLightBox: () => {}
};
