var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../Util/i18n';
import { fetchInvestors } from '../../Util/ApiService';
import InvestorCard from '../../Components/InvestorCard/InvestorCard';
import NoInvestorFound from '../../Components/MyInvestors/NoInvestorFound';
import LoadMore from '../../Components/Buttons/LoadMore';
const PAGE_SIZE = 6;
const InvestorsPage = ({ locale = '', apiUrl = '', investorSearchUrl = '', addProposalUrl = '', interestedInMyProposal = false, myShortlisted = false, myNudged = false, noProposals = false, currencyCode = '', proposalId, noMaxWidth = false }) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [investors, setInvestors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
    const [showNoResultsLabel, setShowNoResultsLabel] = useState(false);
    const { t } = useTranslation();
    const getInvestors = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const newInvestors = yield fetchInvestors(apiUrl, {
            pageNumber,
            pageSize: PAGE_SIZE,
            interestedInMyProposal,
            myShortlisted,
            myNudged,
            proposalId
        });
        const totalResults = Number(newInvestors.pagination.totalResults) || 0;
        if (!totalResults) {
            setShowNoResultsLabel(true);
        }
        setPageNumber(pageNumber + 1);
        setInvestors([...investors.concat(newInvestors.results)]);
        setShowLoadMoreButton(totalResults > pageNumber * PAGE_SIZE);
        setLoading(false);
    });
    useEffect(() => {
        getInvestors();
    }, []);
    return (React.createElement("div", { className: "w-full flex flex-col items-center mt-8 h-full" },
        React.createElement("div", { className: `${noMaxWidth ? 'w-full' : 'max-w-screen-2xl'}` },
            React.createElement("div", { className: "w-full grid lg:grid-cols-3 grid-cols-1" }, investors.map((investor) => {
                var _a, _b, _c, _d;
                return interestedInMyProposal ? (React.createElement(InvestorCard, { key: investor.detailsUrl, investor: investor, locale: locale, currencyCode: currencyCode, shortlistUri: myShortlisted
                        ? ((_a = investor === null || investor === void 0 ? void 0 : investor.investorUserActions) === null || _a === void 0 ? void 0 : _a.addShortlistedInvestorUri) ||
                            ((_b = investor === null || investor === void 0 ? void 0 : investor.investorUserActions) === null || _b === void 0 ? void 0 : _b.removeShortlistedInvestorUri)
                        : false, isLoggedIn: true })) : (React.createElement(InvestorCard, { key: investor.detailsUrl, investor: investor, locale: locale, currencyCode: currencyCode, shortlistUri: myShortlisted
                        ? ((_c = investor === null || investor === void 0 ? void 0 : investor.investorUserActions) === null || _c === void 0 ? void 0 : _c.addShortlistedInvestorUri) ||
                            ((_d = investor === null || investor === void 0 ? void 0 : investor.investorUserActions) === null || _d === void 0 ? void 0 : _d.removeShortlistedInvestorUri)
                        : false }));
            }))),
        showLoadMoreButton && (React.createElement("div", { className: "mt-16 mb-24" },
            React.createElement(LoadMore, { title: t('investorCard.button'), onClick: getInvestors, loading: loading }))),
        showNoResultsLabel && interestedInMyProposal && noProposals && (React.createElement(NoInvestorFound, { addPitchUri: addProposalUrl, message: t('myInvestors.noProposals') })),
        showNoResultsLabel && interestedInMyProposal && !noProposals && (React.createElement(NoInvestorFound, { addPitchUri: investorSearchUrl, message: t('myInvestors.noInterested') })),
        showNoResultsLabel && myShortlisted && (React.createElement(NoInvestorFound, { addPitchUri: investorSearchUrl, message: t('myInvestors.noShortlisted') })),
        showNoResultsLabel && myNudged && (React.createElement(NoInvestorFound, { addPitchUri: investorSearchUrl, message: t('myInvestors.noNudged') }))));
};
export default InvestorsPage;
