/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import '../../Util/i18n';
import { useTranslation } from 'react-i18next';
import Nouislider from 'nouislider-react';
import wNumb from 'wnumb';
import { detectTranslateXval, generateRangeFromArray } from '../../Util/data';

const RangeSlider = ({ onChange, locale, currencyCode, investmentRange }) => {
  const { t } = useTranslation();
  const range = generateRangeFromArray(investmentRange);

  const [value, setValue] = useState(1);

  const updateValue = (event) => {
    setValue(event[0]);
    onChange(event[0]);
  };

  const updateTranslateValue = (element) => {
    const translateVal = parseFloat(detectTranslateXval(element));
    // somehow, noUiReact has translate values in 1000s, so converting it to 100s
    // eslint-disable-next-line no-param-reassign
    element.style.transform = `translate(${translateVal / 10}%, 0px)`;
  };

  useEffect(() => {
    if (document.getElementById('noui-react'))
      updateTranslateValue(document.getElementById('noui-react'));
  }, [value]);

  useEffect(() => {
    const noUiThumb = document.querySelector('.noUi-origin');
    noUiThumb.setAttribute('id', 'noui-react');
    updateTranslateValue(noUiThumb);
  }, []);

  return (
    <div className="relative pt-1">
      <p className="text-base">
        {t('findInvestors.showInvestorsWhoCanInvest')}
        <br />
        <span className="text-blue-400">
          {t('currency', {
            val: value,
            formatParams: {
              val: {
                currency: currencyCode,
                locale: locale.replace(/_/g, '-'),
                maximumFractionDigits: 0
              }
            }
          })}
        </span>
      </p>
      <Nouislider
        start={value}
        range={range}
        onUpdate={updateValue}
        snap
        connect
        format={wNumb({
          decimals: 0
        })}
      />
    </div>
  );
};

export default RangeSlider;

RangeSlider.propTypes = {
  onChange: PropTypes.func,
  locale: PropTypes.string,
  currencyCode: PropTypes.string,
  investmentRange: PropTypes.instanceOf(Object)
};

RangeSlider.defaultProps = {
  onChange: () => {},
  locale: '',
  currencyCode: '',
  investmentRange: null
};
