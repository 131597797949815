const fetchThumbnail = (videoId, source) => {
  const formattedId = videoId.split('?')[0];
  const thumbnailUrl =
    source === 'youtube'
      ? `https://img.youtube.com/vi/${formattedId}/hqdefault.jpg`
      : `https://vumbnail.com/${formattedId}.jpg`;
  return thumbnailUrl;
};

export default fetchThumbnail;
