import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NewsfeedInvestorCard from './InvestorCard';

const NewInvestors = ({ findInvestorsUrl, investors }) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col gap-2">
      {investors?.length &&
        investors.map((investor, idx) => {
          // eslint-disable-next-line react/no-array-index-key
          return <NewsfeedInvestorCard key={idx} investor={investor} />;
        })}
      <div className="w-full mt-4">
        <a
          href={`${findInvestorsUrl}?orderByOption=lastUpdated`}
          target="_blank"
          className="text-blue-600 underline text-base leading-6"
          rel="noreferrer"
        >
          {t('newsfeed.pageContent.viewMoreInvestors')}
        </a>
      </div>
    </div>
  );
};

export default NewInvestors;

NewInvestors.propTypes = {
  findInvestorsUrl: PropTypes.string.isRequired,
  investors: PropTypes.instanceOf(Array).isRequired
};
