import React from 'react';
import * as PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

const LineChart = ({ chartData }) => {
  return (
    <Line
      data={chartData}
      options={{
        responsive: true,
        // eslint-disable-next-line no-bitwise
        aspectRatio: 1 | 2,
        scales: {
          x: {
            gridLines: {
              display: true
            }
          },
          y: {
            beginAtZero: true,
            gridLines: {
              display: true
            },
            ticks: {
              stepSize: 500
            }
          }
        }
      }}
    />
  );
};

export default LineChart;

LineChart.propTypes = {
  chartData: PropTypes.instanceOf(Object).isRequired
};
