/* eslint-disable no-plusplus */
import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Pagination = ({
  currentPage,
  totalPages,
  totalResults,
  pageSize,
  paginationUrl
}) => {
  const { t } = useTranslation();

  const pages = [];

  const totalPageCount = Math.ceil(totalResults / pageSize);

  const leftSiblingIndex = Math.max(currentPage - 1, 1);
  const rightSiblingIndex = Math.min(currentPage + 1, totalPages);

  if (
    rightSiblingIndex <= totalPages &&
    totalPages !== 1 &&
    currentPage !== totalPages
  ) {
    for (let i = leftSiblingIndex; i <= rightSiblingIndex + 2; i++) {
      pages.push(i);
    }
  } else if (
    (currentPage === totalPages && currentPage !== 1) ||
    totalPages - currentPage === 1
  ) {
    for (let i = leftSiblingIndex - 3; i <= rightSiblingIndex; i++) {
      pages.push(i);
    }
  } else {
    for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
      pages.push(i);
    }
  }

  return (
    <div
      className="
        w-full
        flex
        justify-between
        p-4
        items-center
        bg-white
        shadow
        border
        border-slate-100
        rounded
      "
    >
      <div className="w-auto px-4">
        {t('businessProposal.perPage')} {pageSize}
      </div>
      <div className="flex-grow flex items-center justify-end gap-x-2 text-base text-blue-500">
        <a
          href={
            currentPage === 1 ? '' : paginationUrl.concat('/', currentPage - 1)
          }
          className={`${
            currentPage === 1 ? 'cursor-not-allowed' : ''
          } p-2 border border-gray-200 hover:border-blue-50 rounded hover:bg-blue-50 text-gray-700`}
        >
          {t('businessProposal.previous')}
        </a>
        <span className="bg-blue-600 border-blue-600 text-white py-2 px-4 border rounded">
          {currentPage}
        </span>
        {currentPage !== totalPageCount && totalPageCount !== 0 && (
          <a
            href={paginationUrl.concat('/', currentPage + 1)}
            className="p-2 border border-gray-200 hover:border-blue-50 rounded hover:bg-blue-50 text-gray-700 inline-block"
          >
            {t('businessProposal.next')}
          </a>
        )}
      </div>
    </div>
  );
};

export default Pagination;

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalResults: PropTypes.number,
  pageSize: PropTypes.number,
  paginationUrl: PropTypes.string.isRequired
};

Pagination.defaultProps = {
  totalResults: 0,
  pageSize: 0
};
