import React from 'react';
import * as PropTypes from 'prop-types';

const LightBoxButton = ({ direction }) => {
  return (
    <span className="bg-white text-gray-800 p-2 w-10 h-10 shadow rounded-full inline-flex items-center justify-center">
      <span className="material-icons">chevron_{direction}</span>
    </span>
  );
};

export default LightBoxButton;

LightBoxButton.propTypes = {
  direction: PropTypes.string.isRequired
};
