/* eslint-disable indent */
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import { useTranslation } from 'react-i18next';
import { renderUserAvatar } from '../../Util/data';
import Attachment from '../Attachment/Attachment';
import Loader from '../Loader/Loader';

const MessageBox = ({
  sentByCurrentUser,
  message,
  date,
  conversationUserName,
  logoUrl,
  attachments = [],
  isPending,
  connection,
  locale,
  acceptConnection,
  rejectConnection,
  isProposalUser,
  lastMessage,
  acceptingNudge,
  rejectingNudge
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-undef
  const currentLang = document.documentElement.lang;

  return (
    <div className="w-full mb-5">
      <div
        className={`
        flex
        flex-col
        ${sentByCurrentUser ? 'items-end' : 'items-start'}
      `}
      >
        <div
          className={`
            flex
            items-start
            justify-start
            gap-3
            ${isPending ? 'md:max-w-xl' : 'md:max-w-md'}
            ${sentByCurrentUser ? 'flex-row-reverse' : ''}
          `}
        >
          <div className="flex-grow min-w-max">
            {logoUrl ? (
              <img
                src={logoUrl}
                alt={conversationUserName.split(' ')[0]}
                className="w-12 h-12 rounded-full object-center object-cover"
              />
            ) : conversationUserName === 'System null' ? (
              <img
                src="/images/small/logo/ain_new-logo.png"
                className="w-12 h-12 rounded-full"
                alt="AIN"
              />
            ) : (
              renderUserAvatar(conversationUserName, null, null)
            )}
          </div>
          <div className="md:w-auto md:max-w-sm xl:max-w-md w-full ">
            {isPending && !isProposalUser ? (
              <>
                <div className="rounded-xl px-5 py-3 md:text-base text-sm bg-gray-200 text-gray-700">
                  {lastMessage ? (
                    <Markdown>{lastMessage.content}</Markdown>
                  ) : (
                    <>
                      <p>{t('auth.inbox.salutation')}</p>
                      <br />
                      <p>
                        <a
                          href={connection?.proposal?.proposalPageUri}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {connection?.proposal.title}
                        </a>
                        :
                      </p>
                      <br />
                      <p>{connection?.proposal.description}</p>
                    </>
                  )}
                  <br />
                  <table width="100%" border="0">
                    <tbody>
                      <tr>
                        <td>{t('auth.inbox.target')} :</td>
                        <td className="pl-4">
                          <strong>
                            {t('currency', {
                              val: connection?.proposal.maxInvestment,
                              formatParams: {
                                val: {
                                  currency: connection?.proposal.currencyCode,
                                  locale: locale?.replace(/_/g, '-'),
                                  maximumFractionDigits: 0
                                }
                              }
                            })}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>{t('auth.inbox.minimum')} :</td>
                        <td className="pl-4">
                          <strong>
                            {t('currency', {
                              val: connection?.proposal.minInvestment,
                              formatParams: {
                                val: {
                                  currency: connection?.proposal.currencyCode,
                                  locale: locale?.replace(/_/g, '-'),
                                  maximumFractionDigits: 0
                                }
                              }
                            })}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>{t('auth.inbox.investmentRaised')} :</td>
                        <td className="pl-4">
                          <strong>
                            {t('currency', {
                              val:
                                (connection.proposal.completePercentage / 100) *
                                connection.proposal.maxInvestment,
                              formatParams: {
                                val: {
                                  currency: connection?.proposal.currencyCode,
                                  locale: locale?.replace(/_/g, '-'),
                                  maximumFractionDigits: 0
                                }
                              }
                            })}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="my-5 w-full">
                  <p className="text-xs italic">
                    {t('auth.inbox.clickInterested').replace(
                      '%name%',
                      conversationUserName
                    )}
                  </p>
                  <div className="flex flex-wrap gap-2 mt-3 pb-4">
                    <button
                      type="button"
                      onClick={() => {
                        return acceptConnection(
                          connection?.connectionUserActions.acceptConnectionUri
                        );
                      }}
                      className="text-sm px-5 py-3 bg-blue-500 hover:bg-blue-600 text-white border border-blue-600 rounded-md"
                    >
                      {acceptingNudge ? (
                        <Loader title="Loading" />
                      ) : (
                        t('auth.inbox.interested')
                      )}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        return rejectConnection(
                          connection?.connectionUserActions.rejectConnectionUri
                        );
                      }}
                      className="text-sm px-5 py-3 bg-gray-200 hover:bg-gray-300 text-gray-600 border border-gray-400 rounded-md"
                    >
                      {rejectingNudge ? (
                        <Loader title="Loading" />
                      ) : (
                        t('auth.inbox.ignore')
                      )}
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Markdown
                  className={`
                    rounded-xl
                    px-5
                    py-3
                    ${
                      sentByCurrentUser
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-200 text-gray-700'
                    }
                    md:text-base
                    text-sm
                    break-words
                    message-box
                  `}
                >
                  {message}
                </Markdown>
                {attachments?.length > 0 &&
                  attachments?.map((attachment, index) => {
                    // eslint-disable-next-line react/no-array-index-key
                    return <Attachment key={index} attachment={attachment} />;
                  })}
                <span
                  className={`block mt-3 text-xs ${
                    sentByCurrentUser ? 'text-right' : ''
                  }`}
                >
                  {moment(date).locale(currentLang).fromNow()}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageBox;

MessageBox.propTypes = {
  sentByCurrentUser: PropTypes.bool.isRequired,
  message: PropTypes.string,
  date: PropTypes.string,
  conversationUserName: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  attachments: PropTypes.instanceOf(Object),
  isPending: PropTypes.bool,
  connection: PropTypes.instanceOf(Object),
  locale: PropTypes.string,
  acceptConnection: PropTypes.func,
  rejectConnection: PropTypes.func,
  isProposalUser: PropTypes.bool,
  lastMessage: PropTypes.instanceOf(Object),
  acceptingNudge: PropTypes.bool,
  rejectingNudge: PropTypes.bool
};

MessageBox.defaultProps = {
  logoUrl: null,
  attachments: null,
  isPending: false,
  connection: null,
  locale: null,
  acceptConnection: () => {},
  rejectConnection: () => {},
  isProposalUser: false,
  lastMessage: null,
  acceptingNudge: false,
  rejectingNudge: false,
  message: null,
  date: null
};
