import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ButtonGroup from './ButtonGroup';

const UrlForm = ({ url, updateUrl, clickedBack, modalConfirmed }) => {
  const { t } = useTranslation();
  const [invalidLink, setInvalidLink] = useState(false);

  const urlPatternValidation = (URL) => {
    const regex = /([\da-z.-]+)\.([a-z.]{2,6})[/\w .-]*\/?/;
    if (regex.test(URL)) {
      setInvalidLink(false);
      updateUrl(URL);
    } else {
      setInvalidLink(true);
      updateUrl(null);
    }
  };

  return (
    <div className="px-6 w-full flex flex-col gap-2">
      <label htmlFor="urlInput" className="text-gray-800 text-lg leading-6">
        {t('newsfeed.pageContent.addLink')}
      </label>
      <input
        autoComplete="off"
        onChange={(e) => {
          return urlPatternValidation(e.target.value);
        }}
        defaultValue={url}
        type="text"
        id="urlInput"
        placeholder="https://"
        className={`w-full border ${
          invalidLink ? 'border-red-300' : 'border-gray-300'
        } rounded-md p-3 text-gray-500 text-lg focus:outline-none`}
      />
      <ButtonGroup
        onBackClick={clickedBack}
        onConfirmationClick={modalConfirmed}
        presetValue={url}
      />
    </div>
  );
};

export default UrlForm;

UrlForm.propTypes = {
  url: PropTypes.string,
  updateUrl: PropTypes.func,
  clickedBack: PropTypes.func,
  modalConfirmed: PropTypes.func
};

UrlForm.defaultProps = {
  url: '',
  updateUrl: () => {},
  clickedBack: () => {},
  modalConfirmed: () => {}
};
