import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { getLastCharacter, renderUserAvatar } from '../../Util/data';

const AvatarCircle = ({
  image,
  number,
  fullName,
  investorDetailsUrl,
  userId
}) => {
  return (
    <div
      className={`
        inline-flex
        items-center
        justify-center
        rounded-full
        lg:w-12
        lg:h-12
        w-8
        h-8
        overflow-hidden
        ${number ? 'bg-blue-100' : 'bg-gray-200'}
        lg:ring-0
        ring-2
        ring-white
      `}
    >
      {number ? (
        <span className="text-blue-700 lg:text-base text-sm">+ {number}</span>
      ) : image ? (
        <a
          href={investorDetailsUrl}
          target="_blank"
          rel="noreferrer"
          className="inline-block w-full h-full"
        >
          <img className="w-full h-full" src={image} alt="avatar" />
        </a>
      ) : (
        <a
          href={investorDetailsUrl}
          target="_blank"
          rel="noreferrer"
          className="inline-block w-full h-full"
        >
          {renderUserAvatar(
            fullName,
            'w-full h-full',
            `bg-${getLastCharacter(userId)}`
          )}
        </a>
      )}
    </div>
  );
};

export default AvatarCircle;

AvatarCircle.propTypes = {
  image: PropTypes.string,
  number: PropTypes.number,
  fullName: PropTypes.string,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

AvatarCircle.defaultProps = {
  image: null,
  number: null,
  fullName: null,
  userId: 0
};
