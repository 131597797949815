import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnclickOutside from 'react-cool-onclickoutside';
import Loader from '../Loader/Loader';

const Modal = ({
  title,
  description,
  pitches,
  hideModal,
  hasNudges,
  submit,
  submitting,
  createProposalUri,
  nudgePurchaseUri,
  modalEventTarget,
  buttonUri,
  buttonText
}) => {
  const { t } = useTranslation();

  const [selectedPitchId, setSelectedPitchId] = useState('');
  const [additionalMessage, setAdditionalMessage] = useState('');

  const modalContent = useOnclickOutside(() => {
    hideModal();
  });

  const handleFormSubmit = (e) => {
    if (submitting) {
      return;
    }
    e.preventDefault();
    submit(selectedPitchId, additionalMessage);
  };

  useEffect(() => {
    if (pitches?.length) {
      setSelectedPitchId(pitches[0].proposalId);
    }
    if (!hasNudges && modalEventTarget === 'nudge') {
      // eslint-disable-next-line no-undef
      window.open(nudgePurchaseUri, '_blank');
      hideModal();
    }
    // eslint-disable-next-line no-undef
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        hideModal();
      }
    });
  }, []);
  return (
    <div
      tabIndex="-1"
      className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full md:h-screen justify-center items-center flex bg-white"
      role="dialog"
    >
      <button
        type="button"
        onClick={hideModal}
        className="absolute inline-flex items-center justify-center top-3 right-5 bg-blue-50 w-12 h-12 rounded-full group"
      >
        <span className="material-icons text-gray-500 group-hover:text-gray-600">
          close
        </span>
      </button>
      <div className="p-4 md:w-1/2 xl:w-1/3 max-w-full h-full md:h-auto m-auto md:mt-12 mt-14">
        <form onSubmit={handleFormSubmit}>
          <div
            ref={modalContent}
            className="flex flex-col items-center relative bg-white rounded-xl shadow border border-blue-100 lg:p-12 p-6"
          >
            {modalEventTarget === 'nudge' && hasNudges && pitches?.length ? (
              <>
                <h3 className="mb-2 text-ain-socials-gray font-medium text-center">
                  {title}
                </h3>
                <p className="text-center mb-5">{description}</p>
                <p className="mb-2">{t('nudgeModal.selectProposal')}</p>
                <select
                  className="mb-5 p-2 w-full border border-blue-100 rounded appearance-none border-slate-200"
                  onChange={(e) => {
                    return setSelectedPitchId(e.target.value);
                  }}
                  required
                >
                  {pitches.map((pitch) => {
                    return (
                      <option key={pitch.proposalId} value={pitch.proposalId}>
                        {pitch.title}
                      </option>
                    );
                  })}
                </select>
                <textarea
                  className="mb-5 px-3 py-2 w-full border border-blue-100 rounded"
                  placeholder={t('nudgeModal.startTyping')}
                  onChange={(e) => {
                    return setAdditionalMessage(e.target.value);
                  }}
                />
                <button
                  type="submit"
                  disabled={submitting}
                  className="w-full text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded px-8 py-4 text-center"
                >
                  {submitting ? (
                    <Loader title="submitting" />
                  ) : (
                    t('nudgeModal.send')
                  )}
                </button>
              </>
            ) : !hasNudges && modalEventTarget === 'nudge' ? (
              <svg
                className="animate-spin inline h-3.5 w-3.5 fill-current text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <title>Loading</title>
                <path
                  className="opacity-75"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            ) : modalEventTarget === 'dropdown' ? (
              <>
                <h3 className="mb-2 text-ain-socials-gray font-medium text-center">
                  {title}
                </h3>
                <p className="text-center mb-5">{description}</p>
                <a
                  href={buttonUri}
                  className="w-full text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded px-8 py-4 text-center"
                >
                  {buttonText}
                </a>
              </>
            ) : (
              <>
                <h3 className="mb-2 text-ain-socials-gray font-medium text-center">
                  {t('nudgeModal.nudgeInvestor')}
                </h3>
                <p className="text-center mb-5">{t('nudgeModal.needPitch')}</p>
                <a
                  href={createProposalUri}
                  className="w-full text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded px-8 py-4 text-center"
                >
                  {t('nudgeModal.addAPitch')}
                </a>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  pitches: PropTypes.instanceOf(Array),
  hideModal: PropTypes.func.isRequired,
  hasNudges: PropTypes.bool,
  submit: PropTypes.func,
  submitting: PropTypes.bool,
  createProposalUri: PropTypes.string,
  nudgePurchaseUri: PropTypes.string,
  modalEventTarget: PropTypes.oneOf(['nudge', 'dropdown']),
  buttonUri: PropTypes.string,
  buttonText: PropTypes.string
};

Modal.defaultProps = {
  description: '',
  pitches: [],
  hasNudges: false,
  submit: () => {},
  submitting: false,
  createProposalUri: '',
  nudgePurchaseUri: '',
  modalEventTarget: 'dropdown',
  buttonUri: '',
  buttonText: ''
};
