/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import * as PropTypes from 'prop-types';

const Checkbox = ({ title, value, category, onChange, isSelected }) => {
  return (
    <div className="w-full">
      <label className="flex items-center text-base text-gray-900 font-normal cursor-pointer">
        <input
          type="checkbox"
          name={category}
          className="
            flex-initial
            h-5
            w-5
            border-gray-300
            mr-1
          "
          value={value}
          onChange={onChange}
          checked={isSelected}
        />
        {title}
      </label>
    </div>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  category: PropTypes.string,
  onChange: PropTypes.func,
  isSelected: PropTypes.bool
};

Checkbox.defaultProps = {
  title: '',
  value: '',
  category: '',
  onChange: () => {},
  isSelected: false
};
