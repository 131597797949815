/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Nouislider from 'nouislider-react';
import wNumb from 'wnumb';
import { detectTranslateXval, generateRangeFromArray } from '../../Util/data';

const minInvestmentLimit = 1;

const MultiRangeSlider = ({
  onChange,
  locale,
  currencyCode,
  maxInvestmentLimit,
  isDefaultRange,
  investmentRange
}) => {
  const { t } = useTranslation();
  const range = generateRangeFromArray(investmentRange);

  const [values, setValues] = useState([
    minInvestmentLimit,
    maxInvestmentLimit
  ]);

  if (isDefaultRange && values[0] !== minInvestmentLimit) {
    setValues([minInvestmentLimit, maxInvestmentLimit]);
  }

  // eslint-disable-next-line no-shadow
  const updateValues = (values) => {
    setValues(values);
    onChange(values);
  };

  const updateTranslateValue = (element) => {
    const translateVal = parseFloat(detectTranslateXval(element));
    // somehow, noUiReact has translate values in 1000s, so converting it to 100s
    if (translateVal !== 0) {
      // eslint-disable-next-line no-param-reassign
      element.style.transform = `translate(${translateVal / 10}%, 0px)`;
    }
  };

  useEffect(() => {
    const leftThumb = document.getElementById('noui-react-left-thumb');
    const rightThumb = document.getElementById('noui-react-right-thumb');
    if (leftThumb) {
      updateTranslateValue(leftThumb);
      const rightThumbTranslateVal = parseFloat(
        detectTranslateXval(rightThumb)
      );
      if (rightThumbTranslateVal < 0) {
        updateTranslateValue(rightThumb);
      }
    }
  }, [values[0]]);

  useEffect(() => {
    const rightThumb = document.getElementById('noui-react-right-thumb');
    const leftThumb = document.getElementById('noui-react-left-thumb');
    if (rightThumb) {
      updateTranslateValue(rightThumb);
      const leftThumbTranslateVal = parseFloat(detectTranslateXval(leftThumb));
      if (leftThumbTranslateVal > 100 || leftThumbTranslateVal < -100) {
        updateTranslateValue(leftThumb);
      }
    }
  }, [values[1]]);

  useEffect(() => {
    const leftThumb = document.querySelectorAll('.noUi-origin')[0];
    const rightThumb = document.querySelectorAll('.noUi-origin')[1];
    leftThumb.setAttribute('id', 'noui-react-left-thumb');
    rightThumb.setAttribute('id', 'noui-react-right-thumb');
    updateTranslateValue(leftThumb);
    updateTranslateValue(rightThumb);
  }, []);

  return (
    <div className="relative pt-1 px-3">
      <p className="text-base">
        {t('businessProposal.showProjectsBetween')}&nbsp;
        <span className="text-blue-400">
          {t('currency', {
            val: values[0],
            formatParams: {
              val: {
                currency: currencyCode,
                locale: locale.replace(/_/g, '-'),
                maximumFractionDigits: 0
              }
            }
          })}
        </span>
        &nbsp;{t('businessProposal.and')}&nbsp;
        <span className="text-blue-400">
          {t('currency', {
            val: values[1],
            formatParams: {
              val: {
                currency: currencyCode,
                locale: locale.replace(/_/g, '-'),
                maximumFractionDigits: 0
              }
            }
          })}
        </span>
      </p>
      <div className="my-5" id="multirange-slider">
        <Nouislider
          start={[values[0], values[1]]}
          range={range}
          onUpdate={updateValues}
          snap
          connect
          format={wNumb({
            decimals: 0
          })}
        />
      </div>
    </div>
  );
};

export default MultiRangeSlider;

MultiRangeSlider.propTypes = {
  onChange: PropTypes.func,
  locale: PropTypes.string,
  currencyCode: PropTypes.string,
  maxInvestmentLimit: PropTypes.number,
  isDefaultRange: PropTypes.bool,
  investmentRange: PropTypes.instanceOf(Object)
};

MultiRangeSlider.defaultProps = {
  onChange: () => {},
  locale: '',
  currencyCode: '',
  maxInvestmentLimit: 0,
  isDefaultRange: false,
  investmentRange: null
};
