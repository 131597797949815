/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import OfferAdminModal from '../../Components/BenefitOfferAdmin/Modal';
import { postBenefitOffer } from '../../Util/ApiService';
import { displayToast } from '../../Util/Toast';
import { getLastCharacter } from '../../Util/data';

const BenefitOfferAdminModal = ({
  addBenefitOfferUri,
  preFetchedOffer,
  type,
  updateBenefitOfferUri,
  userAvatar,
  isAllSiteSelected,
  username,
  sites,
  userId
}) => {
  const [posting, setPosting] = useState(false);

  const postOffer = async (
    logo,
    banner,
    title,
    description,
    offerDetails,
    howToRedeem,
    link,
    terms,
    expiryDate,
    selectedSites
  ) => {
    setPosting(true);
    const formData = new FormData();
    if (typeof logo === 'string' && preFetchedOffer) {
      formData.append('logoUpdated', false);
    } else {
      logo && formData.append('logo', logo, logo.name);
      formData.append('logoUpdated', true);
    }
    if (typeof banner === 'string' && preFetchedOffer) {
      formData.append('bannerUpdated', false);
    } else {
      banner && formData.append('banner', banner, banner.name);
      formData.append('bannerUpdated', true);
    }
    title && formData.append('title', title);
    description && formData.append('description', description);
    offerDetails && formData.append('details', offerDetails);
    howToRedeem && formData.append('howToRedeem', howToRedeem);
    link && formData.append('redemptionLink', link);
    terms && formData.append('termsAndConditions', terms);
    expiryDate && formData.append('expiredDate', expiryDate);
    sites && formData.append('sitesCodes', JSON.stringify(selectedSites));

    if (type === 'add') {
      await postBenefitOffer(addBenefitOfferUri, formData).then(() => {
        setPosting(false);
        displayToast('Offer posted successfully!', 'success');
        // eslint-disable-next-line no-restricted-globals, no-undef
        return location.reload();
      });
    } else {
      await postBenefitOffer(updateBenefitOfferUri, formData).then(() => {
        setPosting(false);
        displayToast('Offer updated!', 'success');
        // eslint-disable-next-line no-restricted-globals, no-undef
        return location.reload();
      });
    }
  };
  return (
    <OfferAdminModal
      type={type}
      avatar={userAvatar}
      name={username}
      postOffer={postOffer}
      posting={posting}
      preFetchedOffer={preFetchedOffer}
      isAllSiteSelected={isAllSiteSelected}
      sites={sites}
      userId={getLastCharacter(userId)}
    />
  );
};

export default BenefitOfferAdminModal;

BenefitOfferAdminModal.propTypes = {
  addBenefitOfferUri: PropTypes.string,
  preFetchedOffer: PropTypes.string,
  type: PropTypes.oneOf(['add', 'edit']),
  updateBenefitOfferUri: PropTypes.string,
  userAvatar: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  sites: PropTypes.string,
  userId: PropTypes.number,
  isAllSiteSelected: PropTypes.bool
};

BenefitOfferAdminModal.defaultProps = {
  addBenefitOfferUri: '',
  preFetchedOffer: null,
  type: 'add',
  updateBenefitOfferUri: '',
  sites: null,
  userId: 0,
  isAllSiteSelected: false
};
