import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';
import Plyr from 'plyr-react';

const VideoPlayer = ({ videoUrl, videoSource }) => {
  const ref = useRef();

  return (
    <div className="group w-full bg-gray-200 h-auto relative">
      <Plyr
        ref={ref}
        source={{
          type: 'video',
          sources: [
            {
              src: videoUrl.split('?')[0],
              provider: videoSource
            }
          ]
        }}
        options={{
          playsinline: false,
          controls: [
            'play',
            'play-large',
            'progress',
            'mute',
            'volume',
            'settings',
            'fullscreen'
          ],
          ratio: '16:9'
        }}
      />
    </div>
  );
};

export default VideoPlayer;

VideoPlayer.propTypes = {
  videoSource: PropTypes.oneOf(['html5', 'vimeo', 'youtube']).isRequired,
  videoUrl: PropTypes.string.isRequired
};
