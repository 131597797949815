import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';

const BannerInput = ({ handleBannerChange, removeImage, prefetchedBanner }) => {
  const [bannerImage, setBannerImage] = useState(null);
  const bannerInput = useRef();

  const handleFileInput = (event) => {
    setBannerImage(URL.createObjectURL(event.target.files[0]));
    handleBannerChange(event.target.files[0]);
  };

  const deleteImage = () => {
    setBannerImage(null);
    removeImage();
  };

  const triggerBannerInput = () => {
    bannerInput?.current?.click();
  };

  useEffect(() => {
    if (prefetchedBanner) {
      if (typeof prefetchedBanner === 'string') {
        setBannerImage(prefetchedBanner);
      } else {
        setBannerImage(URL.createObjectURL(prefetchedBanner));
      }
    }
  }, [prefetchedBanner]);

  return (
    <div
      className={`
        relative
        h-44
        py-12
        px-16
        bg-gray-50
        flex
        flex-col
        items-center
        justify-center
        gap-2
        rounded-md
        bg-no-repeat
        bg-cover
        bg-center
        ${bannerImage ? 'border-0' : 'border-2'}
        border-dashed
        border-gray-400
      `}
      style={bannerImage ? { backgroundImage: `url(${bannerImage})` } : {}}
    >
      {bannerImage ? (
        <button
          type="button"
          className="absolute top-4 right-4 bg-gray-200 shadow-md text-gray-800 rounded-md p-2 banner-delete inline-flex items-center justify-center"
          onClick={deleteImage}
        >
          <span className="material-icons">delete</span>
        </button>
      ) : (
        <div className="flex flex-col gap-2">
          <button
            type="button"
            className="bg-gray-200 text-gray-800 rounded-md py-2 px-4 text-base font-semibold upload-banner"
            onClick={triggerBannerInput}
          >
            Upload an image
          </button>
          <input
            ref={bannerInput}
            onChange={handleFileInput}
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            className="hidden"
          />
        </div>
      )}
    </div>
  );
};

export default BannerInput;

BannerInput.propTypes = {
  handleBannerChange: PropTypes.func.isRequired,
  prefetchedBanner: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object)
  ]),
  removeImage: PropTypes.func
};

BannerInput.defaultProps = {
  prefetchedBanner: null,
  removeImage: () => {}
};
