/* eslint-disable indent */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import Markdown from 'markdown-to-jsx';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';
import { renderUserAvatar } from '../../Util/data';
import { connectionStatusCodes } from '../../Util/connectionStatuses';
import Dot from '../Dot/Dot';

const MessageList = ({
  image,
  lastUpdated,
  lastMessage,
  userName,
  proposalTitle,
  isSelected,
  isRead,
  changeStatus,
  setAsArchived,
  isArchived,
  restoreConnection,
  isProposalUser,
  status,
  connectionId,
  updatedReadStatus,
  userIdLastChar
}) => {
  const [formattedName, setFormattedName] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [threadAlreadyRead, setThreadReadStatus] = useState(isRead);

  const { t } = useTranslation();

  const ref = useOnclickOutside(() => {
    setShowDropdown(false);
  });

  // eslint-disable-next-line no-undef
  const currentLang = document.documentElement.lang;

  const updateReadStatus = (value) => {
    changeStatus(value);
    setShowDropdown(false);
  };

  const restore = () => {
    restoreConnection();
    setShowDropdown(false);
  };

  const archiveConnection = () => {
    setAsArchived();
    setShowDropdown(false);
  };

  useEffect(() => {
    if (isSelected) {
      // eslint-disable-next-line no-unused-expressions
      updatedReadStatus
        ? setThreadReadStatus(true)
        : setThreadReadStatus(false);
    }
  }, [updatedReadStatus, connectionId, isSelected]);

  useEffect(() => {
    setThreadReadStatus(isRead);
  }, [isRead]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const infiniteScrollComponent = document.querySelector(
      '.infinite-scroll-component'
    );
    // eslint-disable-next-line no-unused-expressions
    showDropdown
      ? (infiniteScrollComponent.style.overflow = 'inherit')
      : (infiniteScrollComponent.style.overflow = 'auto');
  }, [showDropdown]);

  useEffect(() => {
    if (userName?.trim().split(/\s+/).length > 1) {
      setFormattedName(userName?.split(' ')[0]);
    } else {
      setFormattedName(userName);
    }
  }, []);

  return (
    <div className="w-full md:px-3 bg-white" role="button">
      <div
        className={`flex items-start md:justify-start justify-center py-4 px-3 gap-3 rounded-lg ${
          isSelected ? 'group bg-blue-50' : 'bg-white'
        }`}
      >
        <div className="w-12 h-12">
          {image ? (
            <img
              src={image}
              alt={formattedName}
              className="w-full h-full object-cover object-center rounded-full"
            />
          ) : (
            renderUserAvatar(userName, 'w-12 h-12', `bg-${userIdLastChar}`)
          )}
        </div>
        <div className="flex flex-col justify-start w-auto flex-grow">
          <div className="inline-flex items-start justify-between">
            <div className="inline-flex gap-2 items-center">
              <p
                className={`text-gray-800 text-base ${
                  threadAlreadyRead || isSelected ? '' : 'font-semibold'
                }`}
              >
                {formattedName}
              </p>
              {status === connectionStatusCodes.pendingAcceptance &&
                !isProposalUser && (
                  <>
                    <Dot />
                    <span
                      className={`text-gray-700 capitalize leading-6 font-semibold text-sm px-2 rounded whitespace-nowrap ${
                        isSelected ? 'bg-slate-100' : 'bg-gray-100'
                      }`}
                    >
                      {t('auth.inbox.connectionRequest')}
                    </span>
                  </>
                )}
            </div>
            <span
              className={`inline-block text-sm text-gray-700 text-right w-1/3 ${
                threadAlreadyRead || isSelected ? '' : 'font-semibold'
              }`}
            >
              {lastUpdated &&
                moment(lastUpdated).locale(currentLang).format('D MMM')}
            </span>
          </div>
          <div className="flex items-start gap-2">
            <div className="w-10/12">
              <p
                className={`w-3/4 text-gray-700 text-sm whitespace-nowrap truncate ${
                  threadAlreadyRead || isSelected ? '' : 'font-semibold'
                }`}
              >
                {proposalTitle}
              </p>
              {lastMessage ? (
                <Markdown
                  className={`message-preview inline-flex items-center text-sm text-gray-700 gap-1 ${
                    threadAlreadyRead || isSelected ? '' : 'font-semibold'
                  }`}
                >
                  {lastMessage.length > 20
                    ? `${lastMessage.replaceAll('<br />', '').slice(0, 20)} ...`
                    : lastMessage}
                </Markdown>
              ) : (
                <div className="message-preview inline-flex items-center text-sm text-gray-700 gap-1">
                  <p />
                </div>
              )}
            </div>
            <div className="w-2/12 inline-flex flex-col items-end gap-y-1">
              {!threadAlreadyRead && (
                <div className="w-3 h-3 rounded-full bg-blue-600" />
              )}
              <div className="relative">
                <button
                  type="button"
                  className={`
                    inline-flex
                    items-center
                    justify-center
                    w-7
                    h-7
                    rounded
                    border
                    border-gray-50
                    shadow-sm
                    bg-white
                    sidebar-drop-btn
                    ${showDropdown ? '' : 'group-hover:block hidden'}
                  `}
                  onClick={() => {
                    return setShowDropdown((value) => !value);
                  }}
                  ref={ref}
                >
                  <span className="material-icons text-lg text-gray-600">
                    more_horiz
                  </span>
                </button>
                {showDropdown && (
                  <div
                    ref={ref}
                    className="
                      absolute
                      z-20
                      bg-white
                      border
                      border-gray-200
                      shadow-md
                      right-0
                      p-2
                      rounded-lg
                      message-options
                      mt-1
                    "
                  >
                    <button
                      type="button"
                      className="
                        inline-flex
                        gap-2
                        items-center
                        whitespace-nowrap
                        text-base
                        w-full
                        py-2
                        px-4
                        hover:bg-gray-50
                      "
                      onClick={() => {
                        return threadAlreadyRead
                          ? updateReadStatus(false)
                          : updateReadStatus(true);
                      }}
                    >
                      <span className="material-icons">done</span>
                      {threadAlreadyRead
                        ? t('auth.inbox.markAsUnread')
                        : t('auth.inbox.markAsRead')}
                    </button>
                    {isArchived ? (
                      <button
                        type="button"
                        className="
                          inline-flex
                          gap-2
                          items-center
                          whitespace-nowrap
                          text-base
                          w-full
                          py-2
                          px-4
                          hover:bg-gray-50
                        "
                        onClick={restore}
                      >
                        <span className="material-icons">restore</span>
                        {t('auth.inbox.restore')}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="
                          inline-flex
                          gap-2
                          items-center
                          whitespace-nowrap
                          text-base
                          w-full
                          py-2
                          px-4
                          hover:bg-gray-50
                        "
                        onClick={archiveConnection}
                      >
                        <span className="material-icons">delete</span>
                        {t('auth.inbox.delete')}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageList;

MessageList.propTypes = {
  image: PropTypes.string,
  lastUpdated: PropTypes.string,
  lastMessage: PropTypes.string,
  userName: PropTypes.string,
  proposalTitle: PropTypes.string,
  isSelected: PropTypes.bool,
  isRead: PropTypes.bool,
  connectionId: PropTypes.number,
  isArchived: PropTypes.bool,
  setAsArchived: PropTypes.func,
  restoreConnection: PropTypes.func,
  changeStatus: PropTypes.func,
  isProposalUser: PropTypes.bool,
  status: PropTypes.string,
  updatedReadStatus: PropTypes.bool,
  userIdLastChar: PropTypes.string
};

MessageList.defaultProps = {
  image: '',
  lastUpdated: '',
  lastMessage: '',
  userName: '',
  proposalTitle: '',
  isSelected: false,
  isRead: false,
  connectionId: 0,
  changeStatus: () => {},
  setAsArchived: () => {},
  isArchived: false,
  restoreConnection: () => {},
  isProposalUser: false,
  status: '',
  updatedReadStatus: false,
  userIdLastChar: '0'
};
