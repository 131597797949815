import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  fetchDashoardData,
  fetchUsers,
  fetchConnectionsReport
} from '../../Util/ApiService';
import Chart from '../../Components/Chart/Chart';
import Select from '../../Components/Select/Select';
import { chartColors } from '../../Util/data';

const months = [
  {
    name: 'January',
    code: 1
  },
  {
    name: 'February',
    code: 2
  },
  {
    name: 'March',
    code: 3
  },
  {
    name: 'April',
    code: 4
  },
  {
    name: 'May',
    code: 5
  },
  {
    name: 'June',
    code: 6
  },
  {
    name: 'July',
    code: 7
  },
  {
    name: 'August',
    code: 8
  },
  {
    name: 'September',
    code: 9
  },
  {
    name: 'October',
    code: 10
  },
  {
    name: 'November',
    code: 11
  },
  {
    name: 'December',
    code: 12
  }
];

const Admin = ({
  revenueUrl,
  userSignupApiUrl,
  recurringPaymentReportApiUrl,
  orderReportsApiUrl,
  connectionsApiUrl
}) => {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();
  const initialYear = 2004; // AIN founding year
  const [entrepreneurData, setEntrepreneurData] = useState([]);
  const [investorData, setInvestorData] = useState([]);
  const [projection, setProjection] = useState({});
  const [revenue, setRevenue] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Adding 1 because months start from 0 in JS
  const [selectedMonthForUsers, setSelectedMonthForUsers] = useState(
    new Date().getMonth() + 1
  );

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [
    selectedYearForConnectionsReport,
    setSelectedYearForConnectionsReport
  ] = useState(currentYear);
  const [selectedYearForUsers] = useState(currentYear); // FIXME - we might need to add an option to select year if needed
  const [numberOfDaysInSelectedMonth, setNumberOfDaysInSelectedMonth] =
    useState(
      moment(
        new Date(`${selectedYearForUsers}-${selectedMonthForUsers}`)
      ).daysInMonth()
    );
  const [selectedDayForUsers, setSelectedDayForUsers] = useState(1);
  const [daysInSelectedMonth, setDaysInSelectedMonth] = useState([]);
  const [labelsForUsers, setLabelsForUsers] = useState([]);
  const [fetchingRevenue, setFetchingRevenue] = useState(true);
  const [fetchingUsers, setFetchingUsers] = useState(true);

  const [connectionsDocumentDownload, setConnectionsDocumentDownload] =
    useState([]);
  const [connectionsDocumentRequest, setConnectionsDocumentRequest] = useState(
    []
  );
  const [connectionsGenuineInterest, setConnectionsGenuineInterest] = useState(
    []
  );
  const [connectionsInternalMessage, setConnectionsInternalMessage] = useState(
    []
  );
  const [connectionsNudge, setConnectionsNudge] = useState([]);
  const [connectionsQuestion, setConnectionsQuestion] = useState([]);
  const [connectionsRequestACall, setConnectionsRequestACall] = useState([]);
  const [connectionsAll, setConnectionsAll] = useState([]);

  const [discountCanceledData, setDiscountCanceledData] = useState([]);
  const [discountClaimedData, setDiscountClaimedData] = useState([]);
  const [discountUptakeData, setDiscountUptakeData] = useState([]);
  const [selectedDayForRecurringReport, setSelectedDayForRecurringReport] =
    useState(1);
  const [selectedMonthForRecurringReport, setSelectedMonthForRecurringReport] =
    useState(new Date().getMonth() + 1); // Adding 1 because months start from 0 in JS
  const [
    selectedMonthForConnectionReport,
    setSelectedMonthForConnectionReport
  ] = useState(new Date().getMonth() + 1); // Adding 1 because months start from 0 in JS
  const [selectedYarForRecurringReport] = useState(currentYear);
  const [labelsForRecurringReport, setLabelsForRecurringReport] = useState([]);
  const [labelsForConnectionsReport, setLabelsForConnetionsReport] = useState(
    []
  );
  const [numberOfDaysForRecurringPayment, setNumberOfDaysForRecurringReport] =
    useState(
      moment(
        new Date(
          `${selectedYarForRecurringReport}-${selectedMonthForRecurringReport}`
        )
      ).daysInMonth()
    );
  const [
    daysInSelectedMonthForRecurringPayment,
    setDaysInSelectedMonthForRecurringPayment
  ] = useState([]);
  const [fetchingRecurringReport, setFetchingRecurringReport] = useState(true);
  const [fetchingConnectionReport, setFetchingConnectionReport] =
    useState(true);

  const [selectedMonthForSales, setSelectedMonthForSales] = useState(
    new Date().getMonth() + 1
  ); // Adding 1 because months start from 0 in JS
  const [selectedYearForSales, setSelectedYearForSales] = useState(currentYear);
  const [labelsForSales, setLabelsForSales] = useState([]);
  const [countsForSales, setCountsForSales] = useState([]);
  const [totalValuesForSales, setTotalValuesForSales] = useState([]);
  const [totalPaymentsForSales, setTotalPaymentsForSales] = useState([]);
  const [totalDiscountsForSales, setTotalDiscountsForSales] = useState([]);
  const [totalRefundsForSales, setTotalRefundsForSales] = useState([]);
  const [fetchingSales, setFetchingSales] = useState(true);

  const fetchRevenue = async () => {
    setFetchingRevenue(true);
    const data = await fetchDashoardData(
      `${revenueUrl}/${selectedMonth}/${selectedYear}`
    );
    setRevenue(data?.revenue);
    setProjection(data?.projection);
    setFetchingRevenue(false);
  };

  const fetchUserData = async () => {
    setFetchingUsers(true);
    const entrepreneurResponse = await fetchUsers(userSignupApiUrl, {
      day: parseInt(selectedDayForUsers, 10),
      month: parseInt(selectedMonthForUsers, 10),
      userType: 'EN'
    });
    const investorResponse = await fetchUsers(userSignupApiUrl, {
      day: parseInt(selectedDayForUsers, 10),
      month: parseInt(selectedMonthForUsers, 10),
      userType: 'IN'
    });

    // console.log(entrepreneurResponse);
    const dates = [];
    const entrepreneurNumberList = [];
    const investorNumberList = [];

    entrepreneurResponse?.forEach((response) => {
      dates?.push(response?.date);
      entrepreneurNumberList?.push(response?.amount);
    });

    investorResponse?.forEach((response) => {
      return investorNumberList?.push(response?.amount);
    });

    setLabelsForUsers(dates);
    setEntrepreneurData(entrepreneurNumberList);
    setInvestorData(investorNumberList);
    setFetchingUsers(false);
  };

  const fetchRecurringReport = async () => {
    setFetchingRecurringReport(true);
    const response = await fetchUsers(recurringPaymentReportApiUrl, {
      day: parseInt(selectedDayForRecurringReport, 10),
      month: parseInt(selectedMonthForRecurringReport, 10)
    });

    const dates = [];
    const discountCanceledList = [];
    const discountClaimedList = [];
    const discountUptakeList = [];

    response?.forEach((data) => {
      dates?.push(data?.date);
      discountCanceledList?.push(data?.discountCanceled);
      discountClaimedList?.push(data?.discountClaimed);
      discountUptakeList?.push(data?.discountUptakePercentage);
    });

    setLabelsForRecurringReport(dates);
    setDiscountCanceledData(discountCanceledList);
    setDiscountClaimedData(discountClaimedList);
    setDiscountUptakeData(discountUptakeList);
    setFetchingRecurringReport(false);
  };

  const fetchSales = async () => {
    setFetchingSales(true);
    const response = await fetchDashoardData(
      `${orderReportsApiUrl}?month=${parseInt(
        selectedMonthForSales,
        10
      )}&year=${parseInt(selectedYearForSales, 10)}`
    );

    const data = Object.values(response);

    const dates = [];
    const counts = [];
    const totalValues = [];
    const totalPayments = [];
    const totalDiscounts = [];
    const totalRefunds = [];

    data?.forEach((object) => {
      dates?.push(object?.date);
      counts?.push(object?.count);
      totalValues?.push(object?.totalValue);
      totalPayments?.push(object?.totalPayment);
      totalDiscounts?.push(object?.totalDiscount);
      totalRefunds?.push(object?.totalRefund);
    });

    setLabelsForSales(dates);
    setCountsForSales(counts);
    setTotalValuesForSales(totalValues);
    setTotalPaymentsForSales(totalPayments);
    setTotalDiscountsForSales(totalDiscounts);
    setTotalRefundsForSales(totalRefunds);
    setFetchingSales(false);
  };

  const fetchConnections = async () => {
    setFetchingConnectionReport(true);
    const response = await fetchConnectionsReport(connectionsApiUrl, {
      month: parseInt(selectedMonthForConnectionReport, 10),
      year: parseInt(selectedYearForConnectionsReport, 10)
    });

    const dates = [];
    const documentDownload = [];
    const documentRequest = [];
    const genuineInterest = [];
    const internalMessage = [];
    const nudge = [];
    const question = [];
    const requestACall = [];
    const all = [];

    response?.forEach((data) => {
      dates?.push(data?.date);
      documentDownload?.push(data?.documentDownload);
      documentRequest?.push(data?.documentRequest);
      genuineInterest.push(data?.genuineInterest);
      internalMessage.push(data?.internalMessage);
      nudge.push(data?.nudge);
      question.push(data?.question);
      requestACall.push(data?.requestACall);
      all.push(data?.all);
    });

    setLabelsForConnetionsReport(dates);
    setConnectionsDocumentDownload(documentDownload);
    setConnectionsDocumentRequest(documentRequest);
    setConnectionsGenuineInterest(genuineInterest);
    setConnectionsInternalMessage(internalMessage);
    setConnectionsNudge(nudge);
    setConnectionsQuestion(question);
    setConnectionsRequestACall(requestACall);
    setConnectionsAll(all);
    setFetchingConnectionReport(false);
  };

  const handleInput = (e) => {
    setSelectedYear(parseInt(e?.target?.value, 10));
  };

  const handleConnectionInput = (e) => {
    setSelectedYearForConnectionsReport(parseInt(e?.target?.value, 10));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await fetchRevenue();
  };

  const handleConnectionSubmit = async (event) => {
    event.preventDefault();
    await fetchConnections();
  };

  const handleSalesFormSubmit = async (event) => {
    event.preventDefault();
    await fetchSales();
  };

  const renderLoadingOverlay = () => {
    return (
      <div className="absolute inset-0 w-full h-full bg-gray-200 bg-opacity-50">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <span className="font-semibold text-gray-800 text-xl">
            Loading...
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    fetchRevenue();
  }, [selectedMonth]);

  useEffect(() => {
    fetchUserData();
    setNumberOfDaysInSelectedMonth(
      moment(
        new Date(`${selectedYearForUsers}-${selectedMonthForUsers}`)
      ).daysInMonth()
    );
  }, [selectedMonthForUsers, selectedDayForUsers]);

  useEffect(() => {
    fetchRecurringReport();
    setNumberOfDaysForRecurringReport(
      moment(
        new Date(
          `${selectedYarForRecurringReport}-${selectedMonthForRecurringReport}`
        )
      ).daysInMonth()
    );
  }, [selectedMonthForRecurringReport, selectedDayForRecurringReport]);

  useEffect(() => {
    fetchSales();
  }, [selectedMonthForSales]);

  useEffect(() => {
    setDaysInSelectedMonth(
      [...Array(numberOfDaysInSelectedMonth).keys()].map((i) => {
        return {
          name: i + 1,
          code: i + 1
        };
      })
    );
    setDaysInSelectedMonthForRecurringPayment(
      [...Array(numberOfDaysForRecurringPayment).keys()].map((i) => {
        return {
          name: i + 1,
          code: i + 1
        };
      })
    );
  }, [numberOfDaysInSelectedMonth, numberOfDaysForRecurringPayment]);

  useEffect(() => {
    fetchConnections();
  }, [selectedMonthForConnectionReport, selectedYearForConnectionsReport]);

  return (
    <div className="w-full py-16 flex flex-col gap-6">
      <div className="w-full border border-legacy-border rounded-md">
        <div className="bg-gray-100 p-4 w-full flex flex-wrap gap-3 items-center justify-between">
          <div className="inline-flex items-center gap-2">
            <span className="material-icons">show_chart</span>
            <p className="text-base text-gray-800">
              Total Monthly Revenue Graph
            </p>
          </div>
          <div className="flex items-center">
            <form onSubmit={handleSubmit}>
              <Select
                options={months}
                selected={selectedMonth}
                onChange={(e) => {
                  setSelectedMonth(e);
                }}
                isSimple
              />
              <input
                onInput={(e) => {
                  return handleInput(e);
                }}
                type="number"
                max={currentYear}
                min={initialYear}
                defaultValue={currentYear}
                className="focus:outline-none bg-transparent text-base text-gray-800"
              />
            </form>
          </div>
        </div>
        <div
          className={`w-full px-4 pb-8 relative ${
            fetchingRevenue ? 'filter grayscale' : ''
          }`}
        >
          {fetchingRevenue && renderLoadingOverlay()}
          <Chart
            data={revenue}
            loading={fetchingUsers}
            datasets={[
              {
                label: 'Revenue',
                data: revenue?.total,
                borderColor: chartColors.chartDarkBlue,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartLighterBlue,
                pointRadius: 6
              }
            ]}
          />
        </div>
        <div className="px-4 w-full grid md:grid-cols-2 grid-cols-1 text-center mt-5">
          <div className="w-full">
            <p className="text-lg font-semibold text-gray-600">
              Completed Days
            </p>
            <div className="w-full grid md:grid-cols-3 grid-cols-1 py-3">
              <div className="w-full">
                <p className="text-gray-700">
                  <strong>Total Revenue</strong>
                </p>
                <span>
                  {t('currency', {
                    val: projection?.completed_total_revenue,
                    formatParams: {
                      val: {
                        currency: 'GBP',
                        locale: 'en-GB',
                        maximumFractionDigits: 0
                      }
                    }
                  })}
                </span>
              </div>
              <div className="w-full">
                <p className="text-gray-700">
                  <strong>Daily Average</strong>
                </p>
                <span>
                  {t('currency', {
                    val: projection?.completed_daily_average,
                    formatParams: {
                      val: {
                        currency: 'GBP',
                        locale: 'en-GB',
                        maximumFractionDigits: 0
                      }
                    }
                  })}
                </span>
              </div>
              <div className="w-full">
                <p className="text-gray-700">
                  <strong>Projection</strong>
                </p>
                <span>
                  {t('currency', {
                    val: projection?.completed_projection,
                    formatParams: {
                      val: {
                        currency: 'GBP',
                        locale: 'en-GB',
                        maximumFractionDigits: 0
                      }
                    }
                  })}
                </span>
              </div>
            </div>
          </div>
          <div className="w-full">
            <p className="text-lg font-semibold text-gray-600">
              Including Today
            </p>
            <div className="w-full grid md:grid-cols-3 grid-cols-1 py-3">
              <div className="w-full">
                <p className="text-gray-700">
                  <strong>Total Revenue</strong>
                </p>
                <span>
                  {t('currency', {
                    val: projection?.including_total_revenue,
                    formatParams: {
                      val: {
                        currency: 'GBP',
                        locale: 'en-GB',
                        maximumFractionDigits: 0
                      }
                    }
                  })}
                </span>
              </div>
              <div className="w-full">
                <p className="text-gray-700">
                  <strong>Daily Average</strong>
                </p>
                <span>
                  {t('currency', {
                    val: projection?.including_daily_average,
                    formatParams: {
                      val: {
                        currency: 'GBP',
                        locale: 'en-GB',
                        maximumFractionDigits: 0
                      }
                    }
                  })}
                </span>
              </div>
              <div className="w-full">
                <p className="text-gray-700">
                  <strong>Projection</strong>
                </p>
                <span>
                  {t('currency', {
                    val: projection?.including_projection,
                    formatParams: {
                      val: {
                        currency: 'GBP',
                        locale: 'en-GB',
                        maximumFractionDigits: 0
                      }
                    }
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full border border-legacy-border rounded-md">
        <div className="bg-gray-100 p-4 w-full flex flex-wrap gap-3 items-center justify-between">
          <div className="inline-flex items-center gap-2">
            <span className="material-icons">show_chart</span>
            <p className="text-base text-gray-800">Symfony Sales (BETA)</p>
          </div>
          <div className="flex items-center">
            <form onSubmit={handleSalesFormSubmit}>
              <Select
                options={months}
                selected={selectedMonthForSales}
                onChange={(e) => {
                  setSelectedMonthForSales(e);
                }}
                isSimple
              />
              <input
                onInput={(e) => {
                  return setSelectedYearForSales(
                    parseInt(e?.target?.value, 10)
                  );
                }}
                type="number"
                max={currentYear}
                min={initialYear}
                defaultValue={currentYear}
                className="focus:outline-none bg-transparent text-base text-gray-800"
              />
            </form>
          </div>
        </div>
        <div
          className={`w-full px-4 pb-8 relative ${
            fetchingSales ? 'filter grayscale' : ''
          }`}
        >
          {fetchingSales && renderLoadingOverlay()}
          <Chart
            preFetchedLabels={labelsForSales}
            datasets={[
              {
                label: 'Count',
                data: countsForSales,
                borderColor: chartColors.chartDarkBlue,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartLighterBlue,
                pointRadius: 6
              },
              {
                label: 'Total Values',
                data: totalValuesForSales,
                borderColor: chartColors.chartLighterGreen,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartLighterGreenB,
                pointRadius: 6
              },
              {
                label: 'Total Payments',
                data: totalPaymentsForSales,
                borderColor: chartColors.chartPurple,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartLighterPurple,
                pointRadius: 6
              },
              {
                label: 'Total Discounts',
                data: totalDiscountsForSales,
                borderColor: chartColors.chartPink,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartLighterPink,
                pointRadius: 6
              },
              {
                label: 'Total Refunds',
                data: totalRefundsForSales,
                borderColor: chartColors.chartRed,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartLighterRed,
                pointRadius: 6
              }
            ]}
          />
        </div>
      </div>

      <div className="w-full border border-legacy-border rounded-md">
        <div className="bg-gray-100 p-4 w-full flex flex-wrap gap-3 items-center justify-between">
          <div className="inline-flex items-center gap-2">
            <span className="material-icons">show_chart</span>
            <p className="text-base text-gray-800">
              Entrepreneurs vs Investors Registration Graph
            </p>
          </div>
          <div className="flex items-center">
            From{' '}
            <Select
              options={daysInSelectedMonth}
              selected={selectedDayForUsers}
              onChange={(e) => {
                setSelectedDayForUsers(e);
              }}
              isSimple
            />
            <Select
              options={months}
              selected={selectedMonthForUsers}
              onChange={(e) => {
                setSelectedMonthForUsers(e);
              }}
              isSimple
            />
          </div>
        </div>
        <div
          className={`w-full px-4 pb-8 relative ${
            fetchingUsers ? 'filter grayscale' : ''
          }`}
        >
          {fetchingUsers && renderLoadingOverlay()}
          <Chart
            preFetchedLabels={labelsForUsers}
            datasets={[
              {
                label: 'Entrepreneurs',
                data: entrepreneurData,
                borderColor: chartColors.chartDarkBlue,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartLighterBlue,
                pointRadius: 6
              },
              {
                label: 'Investors',
                data: investorData,
                borderColor: chartColors.chartLighterGreen,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartLighterGreenB,
                pointRadius: 6
              }
            ]}
          />
        </div>
      </div>

      <div className="w-full border border-legacy-border rounded-md">
        <div className="bg-gray-100 p-4 w-full flex flex-wrap gap-3 items-center justify-between">
          <div className="inline-flex items-center gap-2">
            <span className="material-icons">show_chart</span>
            <p className="text-base text-gray-800">
              Discount Claim vs Rejection Graph
            </p>
          </div>
          <div className="flex items-center">
            From{' '}
            <Select
              options={daysInSelectedMonthForRecurringPayment}
              selected={selectedDayForRecurringReport}
              onChange={(e) => {
                setSelectedDayForRecurringReport(e);
              }}
              isSimple
            />
            <Select
              options={months}
              selected={selectedMonthForRecurringReport}
              onChange={(e) => {
                setSelectedMonthForRecurringReport(e);
              }}
              isSimple
            />
          </div>
        </div>
        <div
          className={`w-full px-4 pb-8 relative ${
            fetchingRecurringReport ? 'filter grayscale' : ''
          }`}
        >
          {fetchingRecurringReport && renderLoadingOverlay()}
          <Chart
            preFetchedLabels={labelsForRecurringReport}
            datasets={[
              {
                label: 'Discount Claimed',
                data: discountClaimedData,
                borderColor: chartColors.chartDarkBlue,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartLighterBlue,
                pointRadius: 6
              },
              {
                label: 'Canceled Subscriptions',
                data: discountCanceledData,
                borderColor: chartColors.chartLighterGreen,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartLighterGreenB,
                pointRadius: 6
              },
              {
                label: 'Discount Uptake Percentage',
                data: discountUptakeData,
                borderColor: chartColors.chartPurple,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartLighterPurple,
                pointRadius: 6
              }
            ]}
          />
        </div>
        <div className="bg-gray-100 p-4 w-full flex flex-wrap gap-3 items-center justify-between">
          <div className="inline-flex items-center gap-2">
            <span className="material-icons">show_chart</span>
            <p className="text-base text-gray-800">Created Connections</p>
          </div>
          <div className="flex items-center">
            <Select
              options={months}
              selected={selectedMonthForConnectionReport}
              onChange={(e) => {
                setSelectedMonthForConnectionReport(e);
              }}
              isSimple
            />
            <input
              onInput={(e) => {
                return handleConnectionInput(e);
              }}
              type="number"
              max={currentYear}
              min={initialYear}
              defaultValue={currentYear}
              className="focus:outline-none bg-transparent text-base text-gray-800"
            />
          </div>
        </div>
        <div
          className={`w-full px-4 pb-8 relative ${
            fetchingConnectionReport ? 'filter grayscale' : ''
          }`}
        >
          {fetchingConnectionReport && renderLoadingOverlay()}
          <Chart
            preFetchedLabels={labelsForConnectionsReport}
            data={connectionsDocumentDownload}
            datasets={[
              {
                label: 'All connections',
                data: connectionsAll,
                borderColor: chartColors.chartRed,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartRed,
                pointRadius: 6
              },
              {
                label: 'Document Download',
                data: connectionsDocumentDownload,
                borderColor: chartColors.chartDarkBlue,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartDarkBlue,
                pointRadius: 6,
                hidden: true
              },
              {
                label: 'Document Request',
                data: connectionsDocumentRequest,
                borderColor: chartColors.chartLighterGreenB,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartLighterGreenB,
                pointRadius: 6,
                hidden: true
              },
              {
                label: 'Genuine Interest',
                data: connectionsGenuineInterest,
                borderColor: chartColors.chartPurple,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartPurple,
                pointRadius: 6,
                hidden: true
              },
              {
                label: 'Internal message',
                data: connectionsInternalMessage,
                borderColor: chartColors.chartLighterRed,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartLighterRed,
                pointRadius: 6,
                hidden: true
              },
              {
                label: 'Nudge',
                data: connectionsNudge,
                borderColor: chartColors.chartMint,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartMint,
                pointRadius: 6,
                hidden: true
              },
              {
                label: 'Question',
                data: connectionsQuestion,
                borderColor: chartColors.chartPink,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartPink,
                pointRadius: 6,
                hidden: true
              },
              {
                label: 'Request a call',
                data: connectionsRequestACall,
                borderColor: chartColors.chartLighterBlue,
                borderWidth: 3,
                pointBackgroundColor: chartColors.white,
                pointBorderColor: chartColors.chartLighterBlue,
                pointRadius: 6,
                hidden: true
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Admin;

Admin.propTypes = {
  revenueUrl: PropTypes.string,
  userSignupApiUrl: PropTypes.string,
  recurringPaymentReportApiUrl: PropTypes.string,
  orderReportsApiUrl: PropTypes.string,
  connectionsApiUrl: PropTypes.string
};

Admin.defaultProps = {
  revenueUrl: '',
  userSignupApiUrl: '',
  recurringPaymentReportApiUrl: '',
  orderReportsApiUrl: '',
  connectionsApiUrl: ''
};
