import React from 'react';
import * as PropTypes from 'prop-types';

const CircularProgress = ({
  progress,
  width,
  height,
  radius,
  cx,
  cy,
  strokeWidth
}) => {
  const circumference = radius ? radius * 2 * Math.PI : 40 * 2 * Math.PI;

  return (
    <svg
      // eslint-disable-next-line jsx-a11y/aria-role
      role="svg"
      className={`${width || 'w-24'} ${height || 'h-24'} transform -rotate-90`}
    >
      <circle
        className="text-gray-300"
        strokeWidth={`${strokeWidth || 4}`}
        stroke="currentColor"
        fill="transparent"
        r={`${radius || 40}`}
        cx={`${cx || 50}`}
        cy={`${cy || 50}`}
      />
      <circle
        className="text-green-400"
        strokeWidth={`${strokeWidth || 4}`}
        strokeDasharray={circumference}
        strokeDashoffset={circumference - (progress / 100) * circumference}
        strokeLinecap="round"
        stroke="currentColor"
        fill="transparent"
        r={`${radius || 40}`}
        cx={`${cx || 50}`}
        cy={`${cy || 50}`}
      />
    </svg>
  );
};

export default CircularProgress;

CircularProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  radius: PropTypes.number,
  cx: PropTypes.number,
  cy: PropTypes.number,
  strokeWidth: PropTypes.number
};

CircularProgress.defaultProps = {
  width: null,
  height: null,
  radius: null,
  cx: null,
  cy: null,
  strokeWidth: null
};
