import React from 'react';
import * as PropTypes from 'prop-types';

const ListButton = ({ onClick, title, link }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`
      bg-transparent
      border
      border-solid
      border-gray-300
      px-5
      py-2
      text-sm
      rounded-md
      w-auto
      hover:bg-gray-300
      focus:bg-gray-200
      whitespace-nowrap
      ${link ? 'text-blue-500 underline decoration-blue-500' : 'text-gray-700'}
    `}
    >
      {title}
    </button>
  );
};

export default ListButton;

ListButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  link: PropTypes.bool
};

ListButton.defaultProps = {
  link: false,
  onClick: () => {}
};
