/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import CaseStudyModal from '../../Components/CaseStudyAdmin/CaseStudyModal';
import { postCaseStudy } from '../../Util/ApiService';
import { displayToast } from '../../Util/Toast';
import { getLastCharacter } from '../../Util/data';

const CaseStudyAdminModal = ({
  addCaseStudyArticleUri,
  preFetchedCaseStudy,
  type,
  updateCaseStudyUri,
  userAvatar,
  username,
  sites,
  proposalStages,
  industries,
  caseStudyStatuses,
  userId
}) => {
  const [posting, setPosting] = useState(false);

  const postCase = async (
    userId,
    designation,
    companyName,
    image,
    header,
    content,
    quote,
    selectedSites,
    caseStudyStatus,
    proposalStage,
    industry
  ) => {
    setPosting(true);
    const formData = new FormData();
    if (typeof image === 'string' && preFetchedCaseStudy) {
      formData.append('imageUpdated', false);
    } else {
      image && formData.append('image', image, image.name);
      formData.append('imageUpdated', true);
    }
    userId && formData.append('user', userId);
    designation && formData.append('designation', designation);
    companyName && formData.append('companyName', companyName);
    header && formData.append('header', header);
    content && formData.append('content', content);
    quote && formData.append('quote', quote);
    sites && formData.append('sitesCodes', JSON.stringify(selectedSites));
    caseStudyStatus &&
      formData.append('caseStudyArticleStatus', caseStudyStatus);
    proposalStage && formData.append('proposalStage', proposalStage);
    industry && formData.append('industry', industry);

    try {
      if (type === 'add') {
        await postCaseStudy(addCaseStudyArticleUri, formData).then(() => {
          displayToast('Case Study posted successfully!', 'success');
        });
      } else {
        await postCaseStudy(updateCaseStudyUri, formData).then(() => {
          displayToast('Case Study updated!', 'success');
        });
      }
    } catch (error) {
      displayToast('Something went wrong!', 'error');
    } finally {
      setPosting(false);
      window.location.reload();
    }
  };
  return (
    <CaseStudyModal
      type={type}
      avatar={userAvatar}
      name={username}
      postCase={postCase}
      posting={posting}
      preFetchedCaseStudy={preFetchedCaseStudy}
      sites={sites}
      proposalStages={proposalStages}
      industries={industries}
      caseStudyStatuses={caseStudyStatuses}
      bgClass={getLastCharacter(userId)}
    />
  );
};

export default CaseStudyAdminModal;

CaseStudyAdminModal.propTypes = {
  addCaseStudyArticleUri: PropTypes.string,
  preFetchedCaseStudy: PropTypes.string,
  type: PropTypes.oneOf(['add', 'edit']),
  updateCaseStudyUri: PropTypes.string,
  userAvatar: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  sites: PropTypes.string,
  proposalStages: PropTypes.string,
  industries: PropTypes.string,
  caseStudyStatuses: PropTypes.string,
  userId: PropTypes.number
};

CaseStudyAdminModal.defaultProps = {
  addCaseStudyArticleUri: '',
  preFetchedCaseStudy: null,
  type: 'add',
  updateCaseStudyUri: '',
  sites: null,
  proposalStages: null,
  industries: null,
  caseStudyStatuses: null,
  userId: 0
};
