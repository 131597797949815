import React from 'react';
import * as PropTypes from 'prop-types';

const Select = ({ onChange, options, selected, isSimple }) => {
  return (
    <select
      className={`focus:outline-none focus:ring-0 px-5 rounded-md appearance-none cursor-pointer ${
        isSimple ? 'bg-transparent' : 'py-2 border border-gray-400'
      }`}
      defaultValue={selected}
      onChange={(e) => {
        return onChange(e?.target?.value);
      }}
    >
      {options?.map((option) => {
        return (
          <option key={option?.code} value={option?.code}>
            {option?.name}
          </option>
        );
      })}
    </select>
  );
};

export default Select;

Select.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.instanceOf(Array).isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSimple: PropTypes.bool
};

Select.defaultProps = {
  onChange: () => {},
  selected: '',
  isSimple: false
};
