import React from 'react';

const InvestorSkeleton = () => {
  return (
    <div className="w-full md:max-w-md mx-auto flex flex-col py-6 px-4">
      <div className="bg-white rounded-lg h-full flex flex-col gap-4 shadow-3xl p-6">
        <div className="w-full flex items-center gap-4 relative">
          <div className="w-20 h-20 rounded-full animate-pulse bg-gray-300 flex-shrink-0" />
          <div className="flex flex-col gap-0.5">
            <div className="h-8 w-24 animate-pulse bg-gray-200" />
            <div className="h-5 w-20 animate-pulse bg-gray-200" />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <div className="h-6 w-32 animate-pulse bg-gray-200" />
            <div className="h-8 w-48 animate-pulse bg-gray-200" />
          </div>
          <div className="h-5 w-48 animate-pulse bg-gray-200" />
          <div className="h-20 w-full animate-pulse bg-gray-200" />
          <div className="flex flex-col gap-1">
            <div className="h-7 w-48 animate-pulse bg-gray-200" />
            <div className="h-5 w-full animate-pulse bg-gray-200" />
          </div>
        </div>
        <div className="flex flex-wrap items-center gap-2 mt-auto">
          <div className="bg-gray-400 animate-pulse py-1 px-3 w-20 h-8 rounded-md" />
          <div className="bg-gray-200 animate-pulse py-1 px-3 w-24 h-8 rounded-md" />
        </div>
      </div>
    </div>
  );
};

export default InvestorSkeleton;
