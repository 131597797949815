/* eslint-disable indent */
import React, { useState, useRef, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader/Loader';
import MessageBox from './MessageBox';
import PitchExpiredPrompt from './PitchExpiredPrompt';
import Tooltip from '../Tooltip/Tooltip';
import {
  removeEntryFromArray,
  renderDocumentIcon,
  renderUserAvatar
} from '../../Util/data';
import { connectionStatusCodes } from '../../Util/connectionStatuses';
import UserInfo from './UserInfo';
import InvestorDeactivatedPrompt from './InvestorDeactivatedPrompt';
import { displayToast } from '../../Util/Toast';

const MessageSection = ({
  toggleProfileDetails,
  showProfileDetails,
  conversation,
  avatar,
  loading,
  handleMessage,
  sending,
  recipient,
  proposalTitle,
  updateReadStatus,
  markAsArchived,
  restoreConnection,
  isRead,
  isProposalUser,
  canViewMessages,
  canPostMessages,
  connection,
  locale,
  acceptConnection,
  rejectConnection,
  loadMoreMessages,
  acceptingNudge,
  rejectingNudge,
  isHidden,
  toggleSidebar,
  attachmentList,
  connectionAction,
  interestedUserDeactivated,
  updatedReadStatus,
  maxUploadDocumentSize,
  recipientUserIdLastChar,
  toggleTranslationToast
}) => {
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef();
  const messageContainer = useRef();
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);
  const [threadAlreadyRead, setThreadReadStatus] = useState(isRead);
  const [translationOn, setTranslationOn] = useState(true);
  const messageBox = useRef();

  const { t } = useTranslation();

  const scrollToBottom = () => {
    if (!messagesEndRef.current || messagesEndRef.current === undefined) {
      return;
    }
    const topPos = messagesEndRef.current.offsetTop;
    messageContainer.current.scrollTop = topPos;
    // messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    updatedReadStatus ? setThreadReadStatus(true) : setThreadReadStatus(false);
    setShowAdditionalOptions(false);
  }, [recipient, updatedReadStatus, connection]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!canPostMessages) {
      return;
    }
    handleMessage(message, attachments).then(() => {
      setMessage('');
      setAttachments([]);
      if (messageBox.current) {
        messageBox.current.style.height = '';
      }
    });
  };

  const handleFileInput = (e) => {
    const files = [];
    const maxFileSize = maxUploadDocumentSize;
    Array.from(e.target.files).forEach((file) => {
      if (file.size > maxFileSize) {
        displayToast(t('auth.inbox.fileIsTooLarge'), 'error');
      } else {
        files.push(file);
      }
    });
    e.target.value = '';
    setAttachments([...attachments.concat(files)]);
  };

  const handleTextareaHeight = (e) => {
    e.target.style.height = '';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const deleteAttachment = (event, index) => {
    event.preventDefault();
    const selectedAttachments = [...attachments];
    const newArray = removeEntryFromArray(selectedAttachments, index);
    setAttachments([...newArray]);
  };

  const getDocumentIcon = (fileName) => {
    const icon = renderDocumentIcon(fileName);
    return icon;
  };

  // render user information inside message section

  const renderUserDetails = () => {
    return (
      <UserInfo
        locale={locale}
        additionalClasses={
          showAdditionalOptions ? 'md:hidden block px-4' : 'hidden'
        }
        conversation={connection}
        attachments={attachmentList}
        acceptConnection={connectionAction}
        rejectConnection={connectionAction}
        isProposalUser={isProposalUser}
        acceptingNudge={acceptingNudge}
        rejectingNudge={rejectingNudge}
        recipientUserIdLastChar={recipientUserIdLastChar}
      />
    );
  };

  const renderMessages = () => {
    return connection &&
      connection.status.code === connectionStatusCodes.pendingAcceptance &&
      !isProposalUser ? (
      <div>
        <MessageBox
          sentByCurrentUser={false}
          conversationUserName={recipient}
          logoUrl={avatar}
          isPending
          connection={connection}
          locale={locale}
          acceptConnection={acceptConnection}
          rejectConnection={rejectConnection}
          isProposalUser={isProposalUser}
          lastMessage={
            translationOn
              ? connection.lastMessage.content
              : connection.lastMessage.originalContent
          }
          date={connection.lastMessage.sentDate}
          acceptingNudge={acceptingNudge}
          rejectingNudge={rejectingNudge}
          bgClass={recipientUserIdLastChar}
        />
        <div ref={messagesEndRef} />
      </div>
    ) : (
      messages && (
        <>
          {
            // eslint-disable-next-line no-shadow
            [...messages].reverse().map((message) => {
              return (
                <MessageBox
                  key={message.sentDate}
                  loading={loading}
                  sentByCurrentUser={message.sentByCurrentUser}
                  message={
                    translationOn ? message.content : message.originalContent
                  }
                  attachments={message.attachments}
                  date={message.sentDate}
                  conversationUserName={`${message.sender.name.firstName} ${message.sender.name.lastName}`}
                  logoUrl={message.sender.logoUri}
                  isPending={false}
                  isProposalUser={isProposalUser}
                  locale={locale}
                  acceptingNudge={acceptingNudge}
                  rejectingNudge={rejectingNudge}
                  bgClass={recipientUserIdLastChar}
                />
              );
            })
          }
          <div ref={messagesEndRef} />
        </>
      )
    );
  };

  const archiveMessage = () => {
    if (connection) {
      markAsArchived(connection.connectionUserActions.markAsArchivedUri);
    }
  };

  const restoreMessage = () => {
    if (connection) {
      restoreConnection(connection.connectionUserActions.markAsNotArchivedUri);
    }
  };

  const updateTranslationStatus = () => {
    translationOn ? setTranslationOn(false) : setTranslationOn(true);
    window.innerWidth < 768 ? toggleTranslationToast(translationOn) : null;
  };

  const renderTranslateButton = () => {
    return (
      <button
        type="button"
        className="h-full p-3"
        onClick={updateTranslationStatus}
      >
        <span
          className={`material-icons ${translationOn ? 'text-blue-600' : ''}`}
        >
          translate
        </span>
      </button>
    );
  };

  useEffect(() => {
    if (conversation !== '' && conversation.results.length) {
      // eslint-disable-next-line no-unused-expressions
      conversation.pagination.totalResults >
      conversation.pagination.pageNumber * conversation.pagination.pageSize
        ? setHasMoreMessages(true)
        : setHasMoreMessages(false);
      if (isProposalUser && !canPostMessages) {
        setHasMoreMessages(false);
      }
      // eslint-disable-next-line no-unused-expressions
      !connection?.viewerCanPostMessages &&
      connection?.viewerCanViewMessages &&
      connection?.viewingByProposalUser
        ? setMessages(
            [
              {
                content: t('auth.inbox.imNoLongerInterested'),
                sender: {
                  name: {
                    firstName: recipient?.split(' ')[0],
                    lastName: recipient?.split(' ')[1]
                  },
                  logoUri: avatar
                },
                sentByCurrentUser: false,
                sentDate: moment().format(),
                attachments: []
              }
            ].concat(conversation.results)
          )
        : conversation.pagination.pageNumber === 1
        ? setMessages(conversation.results)
        : setMessages([...messages.concat(conversation.results)]);
    }
    if (conversation && conversation.pagination.pageNumber === 1) {
      scrollToBottom();
    }
  }, [conversation.results]);

  useEffect(() => {
    setMessages([]);
  }, [recipient]);

  return (
    <div
      className={`
        md:block
        w-full
        h-full
        border-r
        border-gray-200
        relative
        overflow-hidden
        transition-all
        ${isHidden ? 'hidden' : ''}
        md:w-2/3
        ${showProfileDetails ? 'xl:w-1/2' : 'xl:w-3/4'}
      `}
      id="message-section"
    >
      <div className="flex flex-col w-full h-full">
        <div className="w-full flex p-0.5 items-center justify-between border-b border-gray-200 relative top-0">
          {loading ? (
            <>
              <div className="px-4 inline-flex gap-3 items-center">
                <div className="rounded-full bg-gray-200 h-11 w-11 animate-pulse" />
                <div className="h-6 w-16 bg-gray-200 rounded animate-pulse" />
              </div>
              <div className="w-48 h-6 bg-gray-200 rounded animate-pulse" />
            </>
          ) : (
            <>
              <div className="px-4 inline-flex gap-3 items-center min-w-max">
                <button
                  type="button"
                  className="inline-flex items-center gap-x-3 md:hidden"
                  onClick={toggleSidebar}
                >
                  <span className="material-icons">navigate_before</span>
                  <span className="inline-block md:hidden text-base font-normal text-ain-socials-gray">
                    {t('auth.inbox.messages')}
                  </span>
                </button>
                {avatar ? (
                  <img
                    src={avatar}
                    alt={recipient}
                    className="md:inline-flex hidden md:w-11 md:h-11 w-9 h-9 rounded-full object-center object-cover"
                  />
                ) : (
                  renderUserAvatar(
                    recipient,
                    'md:w-11 md:h-11 w-9 h-9 md:inline-flex hidden',
                    `bg-${recipientUserIdLastChar}`
                  )
                )}
                <p className="text-gray-800 text-base md:block hidden">
                  {recipient}
                </p>
              </div>
              <p className="px-4 font-semibold text-gray-800 text-lg truncate md:block hidden">
                {proposalTitle}
              </p>
            </>
          )}
          <div className="flex items-center relative text-gray-700">
            <div className="flex items-center lg:border-r border-gray-200 relative">
              {conversation?.requireTranslations && (
                <>
                  <div className="md:block hidden">
                    <Tooltip
                      text={
                        translationOn
                          ? t('auth.inbox.translationOn')
                          : t('auth.inbox.translationOff')
                      }
                      classNames="bottom-0 -mb-2.5 whitespace-nowrap"
                    >
                      {renderTranslateButton()}
                    </Tooltip>
                  </div>
                  <div className="md:hidden block">
                    {renderTranslateButton()}
                  </div>
                </>
              )}
              <Tooltip
                text={
                  threadAlreadyRead
                    ? t('auth.inbox.markAsUnread')
                    : t('auth.inbox.markAsRead')
                }
                classNames="bottom-0 -mb-2.5 whitespace-nowrap"
              >
                <button
                  type="button"
                  className="h-full p-3"
                  onClick={() => {
                    return threadAlreadyRead
                      ? updateReadStatus(false)
                      : updateReadStatus(true);
                  }}
                >
                  <span className="material-icons">
                    {threadAlreadyRead ? 'email' : 'drafts'}
                  </span>
                </button>
              </Tooltip>
              {connection && !connection.archived ? (
                <Tooltip
                  text={t('auth.inbox.delete')}
                  classNames="bottom-0 -mb-2.5"
                >
                  <button
                    type="button"
                    className="h-full p-3"
                    onClick={archiveMessage}
                  >
                    <span className="material-icons">delete</span>
                  </button>
                </Tooltip>
              ) : (
                <Tooltip
                  text={t('auth.inbox.restore')}
                  classNames="bottom-0 -mb-2.5"
                >
                  <button
                    type="button"
                    className="h-full p-3"
                    onClick={restoreMessage}
                  >
                    <span className="material-icons">restore_from_trash</span>
                  </button>
                </Tooltip>
              )}
            </div>
            <Tooltip
              text={t('auth.inbox.moreInfo')}
              classNames="bottom-0 -mb-2.5 whitespace-nowrap right-0"
            >
              <button
                type="button"
                className="h-full p-3 md:inline hidden"
                onClick={toggleProfileDetails}
              >
                <span
                  className={`material-icons ${
                    showProfileDetails ? 'text-blue-600' : ''
                  }`}
                >
                  info
                </span>
              </button>
            </Tooltip>
            <Tooltip
              text={t('auth.inbox.moreInfo')}
              classNames="bottom-0 -mb-2.5 whitespace-nowrap right-0"
            >
              <button
                type="button"
                className="h-full p-3 md:hidden"
                onClick={() => {
                  // eslint-disable-next-line arrow-body-style
                  return setShowAdditionalOptions((value) => !value);
                }}
              >
                <span
                  className={`material-icons ${
                    showAdditionalOptions ? 'text-blue-600' : ''
                  }`}
                >
                  info
                </span>
              </button>
            </Tooltip>
          </div>
        </div>
        {!loading && !showAdditionalOptions && (
          <div className="md:hidden flex items-center justify-between px-4 py-2 gap-4 border-b border-gray-200">
            <div className="inline-flex items-center gap-2">
              {avatar && recipient ? (
                <img
                  src={avatar}
                  alt={recipient}
                  className="w-8 h-8 rounded-full"
                />
              ) : (
                renderUserAvatar(
                  recipient,
                  'w-8 h-8',
                  `bg-${recipientUserIdLastChar}`
                )
              )}
              <span className="inline-block md:hidden text-base font-normal text-gray-800 truncate">
                {recipient}
              </span>
            </div>
            <p className="font-semibold text-gray-800 text-base truncate w-36">
              {proposalTitle}
            </p>
          </div>
        )}

        {showAdditionalOptions ? (
          renderUserDetails()
        ) : (
          <>
            {connection &&
            connection.status.code === connectionStatusCodes.rejected ? (
              <div className="py-4 md:px-4 mx-auto">
                {isProposalUser
                  ? t('auth.inbox.rejected')
                  : t('auth.inbox.connectionRejected')}
              </div>
            ) : connection &&
              connection.status.code === connectionStatusCodes.deleted ? (
              <div className="py-4 md:px-4 mx-auto">
                {t('auth.inbox.pitchDeleted')}
              </div>
            ) : (
              <div
                id="scrollableTarget"
                ref={messageContainer}
                className={`
                  flex
                  w-full
                  overflow-auto
                  flex-grow py-2
                  ${
                    isProposalUser && !canPostMessages && !canViewMessages
                      ? ''
                      : 'flex-col-reverse'
                  }
                `}
              >
                <InfiniteScroll
                  dataLength={
                    conversation && conversation.pagination
                      ? conversation.pagination.pageNumber * 6
                      : 0
                  } // 6 is pageSize
                  next={() => {
                    return loadMoreMessages();
                  }}
                  inverse
                  hasMore={
                    hasMoreMessages &&
                    connection &&
                    connection.status.code !==
                      connectionStatusCodes.pendingAcceptance
                  }
                  loader={
                    conversation !== '' && (
                      <span className="text-sm inline-block mx-auto">
                        {t('auth.inbox.loading')}
                      </span>
                    )
                  }
                  scrollableTarget="scrollableTarget"
                  className="flex flex-col-reverse"
                >
                  <div className="w-full h-full px-5 py-10">
                    {isProposalUser && interestedUserDeactivated ? (
                      <InvestorDeactivatedPrompt />
                    ) : isProposalUser &&
                      !canPostMessages &&
                      !canViewMessages ? (
                      <PitchExpiredPrompt
                        proposalId={
                          connection
                            ? connection.proposal.proposalApiUri.match(/\d+/)[0]
                            : null
                        }
                      />
                    ) : (
                      renderMessages()
                    )}
                  </div>
                </InfiniteScroll>
              </div>
            )}

            {canPostMessages && (
              <div className="w-full bottom-0 mt-auto px-6 py-4 border-t border-gray-200 bg-white">
                <form
                  className="w-full flex items-start gap-4"
                  onSubmit={handleFormSubmit}
                >
                  <div className="relative w-10 h-10 cursor-pointer bg-gray-200 rounded-md py-2 px-3 inline-flex items-center justify-center overflow-hidden focus:bg-gray-300">
                    <div className="absolute w-full h-full">
                      <button
                        type="button"
                        className="material-icons absolute inset-0 w-full h-full text-gray-800 text-xl focus:bg-gray-300"
                      >
                        attach_file
                      </button>
                    </div>
                    <input
                      type="file"
                      className="block opacity-0 w-full h-full cursor-pointer focus:bg-gray-300 p-5"
                      multiple
                      accept=".doc,.docx,.ppt,.pptx,.ppsx,.xls,.xlsx,.pdf"
                      onChange={(e) => {
                        return handleFileInput(e);
                      }}
                    />
                  </div>
                  <textarea
                    ref={messageBox}
                    className="
                      w-full
                      rounded-lg
                      px-4
                      py-2
                      bg-gray-200
                      resize-none
                      text-gray-700
                      focus:outline-none
                      lg:max-h-72
                      max-h-52
                    "
                    cols="20"
                    rows="1"
                    placeholder={t('auth.inbox.startTyping')}
                    onChange={(e) => {
                      return setMessage(e.target.value);
                    }}
                    value={message}
                    onInput={(e) => {
                      handleTextareaHeight(e);
                    }}
                    required
                  />

                  <button
                    type="submit"
                    className={`h-10 w-10 inline-flex items-center justify-center bg-blue-600 text-white py-2 px-3 text-lg rounded-md material-icons ${
                      !canPostMessages ? 'cursor-not-allowed' : ''
                    }`}
                    disabled={!canPostMessages}
                  >
                    {sending ? (
                      <Loader title={t('auth.inbox.sending')} />
                    ) : (
                      'send'
                    )}
                  </button>
                  {/* <div className="w-full flex justify-between items-center py-3">
                  </div> */}
                </form>
                <div className="flex gap-2 w-full items-center overflow-x-auto h-auto">
                  {attachments?.length > 0 &&
                    attachments?.map((attachment, index) => {
                      return (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          className="w-1/5 p-4 gap-y-2 h-full flex flex-col items-center justify-center"
                        >
                          <div className="relative md:w-1/3 w-full h-full">
                            {getDocumentIcon(attachment.name)}
                            <button
                              type="button"
                              className="
                              absolute
                              -top-1
                              -right-1
                              w-5
                              h-5
                              rounded-full
                              inline-flex
                              items-center
                              justify-center
                              bg-white
                              shadow
                            "
                              title="Delete attachment"
                              onClick={(event) => {
                                return deleteAttachment(event, index);
                              }}
                            >
                              <span className="material-icons text-sm">
                                close
                              </span>
                            </button>
                          </div>
                          <p className="text-gray-800 text-xs truncate max-w-full">
                            {attachment.name}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MessageSection;

MessageSection.propTypes = {
  toggleProfileDetails: PropTypes.func,
  showProfileDetails: PropTypes.bool,
  conversation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array)
  ]),
  avatar: PropTypes.string,
  loading: PropTypes.bool,
  handleMessage: PropTypes.func,
  sending: PropTypes.bool,
  recipient: PropTypes.string,
  proposalTitle: PropTypes.string,
  updateReadStatus: PropTypes.func,
  markAsArchived: PropTypes.func,
  restoreConnection: PropTypes.func,
  isRead: PropTypes.bool,
  isProposalUser: PropTypes.bool,
  canViewMessages: PropTypes.bool,
  canPostMessages: PropTypes.bool,
  connection: PropTypes.instanceOf(Object),
  locale: PropTypes.string,
  acceptConnection: PropTypes.func,
  rejectConnection: PropTypes.func,
  loadMoreMessages: PropTypes.func,
  interestedUserDeactivated: PropTypes.bool,
  acceptingNudge: PropTypes.bool,
  rejectingNudge: PropTypes.bool,
  isHidden: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  attachmentList: PropTypes.instanceOf(Object),
  connectionAction: PropTypes.func,
  updatedReadStatus: PropTypes.bool,
  recipientUserIdLastChar: PropTypes.string,
  toggleTranslationToast: PropTypes.func
};

MessageSection.defaultProps = {
  toggleProfileDetails: () => {},
  showProfileDetails: false,
  conversation: null,
  avatar: '',
  loading: false,
  handleMessage: () => {},
  sending: false,
  recipient: '',
  proposalTitle: '',
  updateReadStatus: () => {},
  markAsArchived: () => {},
  restoreConnection: () => {},
  isRead: false,
  isProposalUser: false,
  canViewMessages: false,
  canPostMessages: false,
  connection: {},
  locale: '',
  acceptConnection: () => {},
  rejectConnection: () => {},
  loadMoreMessages: () => {},
  interestedUserDeactivated: false,
  acceptingNudge: false,
  rejectingNudge: false,
  isHidden: true,
  toggleSidebar: () => {},
  attachmentList: null,
  connectionAction: () => {},
  updatedReadStatus: false,
  recipientUserIdLastChar: '0',
  toggleTranslationToast: () => {}
};
