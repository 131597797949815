import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ButtonGroup from './ButtonGroup';
import LightBox from '../Lightbox/LightBox';

const ImageForm = ({
  selectedImages,
  lightboxImages,
  imgIndex,
  clickedBack,
  modalConfirmed,
  addImagesToLb,
  updateSelectedImages
}) => {
  const { t } = useTranslation();
  const imageInput = useRef();

  const handleFileChange = () => {
    const tempImageArray = [];
    [...imageInput.current.files].forEach((img) => {
      const image = {
        src: URL.createObjectURL(img)
      };
      tempImageArray.push(image);
    });
    addImagesToLb(tempImageArray);
    updateSelectedImages([...imageInput.current.files]);
  };

  return (
    <div className="pt-2 w-full flex flex-col gap-2">
      {selectedImages?.length ? (
        <div className="w-full md:h-80 h-64">
          <LightBox
            type="carousel"
            images={lightboxImages}
            isOpen
            initialIdx={imgIndex}
          />
        </div>
      ) : (
        <div className="flex flex-col px-6 gap-2 items-center">
          <div className="relative">
            <div className="absolute w-full h-full">
              <button
                type="button"
                className="
                  relative
                  inline-flex
                  items-center
                  justify-center
                  w-full
                  h-full
                  overflow-hidden
                  bg-blue-50
                  rounded-md
                  text-base
                "
              >
                <span className="text-blue-700 font-semibold">
                  {t('newsfeed.pageContent.selectImage')}
                </span>
              </button>
            </div>
            <div className="relative opacity-0 max-w-max md:w-52 w-full h-full">
              <input
                ref={imageInput}
                onChange={handleFileChange}
                multiple
                type="file"
                accept=".png,.jpg,.jpeg"
                className="block opacity-0 w-full h-full cursor-pointer py-2 px-4"
              />
            </div>
          </div>
          <p className="text-gray-600 text-sm font-normal leading-6">
            {t('newsfeed.pageContent.holdControl')}
          </p>
        </div>
      )}
      <ButtonGroup
        onBackClick={clickedBack}
        onConfirmationClick={modalConfirmed}
        presetValue={selectedImages}
        type="image"
      />
    </div>
  );
};

export default ImageForm;

ImageForm.propTypes = {
  selectedImages: PropTypes.instanceOf(Array),
  lightboxImages: PropTypes.instanceOf(Array),
  imgIndex: PropTypes.number,
  clickedBack: PropTypes.func,
  modalConfirmed: PropTypes.func,
  addImagesToLb: PropTypes.func,
  updateSelectedImages: PropTypes.func
};

ImageForm.defaultProps = {
  selectedImages: [],
  lightboxImages: [],
  imgIndex: 0,
  clickedBack: () => {},
  modalConfirmed: () => {},
  addImagesToLb: () => {},
  updateSelectedImages: () => {}
};
