import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CircularProgress from '../Icons/CircularProgress';

const DashboardCard = ({
  connectionDailyConnectionUsage,
  icon,
  inboxPageUri,
  connectionDailyConnectionUsagePercentage,
  openDescription,
  responseRate,
  responseRateLevel,
  responseRateLevelBackgroundColor,
  responseRateLevelTextColor,
  title,
  type,
  unreadConnections
}) => {
  const { t } = useTranslation();

  // FIXME - we need some kind of confirmation from the design team and business
  // on if we actually want to do this conditional rendering or not
  // if yes, we need the values and text to display these
  const renderBadge = (
    responseRateLevel,
    responseRateLevelBackgroundColor,
    responseRateLevelTextColor
  ) => {
    return (
      <span
        className="rounded-sm p-1 pb-0.5 text-xs leading-5 font-semibold"
        style={{
          backgroundColor: `${responseRateLevelBackgroundColor}`,
          color: `${responseRateLevelTextColor}`
        }}
      >
        {responseRateLevel}
      </span>
    );
  };

  const renderConnectionComponent = () => {
    return (
      <div className="flex items-end gap-2 justify-between">
        <div className="relative">
          <CircularProgress
            progress={connectionDailyConnectionUsagePercentage}
          />
          <span
            className="
              absolute
              text-ain-socials-gray
              font-semibold
              text-2xl
              leading-8
              left-1/2
              top-1/2
              transform
              -translate-x-1/2
              -translate-y-1/2
            "
          >
            {connectionDailyConnectionUsage}
          </span>
        </div>
        <a
          href={inboxPageUri}
          className="mb-3 rounded-md bg-gray-200 py-2 px-4 text-gray-800 text-base font-semibold"
        >
          {t('investorDashboard.getMoreConnections')}
        </a>
      </div>
    );
  };

  const renderResponseComponent = () => {
    return (
      <div className="flex gap-4 items-start">
        <p className="text-gray-800 text-5xl font-normal">
          {responseRate == null
            ? t('investorDashboard.notApplicable')
            : `${responseRate}%`}
        </p>
        {responseRate != null &&
          renderBadge(
            responseRateLevel,
            responseRateLevelBackgroundColor,
            responseRateLevelTextColor
          )}
      </div>
    );
  };

  const renderViewMessageComponent = () => {
    return (
      <div className="flex items-end gap-2 justify-between">
        <p className="text-gray-800 text-5xl font-normal">
          {unreadConnections}
        </p>
        <a
          href={inboxPageUri}
          className="rounded-md bg-gray-200 py-2 px-4 text-gray-800 text-base font-semibold"
        >
          {t('investorDashboard.viewMessages')}
        </a>
      </div>
    );
  };

  const renderRespectiveComponent = () => {
    switch (type) {
      case 'connections':
        return renderConnectionComponent();
      case 'response':
        return renderResponseComponent();
      default:
        return renderViewMessageComponent();
    }
  };

  return (
    <div className="rounded-lg bg-white border border-gray-200 p-6 flex flex-col gap-7 justify-between">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2 text-gray-800">
          {icon}
          <p className="text-gray-700 text-lg font-normal leading-6">{title}</p>
        </div>
        <button
          type="button"
          onClick={openDescription}
          className="material-icons fill-current text-gray-400 w-5 h-5 focus:outline-none"
        >
          info
        </button>
      </div>
      {renderRespectiveComponent()}
    </div>
  );
};

export default DashboardCard;

DashboardCard.propTypes = {
  connectionDailyConnectionUsage: PropTypes.string,
  icon: PropTypes.element,
  inboxPageUri: PropTypes.string.isRequired,
  connectionDailyConnectionUsagePercentage: PropTypes.number,
  openDescription: PropTypes.func.isRequired,
  responseRate: PropTypes.number,
  responseRateLevel: PropTypes.string,
  responseRateLevelBackgroundColor: PropTypes.string,
  responseRateLevelTextColor: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(['connections', 'response', 'awaitingResponse']),
  unreadConnections: PropTypes.number
};

DashboardCard.defaultProps = {
  connectionDailyConnectionUsage: '',
  icon: null,
  connectionDailyConnectionUsagePercentage: 0,
  responseRate: 0,
  responseRateLevel: '',
  responseRateLevelBackgroundColor: '',
  responseRateLevelTextColor: '',
  title: null,
  type: 'messages',
  unreadConnections: 0
};
