import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { removeEntryFromArray } from '../../Util/data';

const TagsInput = ({
  handleQueryInput,
  isEmpty,
  preEnteredTags,
  unsetPreRenderedTag,
  storeInput
}) => {
  const [input, setInput] = useState('');
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);

  const { t } = useTranslation();

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
    storeInput(value);
  };

  // handle key presses
  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (
      (key === ',' || key === 'Enter') &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault();
      // eslint-disable-next-line arrow-body-style
      setTags((prevState) => [...prevState, trimmedInput]);
      // eslint-disable-next-line arrow-body-style
      handleQueryInput((prevState) => [...prevState, trimmedInput]);
      setInput('');
    }

    if (key === 'Backspace' && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      // const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      handleQueryInput(tagsCopy);
      setInput(input);
      storeInput(input);
    }

    setIsKeyReleased(false);
  };

  // handle keyup
  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  // delete tag
  const deleteTag = (index) => {
    setTags((prevState) => {
      return prevState.filter((tag, i) => {
        return i !== index;
      });
    });
    const tagsCopy = removeEntryFromArray(tags, index);
    if (preEnteredTags && tags.indexOf(preEnteredTags) === index) {
      unsetPreRenderedTag();
    }
    handleQueryInput(tagsCopy);
  };

  useEffect(() => {
    if (preEnteredTags && !tags.length) {
      setTags([...tags, preEnteredTags]);
      handleQueryInput([...tags, preEnteredTags]);
    }
  }, [preEnteredTags]);

  useEffect(() => {
    if (isEmpty && tags.length > 0) {
      setTags([]);
      handleQueryInput([]);
    }
  }, [isEmpty]);

  return (
    <div className="flex flex-wrap items-center gap-2 w-full py-1 px-5 border border-slate-200 rounded-md">
      {tags.map((tag, index) => {
        return (
          <div
            className="inline-flex px-3 py-1 border border-slate-200 items-center gap-x-2 font-semibold rounded"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <span className="text-sm">{tag}</span>
            <button
              type="button"
              className="text-xs"
              onClick={() => {
                return deleteTag(index);
              }}
            >
              x
            </button>
          </div>
        );
      })}
      <input
        className={`p-2 bg-transparent text-base text-gray-500 ring-0 focus:outline-none focus:ring-0 ${
          tags.length > 0 ? 'w-10/12' : 'w-full'
        }`}
        type="text"
        value={input}
        placeholder={t('findInvestors.whatAreYouLookingFor')}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onChange={onChange}
      />
    </div>
  );
};

export default TagsInput;

TagsInput.propTypes = {
  handleQueryInput: PropTypes.func,
  isEmpty: PropTypes.bool,
  preEnteredTags: PropTypes.instanceOf(Array),
  unsetPreRenderedTag: PropTypes.func,
  storeInput: PropTypes.func
};

TagsInput.defaultProps = {
  handleQueryInput: () => {},
  isEmpty: false,
  preEnteredTags: [],
  unsetPreRenderedTag: () => {},
  storeInput: () => {}
};
